<template>
	<div id="Show">
		<van-overlay :show="true">
			<div class="wrapper">
				<div class="box-get-con" :class="[Awardlist.length>3?'big':'']">
					<div class="close" @click="setShow"></div>
					<div class="title-img"></div>
					<div class="list" :class="['num'+Awardlist.length]">
						<div class="item" :class="{'selected': item.Select==true}" v-for="(item,index) in Awardlist" :key="index" @click="SelectItem(item,index)" ref="Select">
							<div class="img-con" :class="['lv'+item.lv]">
								<img :src="item.cover" alt="">
							</div>
							<div class="name">{{item.name}}</div>
							<div class="price">{{item.bean}}<div class="coins"></div></div>
						</div>
					</div>
					<div class="btn-con">
						<div class="btn" @click="setShow">放入背包</div>
						<!-- <div class="btn on" @click="PostCash">全部兑换 {{TotalPrice.toFixed(2)}}<div class="coins"></div></div> -->
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {
		Cash, PersonalInfo
	} from '@/network/api.js'
	import {
		mapMutations
	} from 'vuex'
	export default {
		name: 'Show',
		props: {
			Awardlist: { //奖品列表
				type: Array
			},
			WinningShow: { //控制声音
				type: Boolean
			},
			Music_checked:{
				type: Boolean
			}
		},
		data() {
			return {
				boom: new Audio(require('@/assets/audio/boom.aac')), //奖品展示声音
				TotalPrice: 0, //回收总价格
			}
		},
		methods: {
			//默认全部选中
			Select_All(){
				for (let i = 0; i < this.Awardlist.length; i++) {
					let key = "Select"
					let value = true
					this.Awardlist[i][key] = value
					// setTimeout(() => {
					// 	this.$refs.Select[i].style.border = "1px solid #E9B10E"
					// },10)
					
				}
				// console.log(this.Awardlist)
				this.TotalPriceCal()
			},
			
			//选中
			SelectItem(item, index) {
				if (item.Select) {
					item.Select = false
					// this.$refs.Select[index].style.border = "1px solid transparent";
				} else {
					let key = "Select";
					let value = true
					item[key] = value
					// this.$refs.Select[index].style.border = "1px solid #E9B10E"
				}
				this.TotalPriceCal()
			},

			// 计算回收总价
			TotalPriceCal() {
				this.TotalPrice = 0
				let A_Arr = this.Awardlist.filter((v) => {
					return v.Select == true
				})

				for (let k = 0; k < A_Arr.length; k++) {
					this.TotalPrice += Number(A_Arr[k].bean)
				}
				this.$forceUpdate();
			},

			//兑换金币(回收)
			PostCash() {
				let arr = this.Awardlist.filter((item) => {
					return item.Select == true
				})
				let data = []
				for (let i = 0; i < arr.length; i++) {
					data.push({
						"id": arr[i].id,
						"uuid": arr[i].uuid
					})
				}
				Cash(JSON.stringify(data)).then((res) => {
					// console.log(res)
					this.RefreshData(res.data.message)
					this.setShow()
				})
			},

			//刷新数据
			RefreshData(message) {
				this.$notify({
					type: 'success',
					message: message
				})
				this.GetPersonalInfo()
			},

			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.PostUser(res.data.message)
				})
			},

			
			//子传父(关闭奖品遮罩层)
			setShow() {
				this.$emit('setShow', false)
			},
			
			...mapMutations([
				'PostUser'
			])
		},
		watch: {
			//播放声音并且调用Select_All方法
			WinningShow(v) {		
				if (v == true) {
					if(this.Music_checked){
						this.boom.play(); 
					}
					
					this.Select_All()
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	#Show {
		.Show_box {
			width: 375px;
			padding: 10px;
			box-sizing: border-box;
			border-radius: 10px;
			position: relative;
			text-align: center;

			.Top_img {
				width: 60%;
			}

			.Show_list {
				width: 100%;
				// height: 50vh;
				overflow-y: scroll;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;

				.Show_item {
					width: 130px;
					height:120px;
					margin: 0 5px;
					margin-top: 10px;
					background: url(../../assets/images/public/pub_back.png) no-repeat center;
					background-size: cover;
					border-radius: 6px;
					border: 1px solid #E9B10E;
					box-sizing: border-box;
					cursor: pointer;
					.Show_pic {
						width: 80px;
						height: 60px;
						margin: 0 auto;
						background-image: url(http://www.gameskins.cn/img/LV4.9815642e.png);
						background-position: center center;
						background-size: auto 100%;
						background-repeat: no-repeat;
						display: flex;
						justify-content: center;
						align-items: center;
						&.lv1{
							background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
						}
						&.lv2{
							background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
						}
						&.lv3{
							background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
						}
						&.lv4{
							background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
						}
						&.lv5{
							background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
						}
						img {
							max-width: 100%;
							max-height: 100%;
							vertical-align: middle;
						}
					}

					p {
						width: 100%;
						padding: 0 5px;
						box-sizing: border-box;
						font-size: 12px;
						color: #fff;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					span {
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						// color: #75cd66;
						margin: 5px 0 10px;

						.coins {
							width: 18px;
							height: 18px;
							
						}
					}
				}

			}

			.Show_btns {
				margin-top: 10px;

				.van-button {
					width: 130px;
					height: 40px;
					padding: 0;
					margin-right: 20px;

					&:last-child {
						margin-right: 0;
					}

					.Gold {
						margin-left: 5px;
						// color: #fff;

						.coins {
							width: 18px;
							height: 18px;
							vertical-align: middle;
							margin-bottom: 2px;
							margin-right: 2px;
							display: inline-block;
						}
					}
				}
			}
		}
	}
	.box-get-con{
		@include Scenter;
		flex-direction: column;
		width: 337px;
		height: 307px;
		padding-top: 82px;
		background-size: 100% 100%;
		background-image: url(~@/assets/img/box-get-bg.png);
		position: relative;
		.title-img{
			width: 126px;
			height: 40px;
			background-image: url(~@/assets/img/box-get-title.png);
			background-size: 100% 100%;
			position: absolute;
			left: 50%;
			top: 24px;
			transform: translateX(-50%);
		}
		.close{
			position: absolute;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/rule-close.png);
			right: 17px;
			top: 17px;
			cursor: pointer;
		}
		&.big{
			height: 440px;
			background-image: url(~@/assets/img/box-get-bg-big.png);
		}
		.list{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 6px;
			width: 294px;
			&.num4{
				width: 250px;
			}
			.item{
				width: 94px;
				height: 129px;
				@include HScenter;
				flex-direction: column;
				border: 1px solid #353545;
				position: relative;
				background: #1D1D2B;
				cursor: pointer;
				&.selected{
					border-image: linear-gradient(180deg, #448EFD 0%, #44FDE7 100%);
					border-image-slice: 1; /* 切片，使整个边框都被渐变填充 */
					&::before{
						content: '';
						position: absolute;
						z-index: 2;
						width: 11px;
						height: 11px;
						right: 0;
						top: 0;
						background: url(~@/assets/img/icon-dagou.png);
						background-size: 100% 100%;
					}
				}
				.img-con{
					width: 72px;
					height: 53px;
					background-size: auto 100%;
					background-position: center center;
					background-repeat: no-repeat;
					&.lv1{
						background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
					}
					&.lv2{
						background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
					}
					&.lv3{
						background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
					}
					&.lv4{
						background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
					}
					&.lv5{
						background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
					}
				}
				.name{
					@include wordhide;
					max-width: 90%;
					font-size: 12px;
					margin: 7px 0 5px;
				}
				.price{
					@include Scenter;
					font-size: 14px;
					.coins{
						background-image: url(~@/assets/img/coins-white1.svg);
						margin-left: 2px;
						width: 16px;
						height: 16px;
					}
				}
			}
		}
		.btn-con{
			margin-top: 24px;
			@include HScenter;
			gap: 14px;
			.btn{
				width: 140px;
				height: 46px;
				@include HScenter;
				cursor: pointer;
				background-size: 100% 100%;
				background-image: url(~@/assets/img/box-get-btn.png);
				.coins{
					background-image: url(~@/assets/img/coins-white1.svg);
					width: 16px;
					height: 16px;
					margin: 0;
					margin-left: 4px;
				}
				&.on{
					background-image: url(~@/assets/img/box-get-btn-active.png);
					text-shadow: 0px 2px 4px #3A6EB5;
				}
			}
		}
	}
</style>
