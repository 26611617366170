<template>
	<div class="container">
		<div class="game-title">ROLL福利</div>
		<div class="roll-top-con">
			<div class="roll-photo">
				<img v-if="RollData.type==1" src="@/assets/img/roll-photo.png" alt="">
				<img v-else :src="Avatar" alt="">
			</div>
			<div class="roll-top-main">
				<div class="title">{{RollData.name}}</div>
				<div class="desc">{{RollData.describe}}</div>
				<div class="time">ROLL时间：{{RollData.end_time}}</div>
			</div>
		</div>
		<div class="win-con" v-if="RollData.status==2">
			<div class="win-title">中奖信息</div>
			<div class="scroll-con">
				<el-scrollbar style="height:100%;">
					<div class="win-list">
						<div class="item" v-for="(item,index) in prizeList" :key="item.id">
							<div class="photo">
								<img :src="item.user.avatar" alt="">
							</div>
							<div class="name">{{ item.user.name }}</div>
							<div class="img-con" :class="['level'+item.lv]">
								<img v-if="item.gunType!='bean'" :src="item.cover" alt="">
								<img v-else src="@/assets/img/jinbi1.png" alt="">
							</div>
							<div class="product-name">{{ item.name }}</div>
							<div class="price">
								<div class="coins"></div>{{ item.bean }}
							</div>
						</div>
					</div>
				</el-scrollbar>
			</div>
			
		</div>
		<div class="roll-tab">
			<div class="item" :class="{'active': tabIndex == 0}" @click="tabIndex = 0">活动奖池</div>
			<div class="item" :class="{'active': tabIndex == 1}" @click="tabIndex = 1">参与玩家</div>
		</div>
		<div class="roll-prize-con" v-show="tabIndex == 0">
			<div class="total">
				价值
				<div class="price">
					<div class="coins"></div>{{ RollData.award_bean }}
				</div>
				<div class="right">数量：{{ RollData.box_records.length + RollData.bean_awards.length }}</div>
			</div>
			<div class="prize-list">
				<div class="item" v-for="(item,index) in RollData.box_records" :key="item.id">
					<div class="img-con" :class="['level'+item.lv]">
						<img :src="item.cover" alt="">
					</div>
					<div class="name">{{ item.name }}</div>
					<div class="price">
						<div class="coins"></div>{{ item.bean }}
					</div>
				</div>
				<div class="item" v-for="(item,index) in RollData.bean_awards" :key="item.id">
					<div class="img-con" :class="['level'+item.lv]">
						<img src="~@/assets/img/jinbi1.png" alt="">
					</div>
					<div class="name">金币</div>
					<div class="price">
						<div class="coins"></div>{{ item.bean }}
					</div>
				</div>
			</div>
		</div>
		<div class="roll-user-con" v-show="tabIndex == 1">
			<div class="total">
				共计：{{ room_users.length }}人
			</div>
			<div class="user-list">
				<div class="item" v-for="(item,index) in room_users" :key="item.id">
					<div class="photo">
						<img :src="item.avatar" alt="">
					</div>
					<div class="name">{{ item.name }}</div>
				</div>
			</div>
		</div>
		<div class="bottom-con" v-if="RollData.status!=2">
			<div class="join-btn disabled" v-if="RollData.has_join_room">已参与</div>
			<div class="join-btn" v-else @click="RollData.is_pwd == 1 ? pwdOpen=true : GetRoomJoin()">立即参与</div>
		</div>
		<van-overlay :show="pwdOpen">
			<div class="wrapper">
				<div class="join-con">
					<div class="close" @click="pwdOpen=false"></div>
					<div class="join-detail-con">
						<div class="photo">
							<img v-if="RollData.type==1" src="@/assets/img/roll-photo.png" alt="">
							<img v-else :src="Avatar" alt="">
						</div>
						<div class="title">{{RollData.name}}</div>
						<div class="desc">{{RollData.describe}}</div>
					</div>
					<div class="input-con">
						<input type="text" v-model="Password" placeholder="请输入房间密码">
					</div>
					<div class="join-btn" @click="GetRoomJoin">我要加入</div>
				</div>
			</div>
		</van-overlay>
	</div>
	<!-- <div id="room">
		<div class="TimeDown" v-show="RollData.status == '1'">
			<p class="TimeDown_txt">倒计时:</p>
			<van-count-down :time="new Date(RollData.end_time).getTime() - new Date().getTime()">
				<template #default="timeData">
					<span class="block" v-text="timeData.days < 10 ? '0'+timeData.days : timeData.days"></span>
					<span class="colon">:</span>
					<span class="block" v-text="timeData.hours < 10 ? '0'+timeData.hours : timeData.hours"></span>
					<span class="colon">:</span>
					<span class="block" v-text="timeData.minutes < 10 ? '0'+timeData.minutes : timeData.minutes"></span>
				</template>
			</van-count-down>
		</div>

		<div class="room_data">
			<div class="room_top">
				<div class="room_pic">
					<div class="official" v-if="RollData.type==1">官方</div>
					<img v-else :src="Avatar" alt="">
				</div>
				<div class="room_info">
					<div class="room_tit">{{RollData.name}}</div>
					<p>开奖时间</p>
					<span>{{RollData.end_time}}</span>
				</div>
			</div>
			<div class="room_bottom">
				<div class="room_desc" v-show="RollData.describe != ''">
					<div class="room_desc_tit">房间介绍</div>
					<p>{{RollData.describe}}</p>
				</div>
				<div v-if="isJoin">
					<van-button type="info" style="filter: grayscale(1);">已加入</van-button>
					<div></div>
				</div>
				<div v-else-if="RollData.status == '1'">
					<van-field center clearable v-model="Password" type="password" label="密码" v-if="RollData.is_pwd == 1">
						<template #button>
							<van-button size="small" type="info" @click="GetRoomJoin">加入房间</van-button>
						</template>
					</van-field>
					<van-button type="info" v-else @click="GetRoomJoin">加入房间</van-button>
					<div></div>
				</div>
			</div>
		</div>

		<div class="prize">
			<div class="prize_nav">
				<div class="prize_tit">活动奖池</div>
				<div class="prize_info">
					<p>共<span>{{prizeList.length}}</span>件</p>
					<p>总价值:<span style="color: #75cd66;"><img src="@/assets/images/public/Gold.png" alt="">{{RollData.award_bean}}</span></p>
				</div>
			</div>
			<div class="prize_list">
				<div class="prize_item" v-for="(item,index) in prizeList" :key="index">
					<div class="prize_pic" :style="{'background-image': 'url('+item.lv_bgImage+')'}">
						<img :src="item.cover" alt="">
					</div>
					<p>{{item.name}}</p>
					<span><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span>
				</div>
			</div>
		</div>


		<div class="Award" v-if="RollData.status == 2">
			<div class="Award_nav">
				<div class="Award_tit">获奖名单</div>
			</div>
			<div class="Award_list">
				<div class="Award_item" v-show="item.is_award==1" v-for="(item,index) in prizeList" :key="index">
					<div class="Award_portrait">
						<img :src="item.user?item.user.avatar:''" alt="">
					</div>
					<div class="Award_name">{{item.user?item.user.name:''}}</div>
					<div class="Award_pic" :style="{'background-image': 'url('+item.lv_bgImage+')'}">
						<img :src="item.cover" alt="">
					</div>
					<p>{{item.name}}</p>
					<span><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span>
				</div>
			</div>
		</div>

		<div class="player">
			<div class="player_nav">
				<div class="player_tit">参与人数</div>
				<div class="player_info">
					<p>共<span>{{room_users.length}}</span>人</p>
				</div>
			</div>
			<div class="player_list">
				<div class="player_item" v-for="(item,index) in room_users" :key="index">
					<div class="player_pic">
						<img :src="item.avatar" alt="">
					</div>
					<p>{{item.name}}</p>
				</div>
			</div>
		</div>

	</div> -->
</template>

<script>
	import {
		RoomDetail,
		RoomJoin
	} from '@/network/api.js'
	import {
		mapState,
		mapMutations
	} from 'vuex'
	export default {
		name: 'room',
		data() {
			return {
				time: 30 * 60 * 60 * 1000,
				RollData: {
					box_records:[],
					bean_awards:[]
				}, //房间信息
				Avatar:'',//房主头像
				prizeList: [], //物品列表
				room_users:[],//玩家列表
				Password:'',//Roll密码
				tabIndex: 0,
				pwdOpen: false
			}
		},
		computed: {
			...mapState([
				'User'
			]),
			isJoin(){
				let isJoin = false
				if(!this.User){
					return false
				}
				let uIndex = this.room_users.findIndex(item=>{
					return item.id == this.User.id
				})
				if(uIndex>-1){
					isJoin = true
				}
				return isJoin
			}
		},
		created() {
			this.GetRoomDetail()
		},
		methods: {
			//房间详情
			GetRoomDetail() {
				RoomDetail(this.$route.query.id).then((res) => {
					// console.log(res.data.data)
					res.data.data.box_records.map(item=>{
						let bean = Number(item.bean)
						if(bean<=100){
							item.lv = 4
						}else if(bean<=300){
							item.lv = 3
						}else if(bean<=600){
							item.lv = 2
						}else{
							item.lv = 1
						}
					})
					// res.data.data.getLength = res.data.data.box_records.filter(item=>{
					// 	return item.is_award==1 
					// }).length
					this.Avatar = res.data.data.user?res.data.data.user.avatar:''
					res.data.data.describe = res.data.data.describe.replace(/\s/g, "\n");
					this.RollData = res.data.data
					let box_records = res.data.data.box_records.filter(item=>{
						return item.is_award==1 
					})
					res.data.data.bean_awards.map(item=>item.gunType='bean')
					let bean_awards = res.data.data.bean_awards.filter(item=>{
						return item.is_award==1 
					})
					this.prizeList = [...box_records,...bean_awards]
					this.room_users = res.data.data.room_users
				})
			},
			
			//加入房间
			GetRoomJoin(){
				if(this.RollData.has_join_room){
					return ;
				}
				RoomJoin(this.$route.query.id,this.Password).then((res) => {
					// console.log(res)
					this.pwdOpen = false
					this.$notify({
						type: 'success',
						message: res.data.message
					})
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.container{
		padding-bottom: 72px;
	}
</style>
<style lang="scss">
	@import "@/assets/css/base.scss";
	
	.roll-top-con{
		@include Scenter;
		flex-direction: column;
		.roll-photo{
			width: 64px;
			height: 64px;
			border: 3px solid #FFB800;
			margin-bottom: -32px;
			margin-top: 6px;
			border-radius: 50%;
			position: relative;
			z-index: 2;
			overflow: hidden;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.roll-top-main{
			width: 327px;
			height: 140px;
			margin: 0 auto;
			background-image: url(~@/assets/img/roll-detail-bg.png);
			background-size: 100% 100%;
			position: relative;
			padding-top: 42px;
			@include Scenter;
			flex-direction: column;
			.title{
				@include wordhide;
				max-width: 290px;
				font-size: 16px;
				height: 22px;
				font-weight: bold;
				margin-bottom: 8px;
			}
			.desc{
				max-width: 290px;
				@include wordhideRow(3);
				font-size: 12px;
				white-space: pre-line;
				text-align: center;
			}
			.time{
				background: #35353B;
				color: #bbb;
				padding: 0 7px;
				position: absolute;
				font-size: 12px;
				line-height: 22px;
				bottom: -11px;
				z-index: 2;
				left: 50%;
				transform: translateX(-50%);
				white-space: nowrap;
			}
		}
	}
	.win-con{
		width: 327px;
		margin: 20px auto;
		.win-title{
			color: #A1A1A1;
			font-size: 12px;
			margin-bottom: 6px;
		}
		.scroll-con{
			width: 100%;
			.el-scrollbar__wrap{
				overflow-y: hidden;
				margin-right: 0 !important;
			}
		}
		.win-list{
			display: flex;
			gap: 4px;
			.item{
				flex-shrink: 0;
				@include Scenter;
				flex-direction: column;
				width: 98px;
				height: 150px;
				border: 1px solid #3D3D4D;
				background: linear-gradient(180deg, #1D1D23 0%, #1D1D2B 100%);
				padding-top: 8px;
				font-size: 10px;
				.photo{
					width: 30px;
					height: 30px;
					border: 1px solid #3D3D4D;
					border-radius: 50%;
					overflow: hidden;
					img{
						width: 100%;
						height: 100%;
					}
				}
				.name{
					margin: 4px 0 2px;
				}
				.img-con{
					width: 62px;
					height: 42px;
					background-size: auto 100%;
					background-repeat: no-repeat;
					background-position: center center;
					&.level1{
						background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
					}
					&.level2{
						background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
					}
					&.level3{
						background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
					}
					&.level4{
						background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
					}
					&.level5{
						background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
					}
				}
				.product-name{
					@include wordhide;
					max-width: 90%;
					margin: 4px 0;
				}
				.price{
					@include Scenter;
					font-size: 15px;
					font-weight: bold;
					.coins{
						width: 14px;
						height: 14px;
						margin-right: 4px;
					}
				}
			}
		}
	}
	.roll-tab{
		width: 327px;
		margin: 20px auto 0;
		display: flex;
		justify-content: space-between;
		.item{
			@include HScenter;
			width: 161px;
			height: 32px;
			font-size: 16px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/roll-tab.png);
			cursor: pointer;
			&.active{
				background-image: url(~@/assets/img/roll-tab-active.png);
			}
		}
	}
	.roll-prize-con{
		width: 327px;
		margin: 0 auto;
		.total{
			height: 36px;
			@include Scenter;
			color: #A1A1A1;
			.price{
				color: #fff;
				margin-left: 6px;
				.coins{
					width: 20px;
					height: 20px;
					margin-right: 4px;
				}
			}
			.right{
				margin-left: auto;	
			}
		}
		.prize-list{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			row-gap: 10px;
			.item{
				width: 161px;
				height: 174px;
				@include Scenter;
				flex-direction: column;
				border: 1px solid #3D3D4D;
				background: linear-gradient(180deg, #1D1D23 0%, #1D1D2B 100%);
				padding: 20px;
				.img-con{
					width: 120px;
					height: 68px;
					background-size: auto 100%;
					background-repeat: no-repeat;
					background-position: center center;
					&.level1{
						background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
					}
					&.level2{
						background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
					}
					&.level3{
						background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
					}
					&.level4{
						background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
					}
					&.level5{
						background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
					}
				}
				.name{
					max-width: 90%;
					@include wordhide;
					font-size: 14px;
					line-height: 20px;
					margin: 12px 0;
				}
				.price{
					font-size: 15px;
					font-weight: bold;
					.coins{
						width: 20px;
						height: 20px;
						margin-right: 9px;
					}
				}
			}
		}
	}
	.roll-user-con{
		width: 327px;
		margin: 0 auto;
		.total{
			height: 36px;
			@include HScenter;
			color: #A1A1A1;
		}
		.user-list{
			display: flex;
			flex-wrap: wrap;
			gap: 14px 12px;
			.item{
				width: 54px;
				@include Scenter;
				flex-direction: column;
				.photo{
					width: 48px;
					height: 48px;
					border-radius: 50%;
					overflow: hidden;
					img{
						width: 100%;
						height: 100%;
					}
				}
				.name{
					font-size: 12px;
					margin-top: 4px;
					@include wordhide;
					max-width: 100%;
				}
			}
		}
	}
	.bottom-con{
		width: 375px;
		height: 72px;
		background: #15151B;
		position: fixed;
		bottom: 55px;
		left: 50%;
		transform: translateX(-50%);
		@include HScenter;
		.join-btn{
			width: 257px;
			height: 51px;
			@include HScenter;
			background: url(~@/assets/img/open-btn-bg.png);
			background-size: 100% 100%;
			&.disabled{
				filter: brightness(0.5);
			}
		}
	}
	.join-con{
		@include Scenter;
		flex-direction: column;
		width: 320px;
		height: 337px;
		background: url(~@/assets/img/roll-join-popup.png);
		background-size: 100% 100%;
		position: relative;
		padding-top: 26px;
		.close{
			width: 24px;
			height: 24px;
			background: url(~@/assets/img/rule-close.png);
			background-size: 100% 100%;
			position: absolute;
			right: 10px;
			top: 10px;
		}
		.join-detail-con{
			width: 288px;
			@include Scenter;
			flex-direction: column;
			.photo{
				width: 64px;
				height: 64px;
				border-radius: 50%;
				overflow: hidden;
				border: 3px solid #FFB800;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.title{
				font-size: 16px;
				@include wordhide;
				margin: 14px 0;
				max-width: 100%;
			}
			.desc{
				@include wordhideRow(3);
				font-size: 12px;
				text-align: center;
				max-width: 100%;
			}
		}
		.input-con{
			width: 288px;
			height: 40px;
			background: url(~@/assets/img/roll-join-input.png);
			background-size: 100% 100%;
			margin: 14px 0;
			input{
				width: 100%;
				height: 100%;
				text-align: center;
			}
		}
		.join-btn{
			background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
			width: 288px;
			height: 40px;
			@include HScenter;
		}
	}
</style>
