<template>
	<div id="pubfooter">
		<div class="Ft_logo">
			<img src="@/assets/img/Nav_Logo.png" alt="">
		</div>
		<!-- <div class="Ft_pic">
			<img src="@/assets/images/public/Ftimg.png" alt="">
		</div> -->
		<div class="Ft_btn">
			<p @click="agreement1=true">提货规则</p>
			<p @click="agreement2=true">用户协议</p>
			<p @click="agreement3=true">隐私协议</p>
			<p @click="agreement4=true">概率公平性</p>
		</div>
		<a class="beian" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002002487"><img src="@/assets/images/gongan.png" alt="">陕公网安备 61019002002487号</a>
		<a class="beian" href="https://beian.miit.gov.cn/#/Integrated/index">陕ICP备2023003800号-1</a>
		<div class="copyright">版权所有 ©2023-2024 wanskins.com All Rights Reserved</div>
		<!-- <div class="popup-con">
			<div class="popup-main">
				<div class="title">提货规则</div>
				<div class="content">
					
				</div>
				<div class="btn">我知道了</div>
			</div>
		</div> -->
		<div class="popup-con" v-show="agreement1">
			<div class="popup-main">
				<div class="popup-title">
					提货条款与协议
					<img src="@/assets/images/icon-close.png" alt="" class="close" @click="agreement1=false">
				</div>
				<div class="content">
					<div class="bg"></div>
					<div class="scroll-con">
						<el-scrollbar style="height:100%;">
							<div class="word-content">
								<div>一、退款条款与免责条款</div>
								<p>1、用户理解并确认，在使用本条款项下的服务的过程中，可能会遇到不可抗力等风险因素，使得充值不到账情况。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成影响的客观事件，包括服务器异常、紧急突发bug、支付渠道延迟到账等问题。出现上述情况时，wanskins.com 将努力在第一时间配合，及时进行修复，并保证充值到账。但此项并不可以作为退款理由</p>
								<p>2、本条款项下的服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本条款项下的服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</p>
								<p>3、用户理解并确认，本条款项下的服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，wanskins.com 及时进行修复，但在法律允许的范围内免责，并且拒绝不合理投诉。</p>
								<p>4、用户理解并确认，在使用本条款项下的服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，wanskins.com 不承担任何责任。</p>
								<p>5、用户理解并确认，wanskins.com 需要定期或不定期地对wanskins.com 或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，平台无需为此承担任何责任，但wanskins.com 应事先进行通告。</p>
								<div>二、取回条款</div>
								<p>1、当用户取回的饰品缺货时，可以向客服申请补货，如供货商暂时缺货，请耐心等待。</p>
								<p>2、如果出现了取回失败、取回延迟到账问题，wanskins.com 有义务第一时间为用户解决问题，保障用户权益。但这并不是用户退款或恶意投诉的理由。</p>
								<p>3、用户如果出现了误提，或账号被盗导致了提取，平台尽力帮助用户解决问题。但这并不是用户退款或恶意投诉的理由</p>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</div>
		<div class="popup-con" v-show="agreement2">
			<div class="popup-main">
				<div class="popup-title">
					用户條款
					<img src="@/assets/images/icon-close.png" alt="" class="close" @click="agreement2=false">
				</div>
				<div class="content">
					<div class="bg"></div>
					<div class="scroll-con">
						<el-scrollbar style="height:100%;">
							<div class="word-content">
								<p>您同意在我們的網站上注册帳戶或以其他管道使用我們的服務，即表示您同意以下條款和條件（“條款”）。如果您不同意這些條款，請勿注册帳戶或以其他管道使用我們的服務。
								請注意，您對服務的使用也受我們隱私政策的約束。本公司保留隨時更新條款和隱私政策的權利，恕不另行通知。</p>
								<div class="title">1 .定義</div>
								<p>帳戶：用戶在平臺上為訪問服務而創建的一個或多個帳戶。</p>
								<p>平臺：公司和/或其附屬機构發佈的平臺，允許用戶與其他用戶交換通證。該平臺不允許用戶將通證交換為法定貨幣。</p>
								<p>服務：通過網站、附屬網站、應用程序和平臺提供的任何服務。</p>
								<p>網站：wanskins.com，網址為：https://wanskins.com/</p>
								<p>用戶：任何服務的用戶（此處對“您”或“您的”的所有引用均指每個用戶）。</p>
								<div class="title">2 .資格、限制區域和允許用途</div>
								<p>資格。通過訪問服務，您需保證：</p>
								<p>（1）年齡至少為18歲，或至少為適用法律規定的最低年齡，以便我們無需經過您父母同意即可合法向您提供服務；</p>
								<p>（2）加入這些條款（並且在法律上有能力這樣做）；</p>
								<p>（4）所在國家適用法律不禁止訪問和使用服務；</p>
								<p>（5）未有過因違法操作，被暫停交易或者調離交易現場的記錄；</p>
								<p>（6）擁有您在平臺上使用的所有通證（或您能够與之進行授權交易的通證）；</p>
								<p>（7）擁有全部必要的同意和授權，以執行從您的帳戶發起的全部交易；</p>
								<p>（8）提供真實、準確、完整的開戶資料；</p>
								<p>（9）已審閱本條款所載的風險披露聲明，並同意承擔其中所述的所有風險。</p>
								<p>允許使用。您同意在訪問服務時遵守所有適用的法律和法規。您同意不將服務用於任何非法活動，包括但不限於非法賭博、洗錢、欺詐、敲詐、勒索、資料交換、資助恐怖主義或其他暴力活動。您還同意，您不會對平臺、網站和應用程序進行駭客、反編譯、反向工程、反彙編、嘗試獲取原始程式碼、解密或以其他管道損害其完整性和安全性。</p>
								<div class="title">3 .帳戶</div>
								<p>您必須創建帳戶才能訪問和使用服務，並且必須提供有效的電子郵寄地址才能創建帳戶。您必須在創建帳戶時提供完整、準確和最新的資訊，並在發生任何更改時立即更新這些資訊。您可以請求公司删除或去除您提供的任何資訊；但是，您承認並同意公司可以拒絕您的請求或恢復以前删除和去除的任何資訊。您必須對您帳戶下發生的所有活動負責，並承擔未經授權訪問的所有風險。您同意維護登入憑證的安全性和機密性，並拒絕在未經授權的情况下（無論是通過網站、應用程序還是其他管道）訪問您的帳戶。如果您發現或懷疑任何未經授權訪問或使用您的帳戶，必須立即通知公司。</p>
								<p>您同意公司：有權限制任何用戶或關聯公司能够創建的帳戶數量</p>
								<p>您同意本公司保留：隨時以任何理由終止您的帳戶的權利和唯一決定權。</p>
								<div class="title">4 .風險披露錶</div>
								<p>（A）科技</p>
								<p>複雜性。通證一般用非常技術性的語言描述；需要對應用密碼學和計算機科學有全面的瞭解，才能認識到固有的風險。通過使用本服務，您表示並保證您擁有足够的知識、市場成熟度、經驗和/或專業建議，足以對您根據本服務所進行的所有交易的價值和風險進行審慎評估。您同意承擔上述評估的全部責任。
					網絡攻擊。如果平臺、應用程序、網站或服務的任何其他組件遭到網絡攻擊，您的帳戶和其中的通證可能會受到不利影響。本公司及其附屬公司均不保證其在發生此類攻擊時可預見、預防、減輕或採取糾正措施。
					暫停/停止支助/撤出。如果在平臺上交易的特定通證發生分叉或網絡攻擊，您同意公司可以暫時中止相關通證的服務（無論是否事先通知您），並且公司可以不支持（或停止支持）分叉通證。如果可行，公司將為您提供收回此類通證的機會。有關受攻擊通證的存款、提款和用戶餘額的決議將由公司根據具體情況自行决定。本公司不就平臺的安全性做出任何陳述或保證，也不對任何價值損失或被盜財產負責，無論本公司是否疏忽提供適當的安全性。</p>
								<p>（B）交易失敗；網上存款條款</p>
								<p>平臺上的交易可能由於若干原因而失敗，包括但不限於價格變化。本公司不對任何交易的正常執行做出任何陳述或保證。本公司特此聲明：放弃負責因任何交易失敗而產生、或與任何交易失敗相關的所有損失和損害。您同意並承認公司並沒有責任通知交易失敗。公司可在任何時候拒絕執行交易、施加交易金額的限制或約束，無需事先通知，並可自行决定。具體而言，如果公司懷疑可能存在洗錢、資助恐怖主義、欺詐或任何其他非法行為，或者公司懷疑交易違反了相關服務條款[這些條款是指？]（例如，涉及開設帳戶和隨後關閉帳戶而不進行任何實際交易的交易），公司保留拒絕處理、取消或撤銷任何交易和禁用用戶帳戶的權利。雖然公司可酌情撤銷交易，但用戶不得更改、撤回或取消其交易授權，但部分填寫的訂單除外。本公司可更正、沖銷或取消任何因處理交易訓示錯誤及其他原因而影響的交易。如果出現錯誤，您的補救措施將受到限制。我們不能保證此類取消或退款始終是可能的。公司的平臺運行使得每個用戶的訂單與其他用戶的訂單相匹配。根據下訂單時的交易活動，可以部分填寫訂單或填寫多個訂單。您確認公司為每個帳戶提供加密通證存儲功能，以便進行交易。</p>
								<p>（C）中止</p>
								<p>本公司有權隨時終止先前提供的通證可用性。在這種情況下，公司將為您提供收回這些通證的機會。</p>
								<p>5 .許可限制</p>
								<p>本公司授予您有限、非排他性、不可轉讓的許可，允許您訪問和使用本服務（無論是通過應用程序還是網站），僅用於本服務的預期功能以及本公司明確允許的其他準予用途。明確禁止任何其他使用服務的行為。</p>
								<p>使用應用程序時，您同意不會去：</p>
								<p>（A）反編譯、反向工程、反彙編、嘗試匯出應用程序的原始程式碼或解密應用程序；</p>
								<p>（B）對應用程序進行任何修改、改編、改進、增强、翻譯或衍生作品；</p>
								<p>（C）違反與您訪問或使用應用程序相關的任何適用法律、規則或法規；</p>
								<p>（E）將該應用程序用於並非設計或意圖用於的任何其他目的；</p>
								<p>（F）通過網絡提供應用程序，或以其他管道允許多個移動設備或用戶同時訪問或使用該應用程序；</p>
								<p>（G）使用應用程序向任何網站發送自動査詢或發送任何未經請求的商業電子郵件；</p>
								<p>（H）未經授權使用公司或其關聯公司的專有資訊或知識產權（包括但不限於商標）。</p>
								<div class="title">6 .用戶行為守則</div>
								<p>使用或訪問服務時，您同意不會：</p>
								<p>（A）通過密碼挖掘或其他管道獲取或企圖獲取對網站、其他用戶帳戶或服務任何其他組成部分的未經授權訪問；</p>
								<p>（B）在網站或服務的任何組成部分上使用或企圖使用網路爬蟲</p>
								<p>（C）對我們的基礎設施造成巨大負擔；</p>
								<p>（D）將含有電腦病毒、特洛伊木馬或蠕蟲的任何資料上載到網站。</p>
								<div class="title">7 .隱私政策相關</div>
								<p>你的隱私對我們很重要。但是，我們可能被迫根據相應的法律、規則或法規共亯您的資訊。有關詳細資訊，請參閱我們的《隱私政策》。</p>
								<div class="title">8 .反洗錢和驗證用戶身份政策</div>
								<p>公司執行銀行層面的（“驗證用戶身份”）流程，以遵守反洗錢（AML）法律。該公司對照政府觀察名單對用戶數據進行交叉核對。如果用戶或用戶的交易被標記為可疑，公司將要求用戶提供額外的身份證明，並可暫停該用戶的任何交易、存款或提款，直至收到令公司滿意的身份證明。</p>
								<p>公司對用戶驗證級別使用內部政策。本公司有權利為任何用戶確定適當驗證級別和標準的，以及在不事先通知的情况下降低用戶級別的權利。公司可隨時執行措施，根據國籍、居住國或任何其他因素限制核查水准和標準。</p>
								<p>您同意，如果無法達到所需的驗證級別，則可能無法存放或選取通證。您同意獨自承擔責任，不要求公司或其關聯公司對此類損失承擔責任。</p>
								<p>您特此授權公司直接（或通過協力廠商）進行任何我們所認為必要的査詢，以驗證您的身份並/或防止欺詐行為的發生，包括査詢公開報告中包含的身份資訊（例如您的姓名、地址、過去地址或出生日期），査詢與您關聯的銀行帳戶相關的帳戶資訊（例如姓名或帳戶餘額），並根據此類査詢和報告的結果採取我們認為合理且必要的行動。您還授權可能收到此類査詢或請求的任何和所有協力廠商對此類査詢或請求做出全面響應。</p>
								<div class="title">9 .不可轉讓性</div>
								<p>雖然向用戶提供的帳戶和服務不可轉讓，但公司有權將帳戶和服務的所有權、利益進行義務轉讓、轉讓或出售給任何人，這些條款對公司或其貸款人的繼承人和受讓人（如有）繼續有效。</p>
								<div class="title">10 .永久性撤回服務</div>
								<p>公司有權暫停或終止您對服務的訪問。以及如果公司根據適用的法律、規則或法規，懷疑您或其他人使用您的帳戶從事非法活動，則可以停用或取消您的帳戶。在這種情況下，除非另有相應的法律禁止，否則您可以在帳戶停用或取消後60天內轉讓與您的帳戶相關的通證。如果您的帳戶在被取消或暫停時，任何交易處於掛起狀態，則可根據需要取消或退還此類交易。</p>
								<p>通證返還流程：公司註銷您的帳戶——您授權公司取消或暫停掛起交易——公司通過服務或應用程序向您發出通知——與交易相關聯的通證會返回公司為您保留的錢包地址。
					如果本公司無法通過合理的商業努力將您的通證歸還給CoinFit為您存檔的錢包地址，本公司可根據香港無人認領財產法向適用的政府機構報告和匯寄通證。</p>
								<div class="title">11.免責聲明與免責事項</div>
								<p>用戶瞭解並同意，在任何情况下，我們不就以下各事項承擔責任：</p>
								<p>（1）收入的損失；</p>
								<p>（2）交易利潤或契约損失；</p>
								<p>（3）業務中斷；</p>
								<p>（4）預期可節省的貨幣的損失；</p>
								<p>（5）資訊的損失；</p>
								<p>（6）機會、商譽或聲譽的損失；</p>
								<p>（7）數據的損壞或損失；</p>
								<p>（8）購買替代產品或服務的成本；</p>
								<p>（9）數位資產消亡或退出市場；</p>
								<p>（10）任何由於侵權（包括過失）、違約或其他任何原因產生的間接的、特殊的或附帶性的損失或損害，不論這種損失或損害是否可以為我們合理預見；不論我們是否事先被告知存在此種損失或損害的可能性。</p>
								<p>（1）條至（10）條均是彼此獨立的。</p>
								<p>用戶瞭解並同意，我們不對因下述任一情况而導致用戶的任何損害賠償承擔責任：</p>
								<p>（1）我們有合理理由認為用戶的具體交易事項可能存在重大違法或違約情形。</p>
								<p>（2）我們有合理的理由認為用戶在我們的行為涉嫌違法或不當。</p>
								<p>（3）通過我們服務購買或獲取任何數據、資訊或進行交易等行為或替代行為產生的費用及損失。</p>
								<p>（4）用戶對本網站服務的誤解。</p>
								<p>（5）非因我們的原因而造成用戶資訊被洩露、毀損或滅失。</p>
								<p>（6）任何非因我們的原因而引起的與我們提供的服務有關的其它損失。</p>
								<p>（7）我們不能保證我們包含的全部資訊、程式、文字等完全安全，不受任何病毒、木馬等惡意程式的干擾和破壞，故用戶登陸、使用我們任何服務或下載及使用該下載的任何程式、資訊、數據等均是用戶個人的决定並自行承擔風險及可能產生的損失。</p>
								<p>（8）我們對我們連結的任何協力廠商網站的任何資訊、產品及業務及其他任何形式的不屬於我們的主體的內容等不做任何保證和承諾，用戶如果使用協力廠商網站提供的任何服務、資訊及產品等均為用戶個人决定且承擔由此產生的一切責任。</p>
								<p>（9）我們對於用戶使用我們服務不做任何明示或暗示的保證，包括但不限於我們提供服務的適用性、沒有錯誤或疏漏、持續性、準確性、可靠性、適用於某一特定用途。同時，我們也不對我們提供的服務所涉及的科技及資訊的有效性、準確性、正確性、可靠性、質量、穩定、完整和及時性作出任何承諾和保證。是否登入或使用我們提供的服務是用戶個人的决定且自行承擔風險及可能產生的損失。我們對於數位資產的市場、價值及價格等不做任何明示或暗示的保證，用戶理解並瞭解數位資產市場是不穩定的，價格和價值隨時會大幅波動或崩盤，交易數位資產是用戶個人的自由選擇及决定且自行承擔風險及可能產生的損失。</p>
								<p>（10）協定中規定的我們的保證和承諾是由我們就本協定和我們提供的服務的唯一保證和陳述，並取代任何其他途徑和管道產生的保證和承諾，無論是書面的或口頭的，​明示的或暗示的。所有這些保證和陳述僅僅代表我們自身的承諾和保證，並不保證任何協力廠商遵守本協定中的保證和承諾。</p>
								<p>（11）我們並不放弃本協定中未提及的在法律適用的最大範圍內我們享有的限制、免除或抵銷我們損害賠償責任的任何權利。</p>
								<div class="title">12 .適用法律和爭議解决</div>
								<p>本使用條款應受香港法律管轄，並應在所有方面解釋為香港契约。由本使用條款引起或與本使用條款相關的任何索賠或訴訟（包括侵權索賠）應受香港法律管轄，並根據香港法律進行解釋和執行。</p>
								<p>為加快並控制爭議成本，您同意，您使用服務或本條款、或您使用服務或本條款相關的任何法律引起的索賠，包括本條款的形成、有效性、可執行性、範圍或適用性（以下簡稱“索賠”）將按以下管道解决：除了禁令或衡平法上的救濟索賠或知識產權索賠（可在任何首長法院提起而無需出具保函）之外，您使用服務引起的任何爭議應根據香港國際仲裁中心（以下簡稱“仲裁中心”）通過保密、有約束力的仲裁最終解決。這意味著，除知識產權訴訟之外的所有索賠，如版權或商標侵權訴訟，或尋求非金錢救濟的索賠，都將受到具有約束力的仲裁。仲裁應以英文在香港進行，仲裁决定可在任何法院執行。</p>
								<p>要開始仲裁程式，您必須通過郵件發送一封請求仲裁的信函，並向我們描述您的索賠要求.</p>
								<div class="title">13 .沒有集體訴訟</div>
								<p>您和公司只能以您或其個人身份對另一方提出索賠，而不能在任何聲稱的類別、代表、合併或多索賠人訴訟中作為原告或類別成員提出索賠。
					你和公司都放弃了陪審團審判的權利。</p>
								<div class="title">14 .可分性；棄權</div>
								<p>如果本條款中的任何條款被視為非法、無效或由於任何原因無法執行，則該條款應被視為可與本條款分離，且不會影響任何剩餘條款的有效性和執行性。本公司延遲或未能執行其任何權利不應構成對該等權利的持續放弃。</p>
								<div class="title">15 .綜合</div>
								<p>本條款以及公司發佈的任何政策或運營規則構成您與公司之間的完整協定和瞭解，並取代您與公司之間以前或同時期的任何形式的協定、溝通和建議（包括但不限於本條款的任何以前版本）。</p>
								<div class="title">16.不可抗力因素</div>
								<p>除上述適用免責聲明外，如果由於超出公司合理控制範圍的原因（包括但不限於天灾、任何政府行為、戰爭或其他敵對行為、內亂、火灾、水灾、暴風雪、地震、爆炸、禁運、恐怖主義行為、停電、設備故障、勞資糾紛或爭議、任何協力廠商數據提供商或其他協力廠商資訊提供商的行為、協力廠商軟件或通信方法中斷）而導致中斷和/或延遲，公司應免除履行本條款。</p>
								<div class="title">17 .修正</div>
								<p>公司可隨時修改、删除或以其他管道修改本使用條款和隱私政策中的任何條款；前提是，公司至少在修改前七天，通過您在公司存檔的電子郵件向您發出通知。</p>
								<div class="title">18 .暫停和終止服務</div>
								<p>公司可出於任何原因，在事先通知或不事先通知您的情况下，暫停和終止服務。您承認並同意，如果市場狀況不穩定、不合理，公司可在事先通知或不通知您的情况下，通過啟動交易限制或其他適當的措施，終止服務。</p>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</div>
		<div class="popup-con" v-show="agreement3">
			<div class="popup-main">
				<div class="popup-title">
					隐私政策
					<img src="@/assets/images/icon-close.png" alt="" class="close" @click="agreement3=false">
				</div>
				<div class="content">
					<div class="bg"></div>
					<div class="scroll-con">
						<el-scrollbar style="height:100%;">
							<div class="word-content">
								<div class="title">wanskins.com隐私政策</div>
								<p>
									本《隱私政策》適用於我們提供的一切wanskins.com服務。當您使用我們任何單項服務時，您同意接受本《隱私政策》以及我們在該單項服務中發出的特定隱私資訊類政策條款（下列稱“特定條款”）的保護，在此情况下特定條款與本政策條款同時對您產生效力。
									請注意我們不時地會檢查我們的政策，囙此有關的措施會隨之變化。我們懇請您定期光顧本頁面，以確保對我們《隱私政策》最新版本始終保持瞭解。在閱讀完本政策之後，如您對本《隱私政策》或與本《隱私政策》相關的事宜有任何問題，請與wanskins.com客服聯系。
								</p>
								<p>
									您使用或繼續使用wanskins.com服務，都表示您同意我們按照本《隱私政策》收集、使用、儲存和分享您的資訊
								</p>
								
								<div class="title">一、我們可能收集的資訊</div>
								
								<p>
									（一）與個人身份無關的資訊：
								</p>
								<p>當您使用wanskins.com服務時，我們可能收集和匯總諸如用戶的來源途徑、訪問順序等資訊，例如記錄使用wanskins.com服務的每個用戶的來源途徑、瀏覽器軟件等。</p>
								<p>（二）有關個人身份的資訊：</p>
								<p>
									當您使用wanskins.com服務時，我們可能收集和匯總或要求您提供有關個人身份的資訊，例如個人：生日、籍貫、性別、興趣愛好、個人電話號碼、（包括系統帳號、IP地址、電子郵箱地址等）；個人財產資訊（交易記錄、餘額、優惠券、兌換碼等）；通訊資訊；個人上網記錄和日誌資訊（包括網站瀏覽記錄、軟體使用記錄、點擊記錄、操作日誌等）；設備資訊（包括設備型號、設備MAC地址、作業系統類型、設備設定）；軟件清單唯一設備識別碼（如IMEI/androidID/IDFA/OPENUDID/GUID、SIM卡IMSI資訊等在內的描述個人常用設備基本情况的資訊）；位置資訊（包括行程資訊、精准定位資訊、住宿資訊、經緯度等）。
									我們收集您的資訊主要是為了您和其他用戶能够更容易和更滿意地使用wanskins.com服務。wanskins.com的目標是向所有的互聯網用戶提供安全、刺激、有趣及有教益的上網經歷。而這些資訊有助於我們實現這一目標。
								</p>
								
								<div class="title">二、我們如何收集和使用資訊</div>
								<p>
									（一）我們將通過以下途徑收集和獲得您的資訊：
								</p>
								<p>1、您提供的資訊。例如：</p>
								<p>（1）您在注册wanskins.com服務的帳號或使用wanskins.com服務時，向我們提供的資訊；</p>
								
								<p>
									（2）您通過wanskins.com服務向其他方提供的共亯資訊，以及您使用wanskins.com服務時所儲存的資訊。
								</p>
								<p>2、我們獲取的您的資訊。您在使用wanskins.com服務時，我們收集、匯總、記錄的資訊，例如日誌資訊、位置資訊、設備資訊等。</p>
								<p>
									（二）COOKIES、日誌檔案和WEB BEACON
								</p>
								<p>我們或我們的協力廠商合作夥伴可能通過COOKIES和WEB
									BEACON獲取和使用您的資訊，並將該等資訊儲存為日誌資訊。通過使用COOKIES，我們向用戶提供簡單易行並富個性化的網絡體驗。一個COOKIES是少量的數據，它們從一個網絡服務器送至您的瀏覽器並存在電腦硬碟上。我們使用COOKIES是為了讓其用戶可以受益。比如，為使得wanskins.com虛擬社群的登入過程更快捷，您可以選擇把用戶名存在一個COOKIES中。這樣下次當您要登入wanskins.com的服務時能更加方便快捷。COOKIES能幫助我們確定您連接的頁面和內容，您在wanskins.com特定服務上花費的時間和您所選擇的wanskins.com服務。COOKIES使得我們能更好、更快地為您服務，並且使您在wanskins.com服務上的經歷更富個性化。然而，您應該能够控制COOKIES是否以及怎樣被你的瀏覽器接受。請查閱您的瀏覽器附帶的檔案以獲得更多這方面的資訊。
									我們使用自己的COOKIES和WEB BEACON，用於以下用途：</p>
								
								<p>（1）記住您的身份。例如：COOKIES和WEB BEACON有助於我們辨認您作為我們的註冊用戶的身份，或保存您向我們提供有關您的喜好或其他資訊；</p>
								
								<p>（2）分析您使用我們服務的情况。我們可利用COOKIES和WEB BEACON來瞭解您使用wanskins.com服務進行什麼活動、或哪些服務或服務最受歡迎；及</p>
								
								<p>
									（3）廣告優化。COOKIES和WEB BEACON有助於我們根據您的資訊，向您提供與您相關的廣告而非進行普遍的廣告投放。
								</p>
								<p>
									我們為上述目的使用COOKIES和WEB BEACON的同時，可能將通過COOKIES和WEB BEACON收集的非個人身份資訊匯總提供給廣告商和其他夥伴，用於分析您和其他用戶如何使用wanskins.com服務並用於廣告服務。
								</p>
								
								<p>
									wanskins.com服務上可能會有廣告商和其他合作方放置的COOKIES和WEB BEACON。這些COOKIES和WEB
									BEACON可能會收集與您相關的非個人身份資訊，以用於分析用戶如何使用該等服務、向您發送您可能感興趣的廣告，或用於評估廣告服務的效果。這些協力廠商COOKIES和WEB
									BEACON收集和使用該等資訊不受本《隱私政策》約束，而是受到其自身的個人資訊保護聲明約束，我們不對協力廠商的COOKIES或WEB BEACON承擔責任。
								</p>
								<p>您可以通過瀏覽器或用戶選擇機制拒絕或管理COOKIES或WEB BEACON。但請您注意，如果您停用COOKIES或WEB
									BEACON，我們有可能無法為您提供最佳的服務體驗，某些服務也可能無法正常使用。同時，您仍然將收到廣告，只是這些廣告與您的相關性會降低。</p>
								
								
								<p>
									（三）我們會出於以下目的，收集和使用您的資訊：
								</p>
								<p>1、幫助您完成注册</p>
								<p>為便於我們為您提供服務，您需要提供基本注册資訊，例如手機號碼、電子郵箱地址等，並創建您的用戶名和密碼。在部分單項服務中，如果您僅需使用瀏覽、蒐索等基本服務，您不需要注册成為wanskins.com用戶及提供上述資訊。</p>
								<p>2、向您提供商品或服務</p>
								<p>
									我們所收集和使用的資訊是為您提供wanskins.com服務的必要條件，如缺少相關資訊，我們將無法為您提供wanskins.com服務的覈心內容，例如：
								</p>
								<p>（2）為展示您帳戶的訂單資訊，我們會收集您在使用wanskins.com服務過程中產生的訂單資訊用於向您展示及便於您對訂單進行管理；</p>
								<p>（3）當您與我們聯系時，我們可能會保存您的通信/通話記錄和內容或您留下的聯繫方式等資訊，以便與您聯系或幫助您解决問題，或記錄相關問題的處理方案及結果。</p>
								<p>（4）為確認交易狀態及為您提供售後與爭議解决服務，我們會通過您基於交易所選擇的交易對象、支付機构、物流公司等收集與交易進度相關的您的交易、支付、物流資訊，或將您的交易信息共亯給上述服務提供者。</p>
								<p>
									3、為您提供安全保障
								</p>
								<p>為確保您身份真實性、向您提供更好的安全保障，您可以向我們提供身份證明、面部特徵等生物識別資訊等個人敏感資訊以完成實名認證。
									除身份驗證外，我們可能將您的資訊用於客戶服務、安全防範、詐騙監測、存檔和備份等用途，確保我們向您提供的服務的安全性；我們可能使用或綜合我們所收集的您的資訊，以及我們的合作夥伴取得您授權或依據法律共亯的資訊，來綜合判斷您帳戶及交易風險、進行身份驗證、檢測及防範安全事件，並依法採取必要的記錄、稽核、分析、處置措施。</p>
								
								<div class="title">三、我們可能分享、轉讓或披露的資訊</div>
								<p>
									（一）分享
								</p>
								<p>
									除以下情形外，未經您同意，我們不會與wanskins.com之外的任何協力廠商分享您的資訊：
								</p>
								<p>1、向您提供我們的服務。我們可能向合作夥伴及其他協力廠商分享您的資訊，以實現您需要的覈心功能或提供您需要的服務，例如：向物流服務商提供對應的訂單資訊；</p>
								<p>2、維護和改善我們的服務。我們可能向合作夥伴及其他協力廠商分享您的資訊，以幫助我們為您提供更有針對性、更完善的服務，例如：代表我們發出電子郵件或推送通知的通訊服務提供者等；</p>
								<p>3、實現本《隱私政策》第二條“我們如何收集和使用資訊”部分所述目的；</p>
								<p>
									4、履行我們在本《隱私政策》或我們與您達成的其他協定中的義務和行使我們的權利；
								</p>
								<p>5、向委託我們進行推廣的合作夥伴等協力廠商共亯，但我們僅會向這些委託方提供推廣的覆蓋面和有效性的資訊，而不會提供可以識別您身份的資訊，例如姓名電話號碼或電子郵箱；或者我們將這些資訊進行匯總，以便它不會識別您個人。比如我們可以告知該委託方有多少人看了他們的推廣資訊或在看到這些資訊後購買了委託方的商品，或者向他們提供不能識別個人身份的統計資訊，幫助他們瞭解其受眾或顧客。</p>
								<p>
									6、在法律法規允許的範圍內，為了遵守法律、維護我們及我們的關聯方或合作夥伴、您或其他wanskins.com用戶或社會公眾利益、財產或安全免遭損害，比如為防止欺詐等違法活動和减少信用風險，我們可能與其他公司和組織交換資訊。不過，這並不包括違反本《隱私政策》中所作的承諾而為獲利目的出售、出租、共亯或以其它管道披露的資訊。
								</p>
								<p>（二）轉讓</p>
								<p></p>
								<p>
									1、隨著我們業務的持續發展，我們有可能進行合併、收購、資產轉讓或類似的交易，而您的資訊有可能作為此類交易的一部分而被轉移。我們會要求新的持有您資訊的公司、組織繼續受本《隱私政策》的約束，否則，我們將要求該公司、組織重新向您徵求授權同意。
								</p>
								<p>2、在獲得您的明確同意後，我們會向其他方轉讓您的資訊。</p>
								<p>（三）披露
									我們僅會在以下情况下，且採取符合業界標準的安全防護措施的前提下，才會披露您的資訊：</p>
								<p>1、根據您的需求，在您明確同意的披露管道下披露您所指定的資訊；</p>
								<p>
									2、根據法律、法規的要求、強制性的行政執法或司法要求所必須提供您資訊的情况下，我們可能會依據所要求的資訊類型和披露管道披露您的資訊。在符合法律法規的前提下，當我們收到上述披露資訊的請求時，我們會要求接收方必須出具與之相應的法律檔，如傳票或調查函。我們堅信，對於要求我們提供的資訊，應該在法律允許的範圍內盡可能保持透明。我們對所有的請求都進行了慎重的審查，以確保其具備合法依據，且僅限於執法部門因特定調查目的且有合法權利獲取的數據。
								</p>
								<div class="title">
									四、我們如何保留、儲存和保護資訊
								</div>
								<p>我們將採取以下手段保護您的資訊：</p>
								<p>（一）數據安全技術措施</p>
								<p>我們會採用符合業界標準的安全防護措施，包括建立合理的制度規範、安全技術來防止您的資訊遭到未經授權的訪問使用、修改，避免數據的損壞或遺失。網路服務採取了多種加密技術，例如在某些服務中，我們將利用加密技術（例如SSL）來保護您的資訊，採取加密技術對您的資訊進行加密保存，並通過隔離科技進行隔離。在資訊使用時，例如資訊展示、資訊關聯計算，我們會採用多種數據脫敏科技增强資訊在使用中安全性。採用嚴格的數據訪問許可權控制和多重身份認證科技保護資訊，避免數據被違規使用。</p>
								<p>（二）我們為保護資訊採取的其他安全措施</p>
								<p>我們通過建立數據分類分級制度、資料安全管理規範、資料安全開發規範來管理規範資訊的存儲和使用。
									我們通過資訊接觸者保密協定、監控和稽核機制來對數據進行全面安全控制。
									加强安全意識。我們還會舉辦安全和隱私保護培訓課程，加强員工對於保護資訊重要性的認識。</p>
								<p>（三）我們僅允許有必要知曉這些資訊的wanskins.com員工、合作夥伴訪問您的資訊，並為此設定了嚴格的訪問許可權控制和監控機制。我們同時要求可能接觸到您的資訊的所有人員履行相應的保密義務。如果未能履行這些義務，可能會被追究法律責任或被中止與wanskins.com的合作關係。</p>
								<p>（四）我們會採取一切合理可行的措施，確保未收集無關的資訊。</p>
								<p>（五）互聯網並非絕對安全的環境，而且電子郵件、即時通訊、社交軟件或其他服務軟件等與其他用戶的交流管道無法確定是否完全加密，我們建議您使用此類工具時請使用複雜密碼，並注意保護您的資訊安全。</p>
								<p>（六）互聯網環境並非百分之百安全，我們將盡力確保或擔保您發送給我們的任何資訊的安全性。如果我們的物理、科技、或管理防護設施遭到破壞，導致資訊被非授權訪問、公開披露、篡改、或毀壞，導致您的合法權益受損，我們將承擔相應的法律責任。</p>
								<p>（七）安全事件處置</p>
								<p>在通過wanskins.com服務與協力廠商進行溝通或購買商品及服務時，您不可避免的要向交易對方或潜在的交易對方披露自己的資訊，如聯絡方式或者郵政地址等。請您妥善保護自己的資訊，僅在必要的情形下向他人提供。
									為應對資訊洩露、損毀和遺失等可能出現的風險，我們製定了多項制度，明確安全事件、安全性漏洞的分類分級標準及相應的處理流程。我們也為安全事件建立了專門的應急回應團隊，按照安全事件處置規範要求，針對不同安全事件啟動安全預案，進行止損、分析、定位、製定補救措施、聯合相關部門進行溯源和打擊。
									在不幸發生資訊安全事件後，我們將按照法律法規的要求，及時向您告知：安全事件的基本情况和可能的影響、我們已採取或將要採取的處置措施、您可自主防範和降低風險的建議、對您的補救措施等。我們同時將及時將事件相關情况以郵件、信函、電話、推送通知等管道告知您，難以逐一告知資訊主體時，我們會採取合理、有效的管道發佈公告。同時，我們還將按照監管部門要求，主動上報資訊安全事件的處置情况。
									請您理解，由於科技的限制以及風險防範的局限，即便我們已經儘量加强安全措施，也無法始終保證資訊百分之百的安全。您需要瞭解，您接入wanskins.com服務所用的系統和通訊網絡，有可能因我們可控範圍外的情况而發生問題。</p>
								<div class="title">五、如何聯繫我們</div>
								<p>如您有關於網絡資訊安全的投訴和舉報，或您對本《隱私政策》、您的資訊的相關事宜有任何問題、意見或建議，以及有關本聲明或wanskins.com的隱私措施的問題請與wanskins.com的協調人聯系</p>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</div>
		<div class="popup-con" v-show="agreement4">
			<div class="popup-main">
				<div class="popup-title">
					爆率公平性
					<img src="@/assets/images/icon-close.png" alt="" class="close" @click="agreement4=false">
				</div>
				<div class="content">
					<div class="bg"></div>
					<div class="scroll-con">
						<el-scrollbar style="height:100%;">
							<div class="word-content">
								<p>本网站秉持着公平，公正，公开的原则来为大家提供开箱服务。</p>
								<p>
									在每次开箱开始时，服务会生成一个介于 0 到 1 间的长随机数（例如 0.296792743），并通过 SHA-2 算法进行加密。回合结束时，服务会将此数字乘以活动总数，进而获得最终结果的编号。加密方式和加密码我们会在回合之后公布，保证活动的公平性。这样保证了用户每次开箱时掉落完全随机，且后台不可操控。PS：回合结束时，活动为C1000（活动中有 100000 张票），生成的数字 0.
									296792743。服务将 0.296792743 乘以 100000，进而得到将在抽奖时显示的编号 29679。
								
									我们会在之后的更新中加入公平性验证功能。用户可以设置自己的随机数种子，并且可以在开箱后通过提供的脚本来验证此次开箱的有效性。具体的内容会在后续更新实装，敬请期待。</p>
							</div>
						</el-scrollbar>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ApiInfo } from '@/network/api.js'
	export default{
		name:'pubfooter',
		data(){
			return{
				agreement1:false,
				agreement2:false,
				agreement3:false,
				agreement4:false,
			}
		},
		
		created() {
			this.GetApiInfo()
		},
		
		methods:{
			GetApiInfo(){
				ApiInfo().then((res) => {
					// console.log(res.data.data.static_file_url)
					localStorage.setItem('static_file_url',res.data.data.static_file_url)
				})
			}
		}
	}
</script>

<style lang="scss">
	#pubfooter{
		width: 375px;
		padding: 10px 0 20px;
		background: linear-gradient(180deg, #0F1923 0%, #0F1923 100%);
		// position: absolute;
		// bottom: 0;
		// left: 50%;
		// transform: translateX(-50%);
		.Ft_logo{
			margin: 0 auto;
			text-align: center;
			margin-bottom: 12px;
			img{
				height: 24px;
			}
		}
		.Ft_pic{
			width: 100%;
			margin: 0 auto 10px;
			text-align: center;
			img{
				width: 80%;
			}
		}
		.Ft_btn{
			width: 100%;
			display: flex;
			p{
				flex: 1;
				color: #fff;
				text-align: center;
				font-size: 12px;
				cursor: pointer;
			}
		}
		a{
			// display: block;
			text-align: center;
			font-size: 12px;
			color: #acacac;
			margin-top: 10px;
		}
		.copyright{
			width: 100%;
			text-align: center;
			color: #acacac;
			margin-top: 10px;
			font-size: 10px;
		}
	}
	.beian{
		display: flex;
		justify-content: center;
		align-items: center;
		img{
			width: 20px;
			height: 20px;
			margin-right: 5px;
		}
	}
</style>
<style lang="scss" scoped>
	.popup-con{
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 9999999;
		display: flex;
		align-items: center;
		justify-content: center;
		.popup-main{
			background: #131426;
			width: 300px;
			border-radius: 10px;
			// padding: 0.2rem 0.1rem;
			color: #fff;
			height: auto;
			padding: 0;
			.popup-title{
				height: 50px;
				line-height: 50px;
				text-align: center;
				position: relative;
				font-size: 16px;
				background: #333444;
				.close{
					position: absolute;
					right: 16px;
					top: 50%;
					transform: translateY(-50%) scale(0.8);
					width: 18px;
					height: 20px;
					cursor: pointer;
				}
			}
			.content{
				padding: 20px;
				position: relative;
				width: 100%;
				height: 100%;
				.bg{
					position: absolute;
					left: 0;
					height: 100%;
					top: 0;
					width: 100%;
					background: url(~@/assets/images/notice-bg.jpeg) no-repeat center center;
					background-size: cover;
					opacity: 0.2;
				}
				.scroll-con{
					height: 300px;
					.el-scrollbar__wrap{
						overflow-x: hidden;
					}
				}
				.word-content{
					font-size: 12px;
					line-height: 20px;
					position: relative;
					z-index: 1;
					white-space: pre-wrap;
				}
			}
		}
	}
</style>
