<template>
	<div class="welfare">
		<div class="welfare-nav">
			<div class="item" v-show="item.show" :class="{'active': item.components == currentComponents}" v-for="(item,index) in navList" :key="index" @click="navClick(item)"></div>
		</div>
		<div class="welfare-main">
			<!-- <component :is="currentComponents" /> -->
			<Home :hotList="hotList" v-if="currentComponents=='Home'" />
			<Recharge :list="activityList" :info="activityInfo" @receive="activityFinish" v-if="currentComponents=='Recharge'" />
			<Mession :list="activityList" :info="activityInfo" @receive="activityFinish" @sign="userSign" v-if="currentComponents=='Mession'" />
			<Red @getRed="getRed" v-if="currentComponents=='Red'" />
			<Level v-if="currentComponents=='Level'" />
			<Recommend :list="activityList" :info="activityInfo" @receive="activityFinish" v-if="currentComponents=='Recommend'" />
		</div>
		<!-- <van-overlay :show="awardShow" :lock-scroll="false" @click="quanmin = false">
			<div class="wrapper">
				<div class="award-con">
					<div class="title">恭喜获得</div>
					<div class="price">
						<div class="coins"></div>
						<span>{{ awardNum }}</span>
					</div>
					<div class="sure-btn" @click="awardShow=false">确定</div>
				</div>
			</div>
		</van-overlay> -->
		<van-overlay :show="dialog" :lock-scroll="false" @click="dialog = false">
			<div class="wrapper">
				<div class="red-get-con">
					<img class="title" src="~@/assets/img/red-get-title.png" alt="">
					<div class="price">
						<div class="coins"></div>
						{{ bean }}
					</div>
					<img class="btn" @click="dialog = false" src="~@/assets/img/red-get-btn.png" alt="">
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="luckDialog" :lock-scroll="false" @click="luckDialog = false">
			<div class="wrapper">
				<div class="red-get-con">
					<template v-if="luckData.type==1 || (luckData.type==2 && luckData.status==0)">
						<img class="title" src="~@/assets/img/red-get-title.png" alt="">
						<div class="price">
							<div class="coins"></div>
							{{ luckData.bean }}
						</div>
					</template>
					<template v-else>
						<img v-if="luckData.status==1" class="fail-title" src="~@/assets/img/red-fail-title1.png" alt="">
						<img v-if="luckData.status==2" class="fail-title" src="~@/assets/img/red-fail-title2.png" alt="">
						<img v-if="luckData.status==3" class="fail-title" src="~@/assets/img/red-fail-title3.png" alt="">

						<img v-if="luckData.status==1" class="face" src="~@/assets/img/red-get-received.png" alt="">
						<img v-else class="face" src="~@/assets/img/red-get-fail.png" alt="">
					</template>
					<img class="btn" @click="openLuckRecord" src="~@/assets/img/red-get-record.png" alt="">
					<div class="close" @click="luckDialog = false"></div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="luckRecordDialog" :lock-scroll="false" @click="luckRecordDialog = false">
			<div class="wrapper">
				<div class="red-record-con">
					<div class="red-top">
						<div class="title">领取记录</div>
						<div class="right">
							领取<span>{{ luckRecordData.received }}/{{ luckRecordData.total }}</span>
						</div>
					</div>
					<div class="list">
						<div class="item" v-for="(item, index) in luckRecord" :key="item.id">
							<img :src="item.user.avatar" alt="">
							<div class="name">{{ item.user.name }}</div>
							<div class="price">
								<div class="coins"></div>
								{{ item.bean }}
							</div>
						</div>
					</div>
					<div class="close" @click="luckRecordDialog=false"></div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import { activityList, activityInfo, activityFinish, userSign, activityHot, Red_luck_records } from '@/network/api.js';
	import Home from './Home.vue';
	import Recharge from './Recharge.vue';
	import Mession from './Mession.vue';
	import Red from './Red.vue';
	import Level from './Level.vue';
	import Recommend from './Recommend.vue';
	import {mapState, mapActions} from 'vuex';
	export default {
		name: 'Welfare',
		data(){
			return{
				navList: [
					{
						label: '充值福利', components: 'Recharge', show: false
					},
					{
						label: '福利任务', components: 'Mession', show: false
					},
					{
						label: '推荐福利', components: 'Recommend', show: false
					},
					{
						label: '热门活动', components: 'Home', show: false
					},
					{
						label: '红包福利', components: 'Red', show: true
					},
					{
						label: '等级福利', components: 'Level', show: true
					},
					
				],
				currentComponents: 'Home',
				activityList:[],
				is_new: false,
				activityInfo: {
					user:{},
					act_data:{}
				},
				awardShow: false,
				awardNum: 0.00,
				hotList:[],
				redshow: false, //红包内容显示
				bean: 0,//红包
				luckDialog: false,
				dialog: false, //dialog显示隐藏
				luckData: {
					bean: 0,
					type: 1,
					status: 0
				},
				luckRecord: [],
				luckRecordData: {
					received: 0,
					total: 10
				},
				luckRecordDialog: false,
				Red_key: ''
			}
		},
		computed:{
			...mapState(['User'])
		},
		created() {
			this.getActivityInfo()
			this.getHotList()
		},
		methods:{
			...mapActions([
				'Refresh'
			]),
			openLuckRecord(){
				Red_luck_records(this.Red_key).then((res) => {
					this.luckRecord = res.data.data.records
					this.luckRecordData = {
						received: res.data.data.received,
						total: res.data.data.total
					},
					this.luckDialog = false
					this.luckRecordDialog = true
				})
			},
			navClick(item){
				if(this.User.id){
					this.currentComponents=item.components
				}else{
					this.$notify({
						type: 'error',
						message: '请先注册登录后查看'
					})
				}
			},
			getHotList(){
				activityHot().then((res) => {
					const {code, data, message} = res.data
					const pageJson = {
						'1':'/',
						'2':'Mall',
						'3':'Roll',
						'4':'Battle',
						'5':'Replacement',
						'6':'VIP',
						'7':'Extension',
						'8':'Open',
						'9':'room'
					}
					data.activities.map(item=>{
						if(item.jump_type == 2){
							let param = ''
							item.jump_config.page_id && (param = '?id='+item.jump_config.page_id);
							item.jump_url = pageJson[item.jump_config.page_type]+param
						}else if(item.jump_type == 3){
							item.jump_url = item.jump_config.url
						}
					})
					
					this.hotList = data.activities
					this.getActivityList()
				})
			},
			getActivityList(){
				activityList().then((res) => {
					const {code, data, message} = res.data
					data.activities.map(item=>{
						if(item.type == 3){
							item.numArr = item.rule.sign_in_days.split('')
						}
					})
					this.activityList = data.activities
					this.is_new = data.is_new
					let typeArr = ['Recharge','Mession','Recommend','Home','Red','Level']
					if(this.hotList==0){
						typeArr.splice(typeArr.findIndex(item=>item=='Home'),1)
					}
					let rechargeList = data.activities.filter(item=>item.type==1)
					if(rechargeList.length==0){
						typeArr.splice(typeArr.findIndex(item=>item=='Recharge'),1)
					}
					let messionList = data.activities.filter(item=>{
						return item.type==2 || item.type==3
					})
					if(messionList.length==0){
						typeArr.splice(typeArr.findIndex(item=>item=='Mession'),1)
					}
					let recommendList = data.activities.filter(item=>item.is_recommend==1)
					if(recommendList.length==0){
						typeArr.splice(typeArr.findIndex(item=>item=='Recommend'),1)
					}
					this.navList.map(item=>{
						if(typeArr.indexOf(item.components)>-1){
							item.show = true
						}
					})
					// this.currentComponents = typeArr[0]
				})
			},
			getActivityInfo(){
				activityInfo().then((res) => {
					const {code, data, message} = res.data
					this.activityInfo = data
				})
			},
			activityFinish(id){
				activityFinish({id}).then((res) => {
					const {code, data, message} = res.data
					this.awardNum = data.bean
					this.awardShow = true
					this.getActivityList()
					this.getActivityInfo()
				})
			},
			userSign(){
				userSign().then((res) => {
					const {code, data, message} = res.data
					this.$notify({
						type: 'success',
						message: '签到成功'
					})
					this.getActivityList()
					this.getActivityInfo()
				})
			},
			getRed(data){
				if(data.v==1){
					this.bean = data.bean
					this.dialog = true
				}else{
					if(data.type == 1){
						this.bean = data.bean
						this.dialog = true
					}else{
						this.Red_key = data.Red_key
						this.luckData = data
						this.luckDialog = true
					}
				}
			}
		},
		components: {
			Home,
			Recharge,
			Mession,
			Red,
			Level,
			Recommend
		},
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.welfare{
		height: calc(100vh - 54px);
		@include Scenter;
		flex-direction: column;
		.welfare-nav{
			width: 327px;
			display: flex;
			flex-wrap: wrap;
			gap: 15px;
			margin-bottom: 18px;
			.item{
				width: 99px;
				height: 39px;
				background-size: 100% 100%;
				cursor: pointer;
				@for $i from 1 to 7{
					&:nth-child(#{$i}){
						background-image: url('~@/assets/img/welfare/welfare-nav-'+$i+'.png');
					}
				}
				&.active{
					@for $i from 1 to 7{
						&:nth-child(#{$i}){
							background-image: url('~@/assets/img/welfare/welfare-nav-'+$i+'-active.png');
						}
					}
				}
			}
		}
		.welfare-main{
			flex: 1;
			overflow: hidden;
		}
	}
	.award-con{
		width: 337px;
		height: 266px;
		@include Scenter;
		flex-direction: column;
		background: #1D1D28;
		padding-top: 94px;
		background: url(~@/assets/img/bg-welfare-get.png);
		background-size: 100% 100%;
		.title{

		}
		.price{
			@include Scenter;
			margin-top: 10px;
			.coins{
				width: 24px;
				height: 24px;
				margin-right: 3px;
			}
			span{
				font-size: 30px;
				line-height: 32px;
				background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
		.sure-btn{
			background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
			text-shadow: 0px 2px 4px #3A6EB5;
			@include HScenter;
			width: 222px;
			height: 40px;
			margin-top: 50px;
			cursor: pointer;
		}
	}
	.red-get-con{
		background: url(~@/assets/img/red-get-bg.png);
		background-size: 100% 100%;
		width: 283px;
		height: 344px;
		@include Scenter;
		flex-direction: column;
		padding-top: 68px;
		position: relative;
		.close{
			position: absolute;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%);
			width: 29px;
			height: 29px;
			background: url(~@/assets/img/red-close.png);
			background-size: 100% 100%;
			cursor: pointer;
		}
		.title{
			width: 108px;
		}
		.price{
			font-size: 30px;
			line-height: 36px;
			@include Scenter;
			color: #FF003D;
			margin-top: 24px;
			font-weight: bold;
			.coins{
				width: 30px;
				height: 30px;
				background-image: url(~@/assets/img/coins-red.png);
			}
		}
		.fail-title{
			height: 29px;
			margin-top: -10px;
		}
		.face{
			width: 90px;
			height: 90px;
			margin: 14px 0 -30px;
		}
		.btn{
			width: 162px;
			margin-top: 101px;
			cursor: pointer;
		}
	}
	.red-record-con{
		background: url(~@/assets/img/red-record-bg.png);
		background-size: 100% 100%;
		width: 299px;
		height: 346px;
		padding-top: 42px;
		position: relative;
		.close{
			position: absolute;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%);
			width: 29px;
			height: 29px;
			background: url(~@/assets/img/red-close.png);
			background-size: 100% 100%;
			cursor: pointer;
		}
		.red-top{
			@include Scenter;
			width: 220px;
			margin: 0 auto 10px;
			.title{
				background: linear-gradient(180deg, #FF7674 0%, #E41041 51.91%, #DF2E3E 100%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				font-weight: bolder;
			}
			.right{
				margin-left: auto;
				color: #333;
				font-size: 12px;
				span{
					color: #FF003D;
				}
			}
		}
		.list{
			width: 220px;
			height: 240px;
			overflow-y: auto;
			overflow-x: hidden;
			margin: 0 auto;
			//滚动条宽度
			&::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}
			/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
			&::-webkit-scrollbar-track {
				background: #FFD1D6;
				
			}
			//滚动条设置
			&::-webkit-scrollbar-thumb {
				background: #DE4C60;
			}
			.item{
				@include Scenter;
				height: 50px;
				width: 196px;
				margin: 0 auto;
				border-bottom: 1px solid #FFD1D6;
				img{
					width: 34px;
					height: 34px;
					border-radius: 50%;
				}
				.name{
					@include wordhide;
					color: #333;
					font-size: 12px;
					width: 72px;
					margin: 0 10px;
					flex-shrink: 0;
				}
				.price{
					color: #FF003D;
					.coins{
						background-image: url(~@/assets/img/coins-red.svg);
					}
				}
			}
		}
	}
</style>
