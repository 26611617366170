<template>
	<div id="Recharge">
		<div class="recharge-title">
			消息中心
		</div>
		<div class="msg-list">
			<div class="item" v-for="(item,index) in list" :key="index">
				<div class="title" :class="[item.biz_type==2?'green':'',item.biz_type==3?'red':'']">
					{{ item.title }}
					<span>{{ item.created_at }}</span>
				</div>
				<div class="content">{{ item.msg }}</div>
			</div>
		</div>
		<div class="page-con" style="margin-top: 12px;">
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" @current-change="handleCurrentChange" :pager-count='5' prev-text="上一页" next-text="下一页" small></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		userMessages
	} from '@/network/api.js'
	export default {
		name: 'Message',
		data() {
			return {
				active: 0, //tab分栏
				BeanList: [], //金豆列表
				pay_way: '1', //支付类别,1支付宝,2微信
				CardCode:'',//卡密兑换码
				list: [], //充值记录

				total:0,//总数据数量
				per_page:10,//一页数据
				page_size:1,//页码
				selectId: '',
				qr_code: '',
				qr_show: false
			}
		},
		created() {
			this.userMessages()
		},
		methods: {
			//充值记录
			userMessages() {
				this.list = []
				userMessages(this.page_size).then((res) => {
					this.total = res.data.data.total
					// this.per_page = res.data.data.per_page
					this.list = res.data.data.records
				})
			},
			//充值记录分页
			handleCurrentChange(val) {
				this.page_size = val
				this.userMessages()
			}
		},

		watch: {
			active(v) {
				// console.log(v)
				this.pay_way = v + 1
				// console.log(this.pay_way)
			}
		}

	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.recharge-title{
		position: relative;
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
		height: 40px;
		@include HScenter;
		span{
			font-size: 12px;
			color: #FFB800;
		}
		&:before{
			content: '';
			position: absolute;
			left: 1px;
			top: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
		&:after{
			content: '';
			position: absolute;
			right: 1px;
			bottom: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
	}
	.msg-list{
		.item{
			width: 343px;
			margin: 12px auto 0;
			.title{
				@include Scenter;
				background-size: 100% 100%;
				background-image: url(~@/assets/img/msg-top-bg.png);
				height: 40px;
				padding: 0 14px;
				color: #FFB800;
				&.red{
					color: #FF1A51;
				}
				&.green{
					color: #26D54D;
				}
				span{
					margin-left: auto;
					color: #999;
				}	
			}
			.content{
				padding: 14px;
				min-height: 90px;
				background: #1D1D28;
			}
		}
	}
</style>
