<template>
	<div class="Vsitem">
		<div class="Vsitem-main" :class="['bg-'+PlayerArr.length+'-'+(seat+1), mode==2?('mode2-'+(seat+1)):'']">
			<div class="core">
				<!-- <div class="exhibition" v-show="active == '1'">
					<div class="already" v-show="ItemData != null"><van-icon name="success" color="#75cd66" size="30"/></div>
					<div class="not" v-show="ItemData == null">
						<van-icon name="replay"  color="#1989fa" size="30"/>
						<van-button v-if="User.id==createUserId && mode!=2" type="info" size="small" @click="PostArenaJoinNPC">邀请NPC</van-button>
						<van-button v-else type="info" size="small" @click="PostArenaJoin">加入</van-button>
					</div>
				</div> -->
				<div class="fruit" v-if="active == '1'">
					<template v-if="ItemData">
						<div class="name">{{close_type==1 ? '' : WinDefeat}}</div>
						<div class="user-img" :class="[WinDefeatSty]">
							<div class="photo"><img :src="ItemData.avatar" alt=""></div>
						</div>
						<div class="user-name">{{ItemData.name}}</div>
					</template>
					<template v-else>
						<div class="name"></div>
						<div class="user-img">
							<div class="photo">
								<img v-if="close_type==1" src="@/assets/img/battle-empty-1.png" alt="">
								<img v-else src="@/assets/img/battle-empty.png" alt="">
							</div>
						</div>
						<div class="user-name" v-if="close_type==1">无人参与</div>
						<template v-else>
							<div class="user-name">等待加入</div>
							<div class="join-btn" v-if="User.id==createUserId && mode!=2 && ban_npc!=1" @click="PostArenaJoinNPC">邀请NPC</div>
							<div class="join-btn" v-else @click="PostArenaJoin">加入对战</div>
						</template>
					</template>
				</div>
				<Slidemod v-if="active=='2'" :game_arena_box="game_arena_box" :ItemData="ItemData" :second="second" :can="can" @setAnimation="setAnimation"></Slidemod>
				
				<div class="fruit" v-if="active == '3'">
					<div class="name">{{close_type==1 ? '' : WinDefeat}}</div>
					<div class="user-img" :class="[WinDefeatSty]">
						<div class="photo"><img v-if="ItemData != null" :src="ItemData.avatar" alt=""></div>
					</div>
					<div class="user-name">{{ItemData.name}}</div>
					<div class="pingfen" v-if="WinDefeat=='胜利' && ItemData.bean!='0.00'">平分<br /><div class="price"><div class="coins"></div>{{ItemData.bean}}</div></div>
				</div>
			</div>
			<div class="info" :class="[(active == '3' && close_type!=1) ? ('bg-'+PlayerArr.length+'-'+(seat+1)+'-'+WinDefeatSty) : '']">
				<!-- <div class="user">
					<img v-if="ItemData != null" :src="ItemData.avatar | ImgFilter" alt="">
					<span v-if="ItemData != null">{{ItemData.name}}</span>
				</div> -->
				<div class="money">
					<div class="coins"></div> <countTo :startVal='startVal' :endVal='close_type==1 ? 0 : endVal' :duration='2000' :decimals="2"></countTo>
				</div>
			</div>
		</div>
		
		<div class="Column" v-show="haveArr.length > 0 && close_type!=1">
			<div class="column_item" v-for="(item,index) in haveArr" :key="index">
				<div class="alias" v-if="item.dura_alias">{{ item.dura_alias }}</div>
				<div class="img-con">
					<img :src="item.cover" alt="">
				</div>
				<div class="name">{{item.name}}</div>
				<div class="price"><div class="coins"></div>{{item.bean}}</div>
			</div>
		</div>
		<van-overlay :show="resultShow">
			<div class="wrapper">
				<div class="battle-get-con" :class="[Awardlist.length>3?'big':'']">
					<div class="icon-close" @click="resultShow=false"></div>
					<img src="~@/assets/img/battle-get-title.png" alt="" class="get-title-big">
					<div class="result-photo">
						<img :src="User.avatar" alt="">
					</div>
					<div class="result-title">对战结束</div>
					<div class="result-price">
						本次战斗的总奖励<span>+{{ totalStatic.toFixed(2) }}</span><div class="coins"></div>
					</div>
					<div class="get-title">
						<div class="tit"></div>
						获得物品
						<div class="tit right"></div>
					</div>
					<div class="scroll-con">
						<el-scrollbar style="height:100%;">
							<div class="list" :class="['num'+Awardlist.length]">
								<div class="item" :class="{'selected': item.Select==true}" v-for="(item,index) in Awardlist" :key="index" @click="SelectItem(item,index)" ref="Select">
									<div class="img-con" :class="['lv'+item.lv]">
										<img :src="item.cover" alt="">
									</div>
									<div class="name">{{item.name}}</div>
									<div class="price">{{item.bean}}<div class="coins"></div></div>
								</div>
							</div>
						</el-scrollbar>
					</div>
					<div class="btn-con">
						<div class="btn" @click="resultShow=false">放入背包</div>
						<!-- <div class="btn on" @click="PostCash">全部兑换 {{TotalPrice.toFixed(2)}}<div class="coins"></div></div> -->
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import countTo from 'vue-count-to'//数字过度组件
	import Slidemod from './Slidemod/index.vue' //动画组件
	import { ArenaJoin, ArenaJoinNPC, Cash } from '@/network/api.js'
	import {
		mapState,
		mapActions
	} from 'vuex'
	export default{
		name:'Vsitem',
		props:{
			close_type:{//NPC禁用
				type: Number
			},
			ban_npc:{//NPC禁用
				type: Number
			},
			ItemData:{//玩家信息
				type:Object
			},
			PlayerArr:{//全部玩家信息(处理结果使用)
				type:Array
			},
			game_arena_box:{//宝箱信息
				type:Array
			},
			game_arena_id:{//对战id
				type:Number
			},
			seat:{//座位号
				type:Number
			},
			status:{//进程
				type:Number
			},
			createUserId:{//创建者id
				type:Number
			},
			win_user:{//胜利玩家列表(有平局)
				type:Array
			},
			mode:{//2为2v2模式
				type:Number
			},
		},
		data(){
			return{
				active:'1',//进程相关(模块显示隐藏)
				can:false,//动画开始开关
				second:0,//动画次数(物品列表下标及中奖列表下标)
				process: new Audio(require('@/assets/audio/process.mp3')), //开奖过程声音
				haveArr:[],//中奖物品(下栏目展示)
				startVal:0,//奖品价值金币(始)
				endVal:0,//奖品价值金币(终)
				WinDefeat:'',//胜利失败文字
				WinDefeatSty:'',//胜利失败样式
				Awardlist: [],
				TotalPrice: 0, //回收总价格
				totalStatic: 0,
				resultShow: false,
			}
		},
		computed: {
			...mapState([
				'User'
			]),
		},
		methods:{
			...mapActions([
				'Refresh'
			]),
			//加入对战
			PostArenaJoin(){
				ArenaJoin(this.game_arena_id,this.seat).then((res) => {
					this.Refresh()
				})
			},
			
			//邀请NPC
			PostArenaJoinNPC(){
				ArenaJoinNPC(this.game_arena_id,this.seat).then((res) => {
					this.Refresh()
				})
			},
			
			//开始动画
			Start(){
				this.active = '2'
				setTimeout(() => {//因v-if,立即改变子组件监听不到
					this.can = true
					this.process.play() //播放声音
				},100)
				
			},
			
			//接收子组件(单次动画结束)
			setAnimation(v){
				this.can = false
				if(v == true){
					this.second += 1 //物品列表下标及中奖列表下标及回合数+1
					this.haveArr.push(this.ItemData.game_award[this.second - 1].box_award)
					this.endVal += Number(this.ItemData.game_award[this.second - 1].box_award.bean)
					setTimeout(() => {
						this.$emit('setSecond', this.second)//动画开始发送回合数
						this.can = true//改变值触发动画
						this.process.play() //播放声音
					},2000)
				}else{//宝箱已全部开完
					this.haveArr.push(this.ItemData.game_award[this.second].box_award)//push最后奖品
					this.endVal += Number(this.ItemData.game_award[this.second].box_award.bean)//增加最后奖品价值
					setTimeout(()=>{
						this.beam()//处理结果
					},3000)
					
				}
			},
			
			// 计算回收总价
			TotalPriceCal() {
				this.TotalPrice = 0
				let A_Arr = this.Awardlist.filter((v) => {
					return v.Select == true
				})

				for (let k = 0; k < A_Arr.length; k++) {
					this.TotalPrice += Number(A_Arr[k].bean)
				}
				this.$forceUpdate();
			},
			//选中
			SelectItem(item, index) {
				if (item.Select) {
					item.Select = false
				} else {
					let key = "Select";
					let value = true
					item[key] = value
				}
				this.TotalPriceCal()
			},
			//兑换金币(回收)
			PostCash() {
				let arr = this.Awardlist.filter((item) => {
					return item.Select == true
				})
				let data = []
				for (let i = 0; i < arr.length; i++) {
					data.push({
						"id": arr[i].id,
						"uuid": arr[i].uuid
					})
				}
				Cash(JSON.stringify(data)).then((res) => {
					this.resultShow = false
					this.RefreshData(res.data.message)
				})
			},
			//刷新数据
			RefreshData(message) {
				this.$notify({
					type: 'success',
					message: message
				})
				this.Refresh()
			},
			//结果处理
			beam(){
				if(this.win_user.length == 1){//单人获胜
					this.single()
				}else{//多人获胜
					this.many()
				}
				if(this.WinDefeatSty == 'Win' && this.ItemData.user_id == this.User.id && this.$route.query.type==0){
					this.Awardlist = this.ItemData.box_records
					this.Awardlist.map(item=>item.Select = true)
					let totalStatic = 0
					this.Awardlist.map(item=>{
						totalStatic += Number(item.bean)
					})
					this.totalStatic = totalStatic;
					this.TotalPriceCal()
					this.resultShow = true;
				}
			},
			//单人胜利
			single(){
				let A_Arr = this.win_user.find((v) => {//确定是否获胜
					return v == this.ItemData.user_id
				})
				//胜利操作
				if(A_Arr !== undefined){
					this.WinDefeat = '胜利'
					this.WinDefeatSty = 'Win'
					let B_Arr = []
					for(let i = 0; i < this.PlayerArr.length; i++){//获取全部玩家信息
						B_Arr = B_Arr.concat(this.PlayerArr[i].game_award)
					}
					let C_Arr = []
					let EndNum = 0
					for(let k = 0; k < B_Arr.length; k++){//获取全部玩家获得的奖品
						C_Arr.push(B_Arr[k].box_award)//全部归胜者
						EndNum += + Number(B_Arr[k].box_award.bean)//金币全部累加归胜者
					}
					this.haveArr = C_Arr//下奖品栏赋值
					this.endVal = EndNum//价值金币赋值
				}else{//失败操作
					this.WinDefeat = '失败'
					this.WinDefeatSty = 'Defeat'
					this.haveArr = [//凉凉奖励
						{
							cover: require('@/assets/images/Vs/FailImg.png'),
							name: '凉凉奖励',
							bean: '0.01'
						}
					]
					this.endVal = 0.01//价值金币赋值
				}
				
				this.active = '3'//显示胜负模块
				this.$emit('getActive', '3')
				
			},
			//多人胜利
			many(){
				let A_Arr = this.win_user.find((v) => {//确定是否获胜
					return v == this.ItemData.user_id
				})
				//胜利操作
				if(A_Arr !== undefined){
					this.WinDefeat = '胜利'
					this.WinDefeatSty = 'Win'
					let Alone = this.PlayerArr.filter((v) => {//筛选应属数据
						return v.id == this.ItemData.id
					})
					this.haveArr = Alone[0].game_award.map(item=>item.box_award)//下奖品栏赋值(应属数据)
					
					let E_Arr = []
					for(let i = 0; i < this.PlayerArr.length; i++){//全部玩家信息
						E_Arr = E_Arr.concat(this.PlayerArr[i].game_award)
					}
					let TotalNum = 0
					for(let k = 0; k < E_Arr.length; k++){//全部奖品
						TotalNum += Number(E_Arr[k].box_award.bean)//全部奖品价值
					}
					//奖品价值为全部奖品价值/获胜人数
					this.endVal = TotalNum/this.win_user.length
					
				}else{//失败操作
					this.WinDefeat = '失败'
					this.WinDefeatSty = 'Defeat'
					this.haveArr = [
						{
							cover: require('@/assets/images/Vs/FailImg.png'),
							name: '凉凉奖励',
							bean: '0.01'
						}
					]
					this.endVal = 0.01
				}
				
				this.active = '3'//显示胜负模块
				this.$emit('getActive', '3')
			}	
		},
		
		created() {
			this.$emit('setSecond', this.second)//初始发送事件(回合数)
			
		},
		destroyed() {
			this.$off('getHaveArr');
		},
		watch:{
			status(v){
				if(v == 1){//为1播放动画
					this.Start()
				}else if(v == 2){//为2直接显示结果
					this.beam()
				}
			},
			haveArr(val){
				if(val && this.User.id!=this.ItemData.user_id){
					localStorage.setItem('haveArr', JSON.stringify(val))
				}
			}
		},
		components:{
			Slidemod,//动画组件
			countTo//数字过度
		},
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.Vsitem{
		flex: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		.Vsitem-main{
			display: flex;
			flex-direction: column;
			height: 256px;
			background-size: 100% 100%;
			flex-shrink: 0;
			@for $j from 2 to 5{
				@for $k from 1 to ($j+1){
					&.bg-#{$j}-#{$k}{
						background-image: url('~@/assets/img/battle/battle-'+$j+'-'+$k+'-bg.png');
					}
				}
			}
			@for $i from 1 to 5{
				&.mode2-#{$i}{
					background-image: url('~@/assets/img/battle/battle-2v2-'+$i+'.png');
				}
			}
		}
		.core{
			width: 100%;
			flex: 1;
			overflow: hidden;
			background: rgba($color: #000000, $alpha: .2);
			border-radius: 2px;
			.exhibition{
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				.not{
					text-align: center;
					.van-button{
						display: block;
						margin-top: 5px;
					}
				}
			}
			.fruit{
				width: 100%;
				height: 100%;
				position: relative;
				color: #fff;
				@include Scenter;
				flex-direction: column;
				padding-top: 12px;
				.name{
					height: 22px;
					line-height: 22px;
					font-size: 16px;
					margin-bottom: 33px;
				}
				.user-img{
					@include Hcenter;
					// align-items: flex-end;
					width: 69px;
					height: 52px;
					&.Win{
						background: url(~@/assets/img/win-bg.png);
						background-size: 100% 100%;
					}
					.photo{
						width: 48px;
						height: 48px;
						border-radius: 50%;
						overflow: hidden;
						margin-top: -4px;
						img{
							width: 100%;
							height: 100%;
						}
					}
				}
				.user-name{
					margin-top: 18px;
					max-width: 90%;
					@include wordhide;
				}
				.pingfen{
					margin-top: 18px;
					@include Scenter;
					.price{
						font-size: 14px;
					}
					.coins{
						background-image: url(~@/assets/img/coins-white1.svg);
						width: 14px;
						height: 14px;
					}
				}
				.join-btn{
					height: 32px;
					background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
					width: 80%;
					margin-top: 20px;
					@include HScenter;
					cursor: pointer;
				}
			}
		}
		.info{
			width: 100%;
			height: 44px;
			@include HScenter;
			background-size: 100% 100%;
			@for $j from 2 to 5{
				@for $k from 1 to ($j+1){
					&.bg-#{$j}-#{$k}-Win{
						color: #81FF76;
						background-image: url('~@/assets/img/battle/battle-'+$j+'-'+$k+'-success.png');
					}
				}
			}
			@for $j from 2 to 5{
				@for $k from 1 to ($j+1){
					&.bg-#{$j}-#{$k}-Defeat{
						color: #FF2020;
						background-image: url('~@/assets/img/battle/battle-'+$j+'-'+$k+'-fail.png');
					}
				}
			}
			.user{
				width: 100%;
				color: #fff;
				font-size: 10px;
				text-align: center;
				margin-top: 2px;
				height: 20px;
				line-height: 20px;
				overflow: hidden;
				img{
					width: 14px;
					height: 14px;
					border-radius: 50%;
					overflow: hidden;
					border: 1px solid #ffba2e;
					margin-right: 2px;
					vertical-align: middle;
				}
				
			}
			.money{
				display: flex;
				align-items: center;
				font-size: 12px;
				font-weight: bold;
				.coins{
					width: 14px;
					height: 14px;
					background-image: url(~@/assets/img/coins-white1.svg);
					margin-right: 4px;
				}
			}
			
		}
		.Column{
			width: 100%;
			background: #262636;
			height: 100%;
			.column_item{
				@include Scenter;
				flex-direction: column;
				padding: 10px 0;
				position: relative;
				.alias{
					position: absolute;
					right: 4px;
					top: 4px;
					font-size: 12px;
					color: #bbb;
				}
				.img-con{
					height: 50px;
					width: 90%;
				}
				.name{
					color: #bbb;
					margin: 6px 0;
					@include wordhide;
					max-width: 90%;
				}
				.price{
					@include Scenter;
					.coins{
						width: 14px;
						height: 14px;
					}
				}
			}
		}
	}
	.battle-get-con{
		@include Scenter;
		flex-direction: column;
		width: 350px;
		height: 501px;
		// min-height: 400px;	
		padding-top: 30px;
		padding-bottom: 20px;
		background: url(~@/assets/img/battle-get-bg.png) #262636 no-repeat;
		background-size: 100% 100%;
		background-position-y: 8px;
		position: relative;
		.get-title-big{
			height: 24px;
			margin: 0 auto 36px;
		}
		.icon-close{
			position: absolute;
			right: 5px;
			top: 5px;
			width: 24px;
			height: 24px;
			background-image: url(~@/assets/img/rule-close.png);
			background-size: 100% 100%;
			z-index: 2;
			cursor: pointer;
		}
		.result-photo{
			position: relative;
			&::before{
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				width: 69px;
				height: 52px;
				transform: translate(-50%, -40%);
				background-image: url(~@/assets/img/win-bg.png);
				background-size: 100% 100%;
			}
			img{
				width: 48px;
				height: 48px;
				border-radius: 50%;
			}
		}
		.result-title{
			margin: 12px 0 5px;
			font-size: 16px;
		}
		.result-price{
			@include Scenter;
			span{
				background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				font-size: 20px;
				line-height: 20px;
				margin-left: 6px;
				font-weight: bold;
			}
			.coins{
				width: 16px;
				height: 16px;
				background-image: url(~@/assets/img/coins-white1.svg);
			}
		}
		.get-title{
			@include Scenter;
			margin: 30px 0 10px;
			.tit{
				width: 14px;
				height: 10px;
				background: url(~@/assets/img/battle-get-title-tit.png);
				background-size: 100% 100%;
				margin: 0 10px;
				&.right{
					transform: rotateY(180deg);
				}
			}
		}
		.scroll-con{
			height: 160px;
			.el-scrollbar__wrap{
				overflow-x: hidden;
			}
		}
		.list{
			display: flex;
			flex-wrap: wrap;
			gap: 6px;
			width: 294px;
			.item{
				width: 94px;
				height: 129px;
				@include HScenter;
				flex-direction: column;
				border: 1px solid #353545;
				position: relative;
				background: #1D1D2B;
				cursor: pointer;
				&.selected{
					border-image: linear-gradient(180deg, #448EFD 0%, #44FDE7 100%);
					border-image-slice: 1; /* 切片，使整个边框都被渐变填充 */
					&::before{
						content: '';
						position: absolute;
						z-index: 2;
						width: 11px;
						height: 11px;
						right: 0;
						top: 0;
						background: url(~@/assets/img/icon-dagou.png);
						background-size: 100% 100%;
					}
				}
				.img-con{
					width: 72px;
					height: 53px;
					background-size: auto 100%;
					background-position: center center;
					background-repeat: no-repeat;
					&.lv1{
						background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
					}
					&.lv2{
						background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
					}
					&.lv3{
						background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
					}
					&.lv4{
						background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
					}
					&.lv5{
						background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
					}
				}
				.name{
					@include wordhide;
					max-width: 90%;
					font-size: 12px;
					margin: 7px 0 5px;
				}
				.price{
					@include Scenter;
					font-size: 14px;
					.coins{
						background-image: url(~@/assets/img/coins-white1.svg);
						margin-left: 2px;
						width: 16px;
						height: 16px;
					}
				}
			}
		}
		.sell-btn{
			@include HScenter;
			background: #448EFD;
			width: 294px;
			height: 40px;
			margin: 15px 0;
			cursor: pointer;
			span{
				margin-left: 8px;
				margin-right: 2px;
			}
			.coins{
				background-image: url(~@/assets/img/coins-white1.svg);
				width: 16px;
				height: 16px;
			}
		}
		.btn-con{
			margin-top: 24px;
			@include HScenter;
			gap: 14px;
			.btn{
				width: 140px;
				height: 46px;
				@include HScenter;
				cursor: pointer;
				background-size: 100% 100%;
				background-image: url(~@/assets/img/box-get-btn.png);
				.coins{
					background-image: url(~@/assets/img/coins-white1.svg);
					width: 16px;
					height: 16px;
					margin: 0;
					margin-left: 4px;
				}
				&.on{
					background-image: url(~@/assets/img/box-get-btn-active.png);
					text-shadow: 0px 2px 4px #3A6EB5;
				}
			}
		}
	}
</style>
