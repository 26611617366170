<template>
	<div class="container double-con">
		<div class="snow-animate">
      <div class="snow-item" v-for="(item,index) in snowList" :key="index" :class="[
        'snow-item-left-'+item.left,
        'snow-item-animate-'+item.animate,
        'snow-item-time-'+item.time,
        'snow-item-margin-'+item.margin,
        'snow-item-bg-'+item.bg,
        'snow-item-size-'+item.size,
        'snow-item-opacity-'+item.opacity
      ]"></div>
    </div>
		<div class="nd-con">
			<div class="nd-top">
				剩余抽奖次数：{{ activityInfo.user.egg_bean }}
				<div class="nd-record" @click="ndRecordShow=true"></div>
			</div>
			<div class="open-btn-con">
				<div class="btn" @click="eggOpen(1)"></div>
				<div class="btn five" @click="eggOpen(5)"></div>
			</div>
			<div class="rule-con">
				{{ activityInfo.play_desc }}
			</div>
		</div>
		<!-- 惊喜任务 -->
		<img class="mession-title" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title.png" alt="">
		<div class="mession-con">
			<div class="mession-title">
				<div class="item" :class="{'active': messionType==1}" @click="messionType=1">
					<img v-if="messionType==1" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-1-active.png" alt="">
					<img v-else src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-1.png" alt="">
				</div>
				<div class="item" :class="{'active': messionType==2}" @click="messionType=2">
					<img v-if="messionType==2" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-2-active.png" alt="">
					<img v-else src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-2.png" alt="">
				</div>
			</div>
			<div class="scroll-con">
				<el-scrollbar style="height:100%;">
					<div class="mession-list">
						<div class="item" v-for="(item,index) in taskList" :key="index" v-show="messionType==1 ? item.isDay : !item.isDay">
							<img v-if="item.icon=='sign'" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/icon-mession-sign.png" alt="">
							<img v-else-if="item.icon=='recharge'" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/icon-mession-recharge.png" alt="">
							<img v-else-if="item.icon=='user'" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/icon-mession-user.png" alt="">
							<div class="info">
								<div class="title">{{ item.title }}</div>
								<div class="progress" v-if="item.rule.days">(进度：{{ `${item.progress.days}/${item.rule.days}` }})</div>
								<div class="progress" v-else-if="item.rule.bean">(进度：{{ `${item.progress.bean}/${item.rule.bean}` }})</div>
								<div class="progress" v-else-if="item.rule.num">(进度：{{ `${item.progress.num}/${item.rule.num}` }})</div>
							</div>
							<div class="award">
								<div class="num">抽签卡+{{ item.rule.egg_bean }}</div>
								<div class="btn" :class="{'received': item.progress.finish}" @click="!item.progress.finish ? finish(item.id) : ''"></div>
							</div>
						</div>
					</div>
				</el-scrollbar>
			</div>
		</div>
		<!-- 新年狂欢礼 -->
		<img class="mession-title" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-crazy.png" alt="">
		<div class="rule-con">
			{{ activityInfo.daily_desc }}
		</div>
		<div class="crazy-list">
			<div class="item" v-for="(item,index) in daily_config" :key="index">
				<div class="title"><span>{{ item.title }}</span></div>
				<div class="award-list">
					<div class="award-item">
						<div class="award-item-img">
							<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/crazy-item-award-img-1.png" alt="" class="award-img">
							<span>新年秘券{{item.act_bean_min}}~{{item.act_bean_max}}</span>
						</div>
						<div class="btn" :class="{'received': item.progress_2.finish}" @click="dailyFinish(item.id, 2)"></div>
					</div>
					<div class="award-item">
						<div class="award-item-img">
							<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/crazy-item-award-img-2.png" alt="" class="award-img">
							<span>随机福袋</span>
						</div>
						<div class="btn" :class="{'received': item.progress_1.finish}" @click="dailyFinish(item.id, 1)"></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 新年迎新礼 -->
		<img class="mession-title" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-new.png" alt="">
		<div class="rule-con">
			{{ activityInfo.invite_desc }}
		</div>
		<div class="invite-con">
			<div class="code">我的邀请码：{{ User.invite_code }}</div>
			<van-button class="copy" size="small" type="info" v-clipboard:copy="User.invite_code" v-clipboard:success="copy" v-clipboard:error="onError">复制</van-button>
			<van-button class="copy link" size="small" type="info" v-clipboard:copy="'https://www.wanskins.com/?ref='+User.invite_code" v-clipboard:success="copy" v-clipboard:error="onError">复制邀请链接</van-button>
		</div>
		<div class="new-list">
			<div class="item" v-for="(item,index) in invite_config" :key="index">
				<div class="title">{{ item.title }}</div>
				<div class="desc">{{ item.type == 1?'活动期间注册的新用户':`(${item.progress.num}/${item.num})` }}</div>
				<div class="award-list">
					<div class="award-item">
						<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-item-award.png" alt="">
						<span>新年秘券{{ item.act_bean_min }}~{{ item.act_bean_max }}</span>
					</div>
					<div class="award-item">
						<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/new-award-img.png" alt="">
						<span>抽签卡*{{ item.egg_bean }}</span>
					</div>
				</div>
				<div class="btn" :class="{'received': item.progress.finish}" @click="inviteFinish(item.id)"></div>
			</div>
		</div>
		<!-- 新年秘宝库 -->
		<img class="mession-title" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-recharge.png" alt="">
		<div class="rule-con">
			{{ activityInfo.recharge_desc }}
			<div class="recharge-record" @click="rechargeRecordShow=true"></div>
		</div>
		<div class="mession-recharge-con">
			<div class="mession-recharge-type">
				<div class="item" :class="{'active': rechargeType == 1}" @click="rechargeType = 1">
					<img v-if="rechargeType==1" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-recharge-1-active.png" alt="">
					<img v-else src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-recharge-1.png" alt="">
				</div>
				<div class="item" :class="{'active': rechargeType == 2}" @click="rechargeType = 2">
					<img v-if="rechargeType==2" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-recharge-2-active.png" alt="">
					<img v-else src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-title-recharge-2.png" alt="">
				</div>
			</div>
			<div class="mession-recharge-total">
				累计充值C币：<span>{{ activityInfo.user.recharge_total }}</span> 今日充值C币：<span>{{ activityInfo.user.recharge_today }}</span>
			</div>
			<div class="scroll-con">
				<el-scrollbar style="height:100%;">
					<div class="mession-recharge-list" v-show="rechargeType == 1">
						<div class="item" :class="{'item-time': item.type==12}" v-for="(item,index) in rechargeTimeList" :key="index">
							<div class="title"><span>{{ item.title }}</span></div>
							<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-item-award.png" alt="" class="award-img">
							<div class="award">随机{{item.rule.act_bean_min}}-{{item.rule.act_bean_max}}个新年秘劵</div>
							<!-- <div class="progress">
								<span v-if="item.type==13">每日充值{{ item.rule.bean }}C币<br />（{{ item.progress.bean }}/{{ item.rule.bean }}）</span>
							</div> -->
							<div class="btn" :class="[item.type==12?'btn-time':item.progress.received?'received':'receive']" @click="item.type==12?($router.push('/Recharge')):item.progress.received?'':finish(item.id)"></div>
						</div>
					</div>
					<div class="mession-recharge-list" v-show="rechargeType == 2">
						<div class="item" :class="{'item-time': item.type==12}" v-for="(item,index) in rechargeList" :key="index">
							<div class="title"><span>{{ item.title }}</span></div>
							<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-item-award.png" alt="" class="award-img">
							<div class="award">随机{{item.rule.act_bean_min}}-{{item.rule.act_bean_max}}个新年秘劵</div>
							<!-- <div class="progress">
								<span v-if="item.type==13">每日充值{{ item.rule.bean }}C币<br />（{{ item.progress.bean }}/{{ item.rule.bean }}）</span>
							</div> -->
							<div class="btn" :class="[item.type==12?'btn-time':item.progress.received?'received':'receive']" @click="item.type==12?($router.push('/Recharge')):item.progress.received?'':finish(item.id)"></div>
						</div>
					</div>
				</el-scrollbar>
			</div>
		</div>
		<!-- 新年秘宝 -->
		<img class="mession-title" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-title.png" alt="">
		<div class="box-con">
			<div class="box-nav">
				<div class="item" :class="{'active': boxIndex == index}" @click="boxIndex = index; GetBoxDetail();" v-for="(item,index) in activityInfo.box_draw_config" :key="index">
					<div class="title">{{ item.name }}</div>
					<div class="desc">累计充值{{item.recharge_bean}}C币</div>
				</div>
			</div>
			<div class="box-rule">
				{{ activityInfo.box_draw_desc }}
			</div>
			<div class="box-rate">
				<div class="item" v-show="item>0" v-for="(item,index) in rateList" :key="index">
					{{item.toFixed(3)}}%
				</div>
			</div>
			<div class="box-list">
				<div class="item" :class="{'active': current==index}" v-for="(item,index) in detailList" :key="index">
					<div class="img-con" :class="['level'+item.lv]">
						<img :src="item.cover" alt="">
					</div>
				</div>
				<div class="box-list-total">
					<div>当前累计充值： <span>{{ activityInfo.user.recharge_total }}</span></div>
					<div>持有新年秘劵： <span>{{ activityInfo.user.act_bean }}</span></div>
				</div>
				<div class="box-open-con">
					<div class="box-open-btn" @click="boxOpen(1)">{{ activityInfo.box_draw_config[boxIndex].use_act_bean }}个新年秘劵</div>
					<div class="box-open-btn" @click="boxOpen(5)">{{ activityInfo.box_draw_config[boxIndex].use_act_bean * 5 }}个新年秘劵</div>
				</div>
			</div>
			<div class="box-bottom">
				<div class="link" @click="drawRecordShow=true">获奖记录</div>
				<div class="skip-con">
					跳过动画
					<van-switch v-model="Animation_checked" size="16px" />
				</div>
			</div>
		</div>
		<!-- 新年馈赠礼 -->
		<img class="mession-title" style="margin-top: 0;margin-bottom: 16px;" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-link-title.png" alt="">
		<div class="box-link-rule">
			{{ activityInfo.box_give_desc }}
		</div>
		<div class="box-link-list">
			<div class="item" v-for="(item,index) in activityInfo.box_give_config" :key="index" @click="ToOpen(item.box_id)">
				<div class="name">{{ item.name }}</div>
				<div class="img-con">
					<img :src="item.cover" alt="">
				</div>
				<div class="recharge">累计充值{{ item.recharge_bean }}</div>
				<div class="progress">（{{ activityInfo.user.recharge_total }}/{{ item.recharge_bean }}）</div>
				<div class="see"></div>
			</div>
		</div>
		<!-- 抽签记录 -->
		<van-overlay :show="ndRecordShow">
			<div class="wrapper">
				<div class="double-popup-con">
					<div class="title">
						<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/popup-title-cyjl.png" alt="">
					</div>
					<div class="close" @click="ndRecordShow=false"></div>
					<div class="table">
						<table>
							<tr>
								<th>抽奖时间</th>
								<th>奖励内容</th>
								<th>奖励状态</th>
							</tr>
							<tr v-for="(item,index) in eggRecord.slice(0,5)" :key="index">
								<td>{{ item.created_at }}</td>
								<td>{{ item.award }}</td>
								<td>{{ item.status_text }}</td>
							</tr>
						</table>
					</div>
					<div class="page">
						<el-pagination background layout="total, prev, pager, next" :pager-count="3" :total="eggTotal" :page-size="5" @current-change="eggPageChange" prev-text="上一页" next-text="下一页" small></el-pagination>
					</div>
				</div>
			</div>
		</van-overlay>
		<!-- 充值记录 -->
		<van-overlay :show="rechargeRecordShow">
			<div class="wrapper">
				<div class="double-popup-con">
					<div class="title">
						<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/popup-title-czjl.png" alt="">
					</div>
					<div class="close" @click="rechargeRecordShow=false"></div>
					<div class="table">
						<table>
							<tr>
								<th>充值时间</th>
								<th>充值金额</th>
								<th>赠送新年秘劵</th>
							</tr>
							<tr v-for="(item,index) in rechargeRecord.slice(0,5)" :key="index">
								<td>{{ item.created_at }}</td>
								<td>{{ item.recharge_bean }}</td>
								<td>{{ item.act_bean }}</td>
							</tr>
						</table>
					</div>
					<div class="page">
						<el-pagination background layout="total, prev, pager, next" :pager-count="3" :total="rechargeTotal" :page-size="5" @current-change="rechargePageChange" prev-text="上一页" next-text="下一页" small></el-pagination>
					</div>
				</div>
			</div>
		</van-overlay>
		<!-- 开箱记录 -->
		<van-overlay :show="drawRecordShow">
			<div class="wrapper">
				<div class="double-popup-con">
					<div class="title">
						<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/popup-title-hjjl.png" alt="">
					</div>
					<div class="close" @click="drawRecordShow=false"></div>
					<div class="table">
						<table>
							<tr>
								<th>抽奖时间</th>
								<th>宝箱名称</th>
								<th>奖励内容</th>
								<th>价值</th>
							</tr>
							<tr v-for="(item,index) in drawRecord.slice(0,5)" :key="index">
								<td>{{ item.created_at }}</td>
								<td>{{ item.box_name }}</td>
								<td>{{ item.award }}</td>
								<td>{{ item.bean }}</td>
							</tr>
						</table>
					</div>
					<div class="page">
						<el-pagination background layout="total, prev, pager, next" :pager-count="3" :total="drawTotal" :page-size="5" @current-change="drawPageChange" prev-text="上一页" next-text="下一页" small></el-pagination>
					</div>
				</div>
			</div>
		</van-overlay>
		<!-- 抽签结果 -->
		<van-overlay :show="ndGetShow">
			<div class="wrapper">
				<div class="double-popup-con" :class="[ndGetNum>0 ? 'award' : 'ndAward']">
					<div class="ng-img">
						<img v-if="ndGetNum>0" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/nd-get-img.png" alt="">
						<!-- <img v-else src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/nd-get-none.png" alt=""> -->
					</div>
					<div class="nd-name" v-if="ndGetNum>0">新年秘劵*{{ ndGetNum }}</div>
					<template v-else>
						<img v-if="ngNoneIndex==0" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/none-img-1.png" alt="" class="none-img">
						<img v-else-if="ngNoneIndex==1" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/none-img-2.png" alt="" class="none-img">
						<img v-else-if="ngNoneIndex==2" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/none-img-3.png" alt="" class="none-img">
						<img v-else-if="ngNoneIndex==3" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/none-img-4.png" alt="" class="none-img">
						<img v-else-if="ngNoneIndex==4" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/none-img-5.png" alt="" class="none-img">
						<img v-else src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/none-img-6.png" alt="" class="none-img">
					</template>
					<!-- <div class="nd-name" v-else>2023 祝您好运长相伴</div> -->
					<div class="btn" @click="ndGetShow=false">
						<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/know-btn.png" alt="">
					</div>
				</div>
			</div>
		</van-overlay>
		<!-- 开箱结果 -->
		<van-overlay :show="drawShow">
			<div class="wrapper">
				<div class="double-popup-con award">
					<div class="draw-list" :class="{'one': times==1}">
						<div class="item" v-for="(item,index) in getArr" :key="index" @click="item.checked=!item.checked">
							<div class="item-main" :class="{'active': item.checked}">
								<!-- <div class="alias">{{ item.dura_alias }}</div> -->
								<div class="img-con" :class="['level'+item.lv]">
									<img :src="item.cover" alt="">
								</div>
								<div class="price">
									<div class="coins"></div>{{ item.bean }}
								</div>
							</div>
							<div class="name">{{ item.name }}</div>
						</div>
					</div>
					<div class="btn-con">
						<div class="btn" @click="drawShow=false">
							<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/package-btn.png" alt="">
						</div>
						<!-- <div class="btn" @click="PostCash">
							<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/rechange-btn.png" alt="">
						</div> -->
					</div>
					
				</div>
			</div>
		</van-overlay>
		<!-- 任务完成领取 -->
		<van-overlay :show="finishShow">
			<div class="wrapper">
				<div class="double-popup-con award">
					<div class="close" @click="finishShow=false"></div>
					<div class="ng-img">
						<img v-if="finishType == 'egg'" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/nd-get-coins.png" alt="">
						<img v-else src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/nd-get-img.png" alt="">
					</div>
					<div class="nd-name">{{ finishType == 'egg' ? '抽签卡' : '新年秘劵' }} * {{ finishNum }}</div>
					<div class="btn" @click="finishShow=false">
						<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/know-btn.png" alt="">
					</div>
				</div>
			</div>
		</van-overlay>
		<!-- 红包金额 -->
		<van-overlay :show="beanShow">
			<div class="wrapper">
				<div class="double-popup-con bean">
					<div class="bean-con">
						{{ finishNum }}<br />
						<span>金额已发放至您的账户</span>
					</div>
					<div class="btn" @click="beanShow=false">
						<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/know-btn.png" alt="">
					</div>
				</div>
			</div>
		</van-overlay>
		<!-- 任务完成领取 -->
		<van-overlay :show="inviteShow">
			<div class="wrapper">
				<div class="double-popup-con award">
					<div class="close" @click="inviteShow=false"></div>
					<div class="new-award-list">
						<div class="item">
							<div class="ng-img">
								<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/nd-get-coins.png" alt="">
							</div>
							<div class="nd-name">抽签卡 * {{ invite_data.egg_bean }}</div>
						</div>
						<div class="item">
							<div class="ng-img">
								<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/nd-get-img.png" alt="">
							</div>
							<div class="nd-name">新年秘劵 * {{ invite_data.act_bean }}</div>
						</div>
					</div>
					<div class="btn" @click="inviteShow=false">
						<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/know-btn.png" alt="">
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	const scrollAudio = require("@/assets/audio/doubleScroll.mp3");
	const openAudio = require("@/assets/audio/doubleOpen.mp3");
	const closeAudio = require("@/assets/audio/drawClose.mp3");
	import { activity20240210Info, activity20240210Finish, activity20240210Draw, activity20240210EggRun, activity20240210EggRecords, activity20240210DrawRecords, activity20240210RechargeRecords, BoxDetail, Cash, PersonalInfo, activity20240210FinishDaily, activity20240210FinishInvite } from '@/network/api.js'
	import { mapState, mapMutations, mapActions } from 'vuex'
	export default{
		name:'Double',
		data(){
			return{
				boxIndex: 0,
				boxInfo: {

				},
				boxList: [
					{
						title: '普通金库',
						recharge: 5
					},
					{
						title: '高级金库',
						recharge: 10
					}
				],
				Animation_checked: true,
				activityInfo: {
					box_give_config:[],
					box_draw_config:[{}],
					user: {}
				},
				taskList: [],
				rechargeList: [],
				rechargeTimeList: [],
				detailList: [],
				rateList:[0,0,0,0,0],
				getArr:[],
				times:[],
				getIndex:0,
				isRolling:true,
				current: 0,
				speed: 150, // 时间->速度
				diff: 10, // 基数 基数越小转速越慢
				time: 0, // 当前时间戳
				giftId:0,
				disabled:false,
				eggRecord: [],
				eggTotal:0,//背包总数据
				eggPage:1,//背包列表页码
				ndRecordShow: false,
				rechargeRecord: [],
				rechargeTotal:0,//背包总数据
				rechargePage:1,//背包列表页码
				rechargeRecordShow: false,
				drawRecord: [],
				drawTotal:0,//背包总数据
				drawPage:1,//背包列表页码
				drawRecordShow: false,
				scrollAudio: undefined,
				openAudio: undefined,
				closeAudio: undefined,
				ndGetShow: false,
				ndGetNum: 0,
				ngNoneIndex: 0,
				drawShow:false,
				finishShow: false,
				finishNum: 0,
				finishType: 'egg',
				rechargeType: 1,
				messionType: 1,
				daily_config: [],
				invite_config: [],
				beanShow: false,
				invite_data: {
					egg_bean: 0,
					act_bean: 0
				},
				inviteShow: false,
				snowList:[]
			}
		},
		computed:{
			...mapState(['User'])
		},
		created() {
			this.GetInfo()
			this.getEggRecord()
			this.getRechargeRecord()
			this.getDrawRecord()
			this.scrollAudio = this.audioInit(scrollAudio);
			this.openAudio = this.audioInit(openAudio);
			this.closeAudio = this.audioInit(closeAudio);
			var snowList = []
			for(var index =0;index<100;index++){
				var obj = {
					left:index%Math.ceil(30*Math.random()),
					margin:index%Math.ceil(20*Math.random()),
					animate:index%Math.ceil(20*Math.random()),
					bg:index%Math.ceil(3*Math.random()),
					size:index%Math.ceil(10*Math.random()),
					time:index%Math.ceil(6*Math.random()),
					opacity:index%Math.ceil(4*Math.random())
				}
				snowList.push(obj)
			}
			this.snowList = snowList;
		},
		methods:{
			...mapMutations([
				'PostUser'
			]),
			...mapActions([
				'Refresh'
			]),
			inviteFinish(id){
				activity20240210FinishInvite({id}).then((res)=>{
					const {data} = res.data;
					this.invite_data = {
						egg_bean: data.egg_bean,
						act_bean: data.act_bean
					}
					this.inviteShow = true
					this.GetInfo()
				})
			},
			dailyFinish(id, type){
				activity20240210FinishDaily({id, type}).then((res)=>{
					const {data} = res.data;
					if(data.act_bean>0){
						this.finishType = 'act'
						this.finishNum = data.act_bean
						this.finishShow = true
					}else {
						this.finishType = 'egg'
						this.finishNum = data.bean
						this.beanShow = true
						this.Refresh()
					}
					this.GetInfo()
				})
			},
			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.PostUser(res.data.message)
				})
			},
			//兑换金币(回收)
			PostCash() {
				let arr = this.getArr.filter((item) => {
					return item.checked
				})
				let data = []
				for (let i = 0; i < arr.length; i++) {
					data.push({
						"id": arr[i].id
					})
				}
				Cash(JSON.stringify(data)).then((res) => {
					this.GetPersonalInfo()
					this.drawShow = false
				})
			},
			audioInit (src) {
				const audio = new Audio();
				audio.src = src;
				audio.preload = 'auto';
				audio.load();
				return audio;
			},
			getEggRecord(){
				activity20240210EggRecords(this.eggPage).then(res=>{
					this.eggRecord = res.data.data.records
					this.eggTotal = res.data.data.total
				})
			},
			eggPageChange(val) {
				this.eggPage = val
				this.getEggRecord()
			},
			getRechargeRecord(){
				activity20240210RechargeRecords(this.rechargePage).then(res=>{
					this.rechargeRecord = res.data.data.records
					this.rechargeTotal = res.data.data.total
				})
			},
			rechargePageChange(val) {
				this.rechargePage = val
				this.getRechargeRecord()
			},
			getDrawRecord(){
				activity20240210DrawRecords(this.drawPage).then(res=>{
					this.drawRecord = res.data.data.records
					this.drawTotal = res.data.data.total
				})
			},
			drawPageChange(val) {
				this.drawPage = val
				this.getDrawRecord()
			},
			//开箱
			eggOpen(num){
				let params = {
					times: num,
				}
				activity20240210EggRun(params).then((res) => {
					const {data} = res.data
					let ndGetNum = 0
					data.awards.map(item=>{
						item.type=='2' && (ndGetNum+=item.act_bean)
					})
					this.ndGetNum = ndGetNum
					this.ngNoneIndex = Math.floor(Math.random() * 6);
					this.ndGetShow = true
					this.GetInfo()
				})
			},
			//宝箱详情
			GetBoxDetail(){
				if(this.activityInfo.box_draw_config.length==0){
					return false
				}
				if(this.boxInfo[this.activityInfo.box_draw_config[this.boxIndex].box_id]){
					const data = this.boxInfo[this.activityInfo.box_draw_config[this.boxIndex].box_id]
					let rateList = [0,0,0,0,0]
					data.box_awards.map(item=>{
						rateList[item.lv-1] += parseFloat(item.weight)
					})
					this.rateList = rateList
					this.detailList = data.box_awards
					return false
				}
				BoxDetail(this.activityInfo.box_draw_config[this.boxIndex].box_id).then((res) => {
					const {data} = res.data;
					this.boxInfo[this.activityInfo.box_draw_config[this.boxIndex].box_id] = data
					let rateList = [0,0,0,0,0]
					data.box_awards.map(item=>{
						rateList[item.lv-1] += parseFloat(item.weight)
					})
					this.rateList = rateList
					this.detailList = data.box_awards
				})
			},
			//福利箱列表
			GetInfo(){
				activity20240210Info().then((res) => {
					const {data} = res.data;
					this.activityInfo = data
					this.GetBoxDetail()
					var dayArr = [1,2,4,6,7,8,13]
					let taskList = data.tasks.filter(item=>(item.type != 12 && item.type != 13))
					this.taskList = taskList.map(item=>({
						...item,
						icon: item.rule.days?'sign':item.rule.bean?'recharge':'user',
						isDay: dayArr.indexOf(item.type)>-1 ? true : false
					}))
					this.rechargeList = data.tasks.filter(item=>(item.type==13))
					this.rechargeTimeList = data.tasks.filter(item=>(item.type==12))
					this.daily_config = data.daily_config
					this.invite_config = data.invite_config
				})
			},
			finish(id){
				activity20240210Finish({id}).then((res)=>{
					const {data} = res.data;
					if(data.egg_bean>0){
						this.finishType = 'egg'
						this.finishNum = data.egg_bean
					}else if(data.act_bean>0){
						this.finishType = 'act'
						this.finishNum = data.act_bean
					}
					this.finishShow = true
					// this.$notify({
					// 	type: 'success',
					// 	message: '领取成功'
					// })
					this.GetInfo()
				})
			},
			//路由跳转开箱
			ToOpen(id){
				this.$router.push({
					path:'/Open',
					query:{
						id:id,
						type: 'double'
					}
				})
			},
			//复制文本
			copy(e) {
				// console.log(e)
				this.$notify({
					type: 'success',
					message: '复制成功'
				})
			},
			onError(e) {
				// console.log(e)
				this.$notify({
					type: 'danger',
					message: '复制失败'
				})
			},
			//开箱
			boxOpen(num){
				if (this.disabled) {
					return;
				}
				this.disabled = true;
				let params = {
					type: 2,
					times: num,
					box_id: this.activityInfo.box_draw_config[this.boxIndex].box_id
				}
				activity20240210Draw(params).then((res) => {
					const {data} = res.data
					data.awards.map(item=>{
						item.checked = true
					})
					this.getArr = data.awards
					this.times = data.awards.length
					if(this.Animation_checked){
						this.drawShow = true
						this.disabled = false
					}else{
						this.startAnimate(num)
					}
					this.GetInfo()
				}).catch(() => {
					this.disabled = false;
				});
			},
			startAnimate(times){
				this.times = times;
				this.current = 0;
				this.getIndex = 0;
				this.giftId = this.getArr[this.getIndex].box_award_id;
				this.luckDraw()
			},
			// 开始抽奖
			luckDraw() {
				if(this.isRolling){ // 开始抽奖
					this.current = 0;
					this.isRolling = false;
					this.time = Date.now();
					this.speed = 10;
					this.move(); // 开始抽奖动效
				}
			},
			move() {
				this.timer = setTimeout(() => {
					this.scrollAudio.play()
					this.current++;
					if (this.current > 13) {
						this.current = 0;
					}
					// 若抽中的奖品id存在，则开始减速转动
					if (this.giftId && (Date.now() - this.time) / 1000 > 1.5) {
						this.speed += this.diff; // 转动减速
						if ((Date.now() - this.time) / 1000 > (this.times == 1 ? 2.2 : 2.2) && this.giftId == this.detailList[this.current].id) {
							clearTimeout(this.timer);
							this.isRolling = true; // 抽奖结束
							this.getIndex++;
							if(this.getIndex < this.getArr.length){
								this.giftId = this.getArr[this.getIndex].box_award_id;
								this.timerDelay = setTimeout(()=>{
									this.luckDraw()
								},1000)
							}else{
								this.timerDelay = setTimeout(()=>{
									this.closeAudio.play()
									// if(this.times == 1){
									// 	this.openDrawOne = true;
									// }else{
									// 	this.openDrawFive = true;
									// }
									this.disabled = false
									this.drawShow = true
									this.getIndex = 0
									this.disabled = false
									this.current = -1
								},1000)
							}
							return false
						}
					}
					else {
						// 若抽中的奖品不存在，则加速转动
						if(this.speed>10){
							this.speed -= this.diff;
						}
						// this.speed -= this.diff;
					}
					this.move();
				}, this.speed);
			},
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	@font-face {
		font-family: 'YOUSHEBIAO';
		src: url(~@/assets/fonts/YOUSHEBIAO.TTF); 
	}
	.double-con{
		background: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/body-bg.png);
		background-size: 100% auto;
	}
	.rule-con{
		width: 355px;
		height: 122px;
		background: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/rule-bg.png);
		background-size: 100% 100%;
		margin: 0 auto;
		white-space: pre-wrap;
		font-size: 12px;
		line-height: 20px;
		position: relative;
		padding: 12px 18px;
		.recharge-record{
			position: absolute;
			top: 50%;
			right: -10px;
			width: 29px;
			height: 79px;
			background: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-record.png);
			background-size: 100% 100%;
			transform: translateY(-50%);
			cursor: pointer;
		}
	}
	.nd-con{
		.nd-top{
			height: 554px;
			background: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/nd-bg.png);
			background-size: 100% 100%;
			text-align: center;
			padding-top: 312px;
			position: relative;
			color: #fff; /* 文字颜色 */
			-webkit-text-stroke: 1px #FE1F00; /* 描边宽度和颜色，适用于WebKit浏览器（例如Chrome和Safari）*/
			text-stroke: 1px #FE1F00; /* 描边宽度和颜色，适用于其他浏览器 */
			font-size: 20px;
			font-family: 'YOUSHEBIAO';
			// text-shadow: 0 0 0px #FE1F00; /* 外边框阴影效果 */
			.nd-record{
				position: absolute;
				right: 0;
				top: 84px;
				width: 25px;
				height: 79px;
				background: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/nd-record.png);
				background-size: 100% 100%;
				cursor: pointer;
			}
		}
		.rule-con{
			margin: 42px auto 10px;
		}
		.open-btn-con{
			@include Hcenter;
			gap: 4px;
			margin-top: -80px;
			position: relative;
			.btn{
				width: 178px;
				height: 52px;
				background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/nd-open-1.png);
				background-size: 100% 100%;
				cursor: pointer;
				&.five{
					background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/nd-open-5.png);
				}
			}
		}
	}
	.mession-con{
		padding: 24px 0 0;
		width: 348px;
		height: 458px;
		background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-bg.png);
		background-size: 100% 100%;
		margin: 0 auto 0;
		@include Scenter;
		flex-direction: column;
		.mession-title{
			@include Hcenter;
			gap: 20px;
			margin: 0 auto 12px;
			height: 41px;
			.item{
				cursor: pointer;
				height: 41px;
				img{
					height: 41px;
					display: block;
				}
			}
		}
		.scroll-con{
			width: 305px;
			height: 350px;
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-scroll.png);
			background-size: 100% 100%;
			padding: 10px;
			.el-scrollbar__wrap{
				overflow-x: hidden;
			}
		}
		.mession-list{
			display: flex;
			flex-direction: column;
			gap: 5px;
			.item{
				width: 283px;
				height: 62px;
				@include Scenter;
				background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-item-bg.png);
				background-size: 100% 100%;
				padding: 0 10px;
				img{
					width: 40px;
					height: 40px;
					margin-right: 10px;
				}
				.info{
					flex: 1;
					overflow: hidden;
					.title{
						font-size: 13px;
						color: #79231E;
					}
					.progress{
						font-size: 12px;
						margin-top: 4px;
						color: #A28D7D;
					}
				}
				.award{
					@include Scenter;
					flex-direction: column;
					.num{
						font-size: 12px;
						color: #685648;
					}
					.btn{
						width: 81px;
						height: 34px;
						background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-receive.png);
						background-size: 100% 100%;
						cursor: pointer;
						&.received{
							background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-received.png);
						}
					}
				}
			}
		}
	}
	.mession-title{
		height: 60px;
		display: block;
		margin: 16px auto 12px;
	}
	.mession-recharge-total{
		text-align: center;
		margin: 12px 0;
		color: #79231E;
		span{
			// font-size: 18px;
			color: #fff;
		}
	}
	.mession-recharge-list{
		display: flex;
		flex-wrap: wrap;
		// width: 344px;
		margin: 0 auto;
		gap: 4px;
		padding: 0 4px;
		.item{
			width: 147px;
			height: 154px;
			@include Scenter;
			flex-direction: column;
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-item-bg.png);
			background-size: 100% 100%;
			// padding-top: 30px;
			// &.item-time{
			// 	background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-item-bg-times.png);
			// }
			.title{
				height: 28px;
				@include Scenter;
				span{
					position: relative;
					background: linear-gradient(180deg, #FFF 0%, #FFDDAB 100%);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					font-size: 12px;
					&::before{
						content: '';
						position: absolute;
						background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-item-title-left.png);
						width: 26px;
						height: 17px;
						background-size: 100% 100%;
						left: -26px;
						top: 50%;
						transform: translateY(-50%);
					}
					&::after{
						content: '';
						position: absolute;
						background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-item-title-left.png);
						width: 26px;
						height: 17px;
						background-size: 100% 100%;
						right: -26px;
						top: 50%;
						transform: rotateY(180deg) translateY(-50%);
					}
				}
			}
			.award-img{
				width: 60px;
				height: 60px;
				margin-top: 4px;
			}
			.award{
				border-radius: 19px;
				// background: rgba($color: #A143DB, $alpha: 0.5);
				// height: 24px;
				// padding: 0 8px;
				margin-top: 6px;
				@include HScenter;
				font-size: 12px;
			}
			.progress{
				margin-top: 18px;
				text-align: center;
				font-size: 12px;
				line-height: 14px;
				height: 28px;
			}
			.btn{
				width: 108px;
				height: 34px;
				// margin-top: 8px;
				background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-btn-receive.png);
				background-size: 100% 100%;
				cursor: pointer;
				&.btn-time{
					background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-btn-times.png);
				}
				&.received{
					background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-btn-received.png);
				}
			}
		}
	}
	.box-nav{
		@include HScenter;
		position: relative;
		height: 94px;
		&::before{
			content: '';
			position: absolute;
			width: 109px;
			height: 94px;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-nav-center.png);
			background-size: 100% 100%;
			pointer-events: none;
		}
		.item{
			width: 161px;
			height: 47px;
			@include HScenter;
			flex-direction: column;
			cursor: pointer;
			background-size: 100% 100%;
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-nav.png);
			opacity: 0.8;
			&.active{
				// background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-nav-active.png);
				opacity: 1;
			}
			&:nth-child(1){
				background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-nav-1.png);
				&.active{
					// background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-nav-active-1.png);
				}
			}
			.title{
				font-size: 20px;
				font-weight: bold;
				font-style: italic;
				background: linear-gradient(180deg, #FFD56A 0%, #FFF 100%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			.desc{
				font-size: 12px;
				background: linear-gradient(180deg, #FFD56A 0%, #FFF 100%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			&:nth-child(1){
				// margin-right: -20px;
			}
			&:nth-child(2){
				// margin-left: -20px;
			}
		}
	}
	.box-con{
		height: 446px;
		// background: linear-gradient(180deg, #5728DD 59.79%, rgba(87, 40, 221, 0.00) 100%);
		background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-con-bg.png);
		background-size: 100% 100%;
		padding-top: 5px;
		@include Scenter;
		flex-direction: column;
		.box-rule{
			width: 287px;
			height: 49px;
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-rule.png);
			background-size: 100% 100%;
			@include HScenter;
			font-size: 12px;
			white-space: pre-wrap;
		}
		.box-rate{
			@include Scenter;
			width: 354px;
			gap: 2px;
			.item{
				height: 30px;
				padding-left: 14px;
				position: relative;
				font-size: 12px;
				@include Scenter;
				&::before{
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					border: 1px solid transparent;
					width: 10px;
					height: 10px;
					border-radius: 50%;
				}
				&::after{
					content: '';
					position: absolute;
					left: 3px;
					top: 50%;
					transform: translateY(-50%);
					width: 6px;
					height: 6px;
					border-radius: 50%;
				}
				&:nth-child(1){
					&::before{
						border-color: #FFF61B;
					}
					&::after{
						background: #FFF61B;
					}
				}
				&:nth-child(2){
					&::before{
						border-color: #FF1B44;
					}
					&::after{
						background: #FF1B44;
					}
				}
				&:nth-child(3){
					&::before{
						border-color: #BD7AFF;
					}
					&::after{
						background: #BD7AFF;
					}
				}
				&:nth-child(4){
					&::before{
						border-color: #6398FF;
					}
					&::after{
						background: #6398FF;
					}
				}
				&:nth-child(5){
					&::before{
						border-color: #BABABA;
					}
					&::after{
						background: #BABABA;
					}
				}
			}
		}
		.box-list{
			position: relative;
			@include HScenter;
			flex-direction: column;
			width: 354px;
			height: 224px;
			.item{
				width: 67px;
				height: 50px;
				background: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-item-bg.png);
				background-size: 100% 100%;
				position: absolute;
				@include HScenter;
				opacity: 0.5;
				&.active{
					opacity: 1;
				}
				.img-con{
					width: 50px;
					height: 38px;
					background-size: auto 100%;
					background-repeat: no-repeat;
					background-position: center center;
					@for $i from 1 to 6{
            &.level#{$i}{
              background-image: url('https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/double-box-'+$i+'.png');
            }
          }
				}
				&:nth-child(1){
					left: 0;
					top: 0;
				}
				&:nth-child(2){
					left: 71px;
					top: 0;
				}
				&:nth-child(3){
					left: 142px;
					top: 0;
				}
				&:nth-child(4){
					left: 213px;
					top: 0;
				}
				&:nth-child(5){
					left: 284px;
					top: 0;
				}
				&:nth-child(6){
					left: 284px;
					top: 58px;
				}
				&:nth-child(7){
					left: 284px;
					top: 116px;
				}
				&:nth-child(8){
					left: 284px;
					top: 174px;
				}
				&:nth-child(9){
					left: 213px;
					top: 174px;
				}
				&:nth-child(10){
					left: 142px;
					top: 174px;
				}
				&:nth-child(11){
					left: 71px;
					top: 174px;
				}
				&:nth-child(12){
					left: 0;
					top: 174px;
				}
				&:nth-child(13){
					left: 0;
					top: 116px;
				}
				&:nth-child(14){
					left: 0;
					top: 58px;
				}
			}
			.box-list-total{
				width: 200px;
				height: 49px;
				@include HScenter;
				gap: 2px;
				font-size: 12px;
				background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-total.png);
				background-size: 100% 100%;
				flex-direction: column;
				margin-top: 4px;
				span{
					color: #FFF500;
					font-size: 14px;
				}
			}
			.box-open-con{
				@include Hcenter;
				gap: 2px;
				margin-top: 8px;
				.box-open-btn{
					width: 101px;
					height: 52px;
					background-size: 100% 100%;
					padding-top: 10px;
					font-size: 12px;
					text-align: center;
					position: relative;
					cursor: pointer;
					&::before{
						content: '';
						position: absolute;
						width: 55px;
						height: 15px;
						background-size: 100% 100%;
						top: 26px;
						left: 50%;
						transform: translateX(-50%);
					}
					&:nth-child(1){
						background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-open-1.png);
						color: #FD541E;
						&::before{
							background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-open-1-title.png);
						}
					}
					&:nth-child(2){
						background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-open-5.png);
						&::before{
							background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-open-5-title.png);
						}
					}
				}
			}
		}
		.box-bottom{
			@include HScenter;
			gap: 35px;
			margin-top: 12px;
			.link{
				text-decoration: underline;
				font-size: 12px;
			}
			.skip-con{
				@include HScenter;
				font-size: 12px;
				.van-switch{
					border: none;
					margin-left: 6px;
				}
				.van-switch--on{
					background: var(--logo, linear-gradient(180deg, #FF2525 0%, #FD9D44 100%));
				}
			}
		}
	}
	.box-link-rule{
		width: 340px;
		height: 70px;
		@include HScenter;
		margin: 0 auto 16px;
		background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-link-rule.png);
		background-size: 100% 100%;
		padding: 0 12px;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		white-space: pre-wrap;
	}
	.box-link-list{
		display: flex;
		flex-wrap: wrap;
		gap: 4px;
		width: 356px;
		margin: 0 auto;
		.item{
			width: 116px;
			height: 191px;
			@include Scenter;
			flex-direction: column;
			padding-top: 12px;
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-link-item.png);
			background-size: 100% 100%;
			white-space: nowrap;
			.name{
				font-size: 12px;
				max-width: 80%;
				@include wordhide;
			}
			.img-con{
				width: 77px;
				height: 77px;
			}
			.recharge{
				margin: 4px 0;
				font-size: 13px;
				line-height: 15px;
			}
			.progress{
				font-size: 12px;
				line-height: 15px;
				color: #FFF500;
			}
			.see{
				width: 81px;
				height: 34px;
				background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/box-link-see.png);
				background-size: 100% 100%;
				margin-top: 6px;
			}
		}
	}
	.double-popup-con{
		width: 375px;
		height: 557px;
		background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/popup-bg.png);
		background-size: 100% 100%;
		position: relative;
		padding-top: 79px;
		&.big{
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/popup-bg-big.png);
			height: 381px;
		}
		&.award{
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/popup-bg-award.png);
			padding-top: 170px;
		}
		&.ndAward{
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/popup-bg-ndAward.png);
		}
		&.bean{
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/popup-bg-bean.png);
			height: 449px;
			padding-top: 220px;
		}
		.bean-con{
			text-align: center;
			font-size: 36px;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			span{
				font-size: 16px;
				// line-height: 40px;
			}
		}
		.title{
			width: 289px;
			margin: 0 auto 15px;
			@include Hcenter;
			img{
				height: 26px;
			}
		}
		.close{
			width: 24px;
			height: 24px;
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/popup-close.png);
			background-size: 100% 100%;
			position: absolute;
			cursor: pointer;
			right: 12px;
			top: 26px;
		}
		.table{
			width: 329px;
			margin: 40px auto 0;
			table{
				width: 100%;
				border-collapse: separate;
				border-spacing: 3px;
				tr{
					th{
						height: 40px;
						border: 1px solid #FFD785;
						background: linear-gradient(180deg, #74100B 0%, #920C11 100%);
						white-space: nowrap;
					}
					td{
						height: 40px;
						background: linear-gradient(180deg, #D9560D 0%, #DF2626 100%);
						text-align: center;
						font-size: 12px;
					}
				}
			}
		}
		.page{
			margin-top: 30px;
			.el-pagination__total{
				color: #600F09;
			}
			.btn-prev, .btn-next{
				background: transparent !important;
				color: #600F09 !important;
			}
			.number{
				background: transparent !important;
				color: #600F09 !important;
			}
			.number.active{
				border-radius: 7px;
				background: linear-gradient(180deg, #74100B 0%, #920C11 100%) !important;
				color: #fff !important;
			}
		}
		.ng-img{
			width: 160px;
			height: 160px;
			margin:  0 auto;
			img{
				width: 100%;
			}
		}
		.none-img{
			display: block;
			margin: 110px auto 0;
			height: 60px;
		}
		.nd-name{
			text-align: center;
			margin-top: 50px;
			font-size: 20px;
			color: #79231E;
		}
		.btn{
			margin: 20px auto 0;
			@include HScenter;
			cursor: pointer;
			position: absolute;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%);
			img{
				height: 67px;
			}
		}
		.btn-con{
			@include Hcenter;
			gap: 10px;
			position: absolute;
			bottom: -20px;
			left: 50%;
			transform: translateX(-50%);
			.btn{
				margin: 0;
				cursor: pointer;
				position: relative;
				bottom: unset;
				left: unset;
				transform: unset;
				img{
					height: 49px;
				}
			}
		}
	}
	.draw-list{
		@include HScenter;
		gap: 8px;
		flex-wrap: wrap;
		margin-bottom: 15px;
		&.one{
			margin-bottom: 25px;
			margin-top: 20px;
			.item{
				width: 165px;
				.item-main{
					height: 160px;
					background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/draw-item-big.png) !important;
					.alias{
						height: 24px;
						padding-right: 8px;
						padding-top: 10px;
					}
					.img-con{
						width: 126px;
						height: 96px;
					}
					.price{
						bottom: 16px;
						font-size: 16px;
					}
				}
				.name{
					font-size: 16px;
					line-height: 16px;
					margin-top: 20px;
				}
			}
		}
		.item{
			width: 105px;
			.item-main{
				height: 95px;
				position: relative;
				background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/draw-item.png);
				background-size: 100% 100%;
				@include HScenter;
				&.active{
					background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/draw-item-active.png);
				}
				.alias{
					position: absolute;
					right: 0;
					top: 0;
					width: 58px;
					height: 18px;
					font-size: 12px;
					@include HScenter;
				}
				.img-con{
					width: 74px;
					height: 56px;
					background-size: auto 100%;
					background-repeat: no-repeat;
					background-position: center center;
					margin-top: -10px;
					@for $i from 1 to 6{
            &.level#{$i}{
              background-image: url('https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/double-box-'+$i+'.png');
            }
          }
				}
				.price{
					position: absolute;
					bottom: 5px;
					left: 0;
					width: 100%;
					height: 24px;
					@include HScenter;
					font-size: 12px;
					.coins{
						background-image: url(~@/assets/img/coins-white1.svg);
						width: 16px;
						height: 16px;
						margin-right: 2px;
					}
				}
			}
			.name{
				font-size: 12px;
				line-height: 12px;
				margin-top: 9px;
				@include wordhide;
				text-align: center;
				color: #79231E;
			}
		}
	}
	.mession-recharge-type{
		@include Hcenter;
		gap: 20px;
		margin: 0 auto 12px;
		.item{
			width: 136px;
			height: 38px;
			cursor: pointer;
			img{
				width: 100%;
				height: 100%;
				display: block;
			}
		}
	}
	.bar-img{
		width: 375px;
		margin: 12px auto 0;
	}
	.mession-recharge-con{
		width: 348px;
		height: 443px;
		margin: 16px auto 0;
		background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/mession-recharge-con-bg.png);
		background-size: 100% 100%;
		padding: 24px 0 0;
		.scroll-con{
			width: 308px;
			height: 320px;
			margin: 0 auto;
			.el-scrollbar__wrap{
				overflow-x: hidden;
			}
		}
	}
	.crazy-list{
		display: flex;
		flex-wrap: wrap;
		gap: 7px;
		width: 362px;
		margin: 12px auto 0;
		.item{
			@include Scenter;
			flex-direction: column;
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/crazy-item-bg.png);
			background-size: 100% 100%;
			width: 177px;
			height: 155px;
			.title{
				height: 28px;
				@include Scenter;
				span{
					position: relative;
					background: linear-gradient(180deg, #FFF 0%, #FFDDAB 100%);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					font-size: 12px;
					&::before{
						content: '';
						position: absolute;
						background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-item-title-left.png);
						width: 26px;
						height: 17px;
						background-size: 100% 100%;
						left: -26px;
						top: 50%;
						transform: translateY(-50%);
					}
					&::after{
						content: '';
						position: absolute;
						background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/recharge-item-title-left.png);
						width: 26px;
						height: 17px;
						background-size: 100% 100%;
						right: -26px;
						top: 50%;
						transform: rotateY(180deg) translateY(-50%);
					}
				}
			}
			.award-list{
				display: flex;
				margin-top: 8px;
				.award-item{
					@include Scenter;
					flex-direction: column;
					.award-item-img{
						width: 80px;
						height: 70px;
						@include Scenter;
						flex-direction: column;
						background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/crazy-item-award-bg.png);
						background-size: 100% 100%;
						img{
							width: 48px;
							height: 48px;
						}
						span{
							font-size: 12px;
							white-space: nowrap;
							margin-top: 2px;
						}	
					}
					.btn{
						background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/crazy-item-btn.png);
						background-size: 100% 100%;
						width: 81px;
						height: 34px;
						margin-top: 12px;
						cursor: pointer;
						&.received{
							background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/crazy-item-btn-received.png);
						}
					}
				}
			}
		}
	}
	.invite-con{
		@include HScenter;
		margin: 20px 0;
		.code{
			// flex: 1;
			background: linear-gradient(180deg, #FFCB6B 0%, #FEFEF5 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		.copy{
			width: 62px;
			height: 30px;
			border-radius: 10px;
			border-radius: 10px;
			background: linear-gradient(180deg, #FFCB6B 0%, #FEFEF5 100%) !important;
			box-shadow: 0px 4px 10px 0px rgba(255, 226, 153, 0.52) inset;
			margin-left: 10px;
			@include HScenter;
			font-size: 12px;
			color: #FF1F1F !important;
			white-space: nowrap;
			.van-button__text{
				font-size: 12px;
			}
			&.link{
				width: 92px;
			}
		}
	}
	.new-list{
		display: flex;
		flex-wrap: wrap;
		gap: 6px;
		width: 358px;
		margin: 0 auto;
		.item{
			width: 173px;
			height: 200px;
			@include Scenter;
			flex-direction: column;
			background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/new-item-bg.png);
			background-size: 100% 100%;
			padding-top: 5px;
			.title{
				background: linear-gradient(180deg, #FFF 39%, #FFCB6B 100%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			.desc{
				text-shadow: 0px 4px 4px #900E24;
				font-size: 12px;
			}
			.award-list{
				display: flex;
				gap: 4px;
				margin-top: 14px;
				.award-item{
					width: 80px;
					height: 78px;
					position: relative;
					@include Hcenter;
					background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/new-award.png);
					background-size: 100% 100%;
					img{
						width: 48px;
						height: 48px;
						margin-top: 3px;
					}
					span{
						position: absolute;
						bottom: 4px;
						left: 50%;
						transform: translateX(-50%);
						color: #C12A1B;
						font-size: 12px;
						white-space: nowrap;
					}
				}
			}
			.btn{
				width: 49px;
				height: 49px;
				background-size: 100% 100%;
				background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/new-btn.png);
				margin-top: 4px;
				cursor: pointer;
				&.received{
					background-image: url(https://wanskins.oss-cn-beijing.aliyuncs.com/images/ac/20240210/new-btn-received.png);
				}
			}
		}
	}
	.new-award-list{
		@include HScenter;
		gap: 8px;
		.item{

		}
	}
	.snow-animate{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    z-index: 9999;
    display: flex;
    justify-content: space-around;
    .snow-item{
      height: 100%;
      width: 10px;
      position: relative;
      @for $i from 0 to 20 {
        &.snow-item-animate-#{$i}{
          &::before{
            animation-delay: $i*2s;
          }
        }
      }
      @for $i from 0 to 6 {
        &.snow-item-time-#{$i}{
          &::before{
            animation-duration: 30s+3*$i;
          }
        }
      }
      @for $i from 0 to 4 {
        &.snow-item-opacity-#{$i}{
          &::before{
            opacity: .3+$i*0.2;
          }
        }
      }
      &::before{
        content: '';
        position: absolute;
        left: 0%;
        width: 10px;
        height: 10px;
        background-size: 100% 100%;
        opacity: .7;
        @keyframes snow {
          0% {
            top: -50%;
            transform: rotate(0deg) translateX(20px);
          }
          100%{
            top: 200%;
            transform: rotate(360deg) translateX(20px);
          }
        }
        animation:snow linear 30s infinite both;
      }
    }
    @for $i from 0 to 10 {
      .snow-item-margin-#{$i} {
        &::before{
          margin-left: -8px+1px*$i/20;
          margin-top: -60px+2px*$i/20;
        }
      }
    }
    @for $i from 0 to 30 {
      .snow-item-left-#{$i} {
        left: 3.3%*$i;
      }
    }
    @for $i from 0 to 10 {
      .snow-item-size-#{$i} {
        &::before{
          transform: scale(0.1*($i+1));
        }
      }
    }
    @for $i from 0 to 3 {
      .snow-item-bg-#{$i} {
        &::before{
          // background-image: url("~@/assets/img/snow-img-"+$i+".svg");
					background: #fff;
					filter: blur(3px);
					border-radius: 50%;
        }
      }
    }
  }
</style>
