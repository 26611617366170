<template>
	<div class="container double-con">
		<div class="nd-con">
			<div class="nd-top">
				剩余圣诞雪花：{{ activityInfo.user.egg_bean }}
				<div class="nd-record" @click="ndRecordShow=true"></div>
			</div>
			<div class="rule-con">
				{{ activityInfo.play_desc }}
			</div>
			<div class="open-btn-con">
				<div class="btn" @click="eggOpen(1)"></div>
				<div class="btn five" @click="eggOpen(5)"></div>
			</div>
		</div>
		<img class="bar-img" style="margin-top: 0;" src="~@/assets/img/double/bar1.png" alt="">
		<img class="mession-title" src="~@/assets/img/double/mession-title.png" alt="">
		<div class="mession-con">
			<div class="mession-title">
				<div class="item" :class="{'active': messionType==1}" @click="messionType=1"><span>每日任务</span></div>
				<div class="item" :class="{'active': messionType==2}" @click="messionType=2"><span>累计任务</span></div>
			</div>
			<div class="invite-con">
				<div class="code">我的邀请码：{{ User.invite_code }}</div>
				<!-- <div>复制</div> -->
				<van-button class="copy" size="small" type="info" v-clipboard:copy="User.invite_code" v-clipboard:success="copy" v-clipboard:error="onError">复制</van-button>
				<van-button class="copy link" size="small" type="info" v-clipboard:copy="'https://www.wanskins.com/?ref='+User.invite_code" v-clipboard:success="copy" v-clipboard:error="onError">复制邀请链接</van-button>
				<!-- <div class="copy link">复制邀请链接</div> -->
			</div>
			<div class="scroll-con">
				<el-scrollbar style="height:100%;">
					<div class="mession-list">
						<div class="item" v-for="(item,index) in taskList" :key="index" v-show="messionType==1 ? item.isDay : !item.isDay">
							<img v-if="item.icon=='sign'" src="~@/assets/img/double/icon-mession-sign.png" alt="">
							<img v-else-if="item.icon=='recharge'" src="~@/assets/img/double/icon-mession-recharge.png" alt="">
							<img v-else-if="item.icon=='user'" src="~@/assets/img/double/icon-mession-user.png" alt="">
							<div class="info">
								<div class="title"></div>{{ item.title }}
								<div class="progress" v-if="item.rule.days">(进度：{{ `${item.progress.days}/${item.rule.days}` }})</div>
								<div class="progress" v-else-if="item.rule.bean">(进度：{{ `${item.progress.bean}/${item.rule.bean}` }})</div>
								<div class="progress" v-else-if="item.rule.num">(进度：{{ `${item.progress.num}/${item.rule.num}` }})</div>
							</div>
							<div class="award">
								<div class="num">圣诞雪花+{{ item.rule.egg_bean }}</div>
								<div class="btn" :class="{'received': item.progress.finish}" @click="!item.progress.finish ? finish(item.id) : ''"></div>
							</div>
						</div>
					</div>
				</el-scrollbar>
			</div>
		</div>
		<img class="bar-img" src="~@/assets/img/double/bar2.png" alt="">
		<img class="mession-title" src="~@/assets/img/double/mession-title-recharge.png" alt="">
		<div class="rule-con">
			{{ activityInfo.recharge_desc }}
			<div class="recharge-record" @click="rechargeRecordShow=true"></div>
		</div>
		<div class="mession-recharge-type" style="margin-top: 10px;">
			<div class="item" :class="{'active': rechargeType == 1}" @click="rechargeType = 1"><span>每次充值礼</span></div>
			<div class="item" :class="{'active': rechargeType == 2}" @click="rechargeType = 2"><span>每日充值礼</span></div>
		</div>
		<div class="mession-recharge-total">
			累计充值C币：<span>{{ activityInfo.user.recharge_total }}</span> 今日充值C币：<span>{{ activityInfo.user.recharge_today }}</span>
		</div>
		<div class="mession-recharge-list" v-show="rechargeType == 1">
			<div class="item" :class="{'item-time': item.type==12}" v-for="(item,index) in rechargeTimeList" :key="index">
				<div class="title"></div>{{ item.title }}
				<div class="award">随机{{item.rule.act_bean_min}}-{{item.rule.act_bean_max}}个双旦秘劵</div>
				<div class="progress">
					<span v-if="item.type==13">每日充值{{ item.rule.bean }}C币<br />（{{ item.progress.bean }}/{{ item.rule.bean }}）</span>
				</div>
				<div class="btn" :class="[item.type==12?'btn-time':item.progress.received?'received':'receive']" @click="item.type==12?($router.push('/Recharge')):item.progress.received?'':finish(item.id)"></div>
			</div>
		</div>
		<div class="mession-recharge-list" v-show="rechargeType == 2">
			<div class="item" :class="{'item-time': item.type==12}" v-for="(item,index) in rechargeList" :key="index">
				<div class="title"></div>{{ item.title }}
				<div class="award">随机{{item.rule.act_bean_min}}-{{item.rule.act_bean_max}}个双旦秘劵</div>
				<div class="progress">
					<span v-if="item.type==13">每日充值{{ item.rule.bean }}C币<br />（{{ item.progress.bean }}/{{ item.rule.bean }}）</span>
				</div>
				<div class="btn" :class="[item.type==12?'btn-time':item.progress.received?'received':'receive']" @click="item.type==12?($router.push('/Recharge')):item.progress.received?'':finish(item.id)"></div>
			</div>
		</div>
		<img class="bar-img" src="~@/assets/img/double/bar3.png" alt="">
		<img class="mession-title" src="~@/assets/img/double/box-title.png" alt="">
		<div class="box-nav">
			<div class="item" :class="{'active': boxIndex == index}" @click="boxIndex = index; GetBoxDetail();" v-for="(item,index) in activityInfo.box_draw_config" :key="index">
				<div class="title">{{ item.name }}</div>
				<div class="desc">累计充值{{item.recharge_bean}}C币</div>
			</div>
		</div>
		<div class="box-con">
			<div class="box-rule">
				{{ activityInfo.box_draw_desc }}
			</div>
			<div class="box-rate">
				<div class="item" v-show="item>0" v-for="(item,index) in rateList" :key="index">
					{{item.toFixed(3)}}%
				</div>
			</div>
			<div class="box-list">
				<div class="item" :class="{'active': current==index}" v-for="(item,index) in detailList" :key="index">
					<div class="img-con" :class="['level'+item.lv]">
						<img :src="item.cover" alt="">
					</div>
				</div>
				<div class="box-list-total">
					<div>当前累计充值： <span>{{ activityInfo.user.recharge_total }}</span></div>
					<div>持有双旦秘劵： <span>{{ activityInfo.user.act_bean }}</span></div>
				</div>
				<div class="box-open-con">
					<div class="box-open-btn" @click="boxOpen(1)">{{ activityInfo.box_draw_config[boxIndex].use_act_bean }}个双旦秘劵</div>
					<div class="box-open-btn" @click="boxOpen(5)">{{ activityInfo.box_draw_config[boxIndex].use_act_bean * 5 }}个双旦秘劵</div>
				</div>
			</div>
			<div class="box-bottom">
				<div class="link" @click="drawRecordShow=true">获奖记录</div>
				<div class="skip-con">
					跳过动画
					<van-switch v-model="Animation_checked" size="16px" />
				</div>
			</div>
		</div>
		<img class="bar-img" src="~@/assets/img/double/bar4.png" alt="">
		<img class="mession-title" style="margin-top: 0;margin-bottom: 16px;" src="~@/assets/img/double/box-link-title.png" alt="">
		<div class="box-link-rule">
			{{ activityInfo.box_give_desc }}
		</div>
		<div class="box-link-list">
			<div class="item" v-for="(item,index) in activityInfo.box_give_config" :key="index" @click="ToOpen(item.box_id)">
				<div class="name">{{ item.name }}</div>
				<div class="img-con">
					<img :src="item.cover" alt="">
				</div>
				<div class="recharge">累计充值{{ item.recharge_bean }}</div>
				<div class="progress">（{{ activityInfo.user.recharge_total }}/{{ item.recharge_bean }}）</div>
				<div class="see"></div>
			</div>
		</div>
		<img class="bar-img" src="~@/assets/img/double/bar5.png" alt="">
		<van-overlay :show="ndRecordShow">
			<div class="wrapper">
				<div class="double-popup-con">
					<div class="title">
						<img src="~@/assets/img/double/popup-title-cyjl.png" alt="">
					</div>
					<div class="close" @click="ndRecordShow=false"></div>
					<div class="table">
						<table>
							<tr>
								<th>抽奖时间</th>
								<th>奖励内容</th>
								<th>奖励状态</th>
							</tr>
							<tr v-for="(item,index) in eggRecord.slice(0,5)" :key="index">
								<td>{{ item.created_at }}</td>
								<td>{{ item.award }}</td>
								<td>{{ item.status_text }}</td>
							</tr>
						</table>
					</div>
					<div class="page">
						<el-pagination background layout="total, prev, pager, next" :total="eggTotal" :page-size="5" @current-change="eggPageChange" prev-text="上一页" next-text="下一页" small></el-pagination>
					</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="rechargeRecordShow">
			<div class="wrapper">
				<div class="double-popup-con">
					<div class="title">
						<img src="~@/assets/img/double/popup-title-czjl.png" alt="">
					</div>
					<div class="close" @click="rechargeRecordShow=false"></div>
					<div class="table">
						<table>
							<tr>
								<th>充值时间</th>
								<th>充值金额</th>
								<th>赠送双旦秘劵</th>
							</tr>
							<tr v-for="(item,index) in rechargeRecord.slice(0,5)" :key="index">
								<td>{{ item.created_at }}</td>
								<td>{{ item.recharge_bean }}</td>
								<td>{{ item.act_bean }}</td>
							</tr>
						</table>
					</div>
					<div class="page">
						<el-pagination background layout="total, prev, pager, next" :total="rechargeTotal" :page-size="5" @current-change="rechargePageChange" prev-text="上一页" next-text="下一页" small></el-pagination>
					</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="drawRecordShow">
			<div class="wrapper">
				<div class="double-popup-con">
					<div class="title">
						<img src="~@/assets/img/double/popup-title-hjjl.png" alt="">
					</div>
					<div class="close" @click="drawRecordShow=false"></div>
					<div class="table">
						<table>
							<tr>
								<th>抽奖时间</th>
								<th>宝箱名称</th>
								<th>奖励内容</th>
								<th>价值</th>
							</tr>
							<tr v-for="(item,index) in drawRecord.slice(0,5)" :key="index">
								<td>{{ item.created_at }}</td>
								<td>{{ item.box_name }}</td>
								<td>{{ item.award }}</td>
								<td>{{ item.bean }}</td>
							</tr>
						</table>
					</div>
					<div class="page">
						<el-pagination background layout="total, prev, pager, next" :total="drawTotal" :page-size="5" @current-change="drawPageChange" prev-text="上一页" next-text="下一页" small></el-pagination>
					</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="ndGetShow">
			<div class="wrapper">
				<div class="double-popup-con">
					<div class="title">
						<img src="~@/assets/img/double/popup-title-hjjl.png" alt="">
					</div>
					<div class="ng-img">
						<img v-if="ndGetNum>0" src="~@/assets/img/double/nd-get-img.png" alt="">
						<img v-else src="~@/assets/img/double/nd-get-none.png" alt="">
					</div>
					<div class="nd-name" v-if="ndGetNum>0">双旦秘劵*{{ ndGetNum }}</div>
					<div class="nd-name" v-else>2023 祝您好运长相伴</div>
					<div class="btn" @click="ndGetShow=false">
						<img src="~@/assets/img/double/know-btn.png" alt="">
					</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="drawShow">
			<div class="wrapper">
				<div class="double-popup-con" :class="{'big': times==5}">
					<div class="title">
						<img src="~@/assets/img/double/popup-title-gxhd.png" alt="">
					</div>
					<div class="draw-list" :class="{'one': times==1}">
						<div class="item" v-for="(item,index) in getArr" :key="index" @click="item.checked=!item.checked">
							<div class="item-main" :class="{'active': item.checked}">
								<div class="alias">{{ item.dura_alias }}</div>
								<div class="img-con" :class="['level'+item.lv]">
									<img :src="item.cover" alt="">
								</div>
								<div class="price">
									<div class="coins"></div>{{ item.bean }}
								</div>
							</div>
							<div class="name">{{ item.name }}</div>
						</div>
					</div>
					<div class="btn-con">
						<div class="btn" @click="drawShow=false">
							<img src="~@/assets/img/double/package-btn.png" alt="">
						</div>
						<!-- <div class="btn" @click="PostCash">
							<img src="~@/assets/img/double/rechange-btn.png" alt="">
						</div> -->
					</div>
					
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="finishShow">
			<div class="wrapper">
				<div class="double-popup-con">
					<div class="title">
						<img src="~@/assets/img/double/popup-title-hjjl.png" alt="">
					</div>
					<div class="ng-img">
						<img v-if="finishType == 'egg'" src="~@/assets/img/double/nd-get-coins.png" alt="">
						<img v-else src="~@/assets/img/double/nd-get-img.png" alt="">
					</div>
					<div class="nd-name">{{ finishType == 'egg' ? '圣诞雪花' : '双旦秘劵' }} * {{ finishNum }}</div>
					<div class="btn" @click="finishShow=false">
						<img src="~@/assets/img/double/know-btn.png" alt="">
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	const scrollAudio = require("@/assets/audio/doubleScroll.mp3");
	const openAudio = require("@/assets/audio/doubleOpen.mp3");
	const closeAudio = require("@/assets/audio/drawClose.mp3");
	import { activity20240210Info, activity20240210Finish, activity20240210Draw, activity20240210EggRun, activity20240210EggRecords, activity20240210DrawRecords, activity20240210RechargeRecords, BoxDetail, Cash, PersonalInfo } from '@/network/api.js'
	import { mapState, mapMutations } from 'vuex'
	export default{
		name:'Double',
		data(){
			return{
				boxIndex: 0,
				boxInfo: {

				},
				boxList: [
					{
						title: '普通金库',
						recharge: 5
					},
					{
						title: '高级金库',
						recharge: 10
					}
				],
				Animation_checked: true,
				activityInfo: {
					box_give_config:[],
					box_draw_config:[{}],
					user: {}
				},
				taskList: [],
				rechargeList: [],
				rechargeTimeList: [],
				detailList: [],
				rateList:[0,0,0,0,0],
				getArr:[],
				times:[],
				getIndex:0,
				isRolling:true,
				current: 0,
				speed: 150, // 时间->速度
				diff: 10, // 基数 基数越小转速越慢
				time: 0, // 当前时间戳
				giftId:0,
				disabled:false,
				eggRecord: [],
				eggTotal:0,//背包总数据
				eggPage:1,//背包列表页码
				ndRecordShow: false,
				rechargeRecord: [],
				rechargeTotal:0,//背包总数据
				rechargePage:1,//背包列表页码
				rechargeRecordShow: false,
				drawRecord: [],
				drawTotal:0,//背包总数据
				drawPage:1,//背包列表页码
				drawRecordShow: false,
				scrollAudio: undefined,
				openAudio: undefined,
				closeAudio: undefined,
				ndGetShow: false,
				ndGetNum: 0,
				drawShow:false,
				finishShow: false,
				finishNum: 0,
				finishType: 'egg',
				rechargeType: 1,
				messionType: 1
			}
		},
		computed:{
			...mapState(['User'])
		},
		created() {
			this.GetInfo()
			this.getEggRecord()
			this.getRechargeRecord()
			this.getDrawRecord()
			this.scrollAudio = this.audioInit(scrollAudio);
			this.openAudio = this.audioInit(openAudio);
			this.closeAudio = this.audioInit(closeAudio);
		},
		methods:{
			...mapMutations([
				'PostUser'
			]),
			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.PostUser(res.data.message)
				})
			},
			//兑换金币(回收)
			PostCash() {
				let arr = this.getArr.filter((item) => {
					return item.checked
				})
				let data = []
				for (let i = 0; i < arr.length; i++) {
					data.push({
						"id": arr[i].id
					})
				}
				Cash(JSON.stringify(data)).then((res) => {
					this.GetPersonalInfo()
					this.drawShow = false
				})
			},
			audioInit (src) {
				const audio = new Audio();
				audio.src = src;
				audio.preload = 'auto';
				audio.load();
				return audio;
			},
			getEggRecord(){
				activity20240210EggRecords(this.eggPage).then(res=>{
					this.eggRecord = res.data.data.records
					this.eggTotal = res.data.data.total
				})
			},
			eggPageChange(val) {
				this.eggPage = val
				this.getEggRecord()
			},
			getRechargeRecord(){
				activity20240210RechargeRecords(this.rechargePage).then(res=>{
					this.rechargeRecord = res.data.data.records
					this.rechargeTotal = res.data.data.total
				})
			},
			rechargePageChange(val) {
				this.rechargePage = val
				this.getRechargeRecord()
			},
			getDrawRecord(){
				activity20240210DrawRecords(this.drawPage).then(res=>{
					this.drawRecord = res.data.data.records
					this.drawTotal = res.data.data.total
				})
			},
			drawPageChange(val) {
				this.drawPage = val
				this.getDrawRecord()
			},
			//开箱
			eggOpen(num){
				let params = {
					times: num,
				}
				activity20240210EggRun(params).then((res) => {
					const {data} = res.data
					let ndGetNum = 0
					data.awards.map(item=>{
						item.type=='2' && (ndGetNum+=item.act_bean)
					})
					this.ndGetNum = ndGetNum
					this.ndGetShow = true
					this.GetInfo()
				})
			},
			//宝箱详情
			GetBoxDetail(){
				if(this.activityInfo.box_draw_config.length==0){
					return false
				}
				if(this.boxInfo[this.activityInfo.box_draw_config[this.boxIndex].box_id]){
					const data = this.boxInfo[this.activityInfo.box_draw_config[this.boxIndex].box_id]
					let rateList = [0,0,0,0,0]
					data.box_awards.map(item=>{
						rateList[item.lv-1] += parseFloat(item.weight)
					})
					this.rateList = rateList
					this.detailList = data.box_awards
					return false
				}
				BoxDetail(this.activityInfo.box_draw_config[this.boxIndex].box_id).then((res) => {
					const {data} = res.data;
					this.boxInfo[this.activityInfo.box_draw_config[this.boxIndex].box_id] = data
					let rateList = [0,0,0,0,0]
					data.box_awards.map(item=>{
						rateList[item.lv-1] += parseFloat(item.weight)
					})
					this.rateList = rateList
					this.detailList = data.box_awards
				})
			},
			//福利箱列表
			GetInfo(){
				activity20240210Info().then((res) => {
					const {data} = res.data;
					this.activityInfo = data
					this.GetBoxDetail()
					var dayArr = [1,2,4,6,7,8,13]
					let taskList = data.tasks.filter(item=>(item.type != 12 && item.type != 13))
					this.taskList = taskList.map(item=>({
						...item,
						icon: item.rule.days?'sign':item.rule.bean?'recharge':'user',
						isDay: dayArr.indexOf(item.type)>-1 ? true : false
					}))
					this.rechargeList = data.tasks.filter(item=>(item.type==13))
					this.rechargeTimeList = data.tasks.filter(item=>(item.type==12))
				})
			},
			finish(id){
				activity20240210Finish({id}).then((res)=>{
					const {data} = res.data;
					if(data.egg_bean>0){
						this.finishType = 'egg'
						this.finishNum = data.egg_bean
					}else if(data.act_bean>0){
						this.finishType = 'act'
						this.finishNum = data.act_bean
					}
					this.finishShow = true
					// this.$notify({
					// 	type: 'success',
					// 	message: '领取成功'
					// })
					this.GetInfo()
				})
			},
			//路由跳转开箱
			ToOpen(id){
				this.$router.push({
					path:'/Open',
					query:{
						id:id,
						type: 'double'
					}
				})
			},
			//复制文本
			copy(e) {
				// console.log(e)
				this.$notify({
					type: 'success',
					message: '复制成功'
				})
			},
			onError(e) {
				// console.log(e)
				this.$notify({
					type: 'danger',
					message: '复制失败'
				})
			},
			//开箱
			boxOpen(num){
				if (this.disabled) {
					return;
				}
				this.disabled = true;
				let params = {
					type: 2,
					times: num,
					box_id: this.activityInfo.box_draw_config[this.boxIndex].box_id
				}
				activity20240210Draw(params).then((res) => {
					const {data} = res.data
					data.awards.map(item=>{
						item.checked = true
					})
					this.getArr = data.awards
					this.times = data.awards.length
					if(this.Animation_checked){
						this.drawShow = true
						this.disabled = false
					}else{
						this.startAnimate(num)
					}
					this.GetInfo()
				}).catch(() => {
					this.disabled = false;
				});
			},
			startAnimate(times){
				this.times = times;
				this.current = 0;
				this.getIndex = 0;
				this.giftId = this.getArr[this.getIndex].box_award_id;
				this.luckDraw()
			},
			// 开始抽奖
			luckDraw() {
				if(this.isRolling){ // 开始抽奖
					this.current = 0;
					this.isRolling = false;
					this.time = Date.now();
					this.speed = 10;
					this.move(); // 开始抽奖动效
				}
			},
			move() {
				this.timer = setTimeout(() => {
					this.scrollAudio.play()
					this.current++;
					if (this.current > 13) {
						this.current = 0;
					}
					// 若抽中的奖品id存在，则开始减速转动
					if (this.giftId && (Date.now() - this.time) / 1000 > 1.5) {
						this.speed += this.diff; // 转动减速
						if ((Date.now() - this.time) / 1000 > (this.times == 1 ? 2.2 : 2.2) && this.giftId == this.detailList[this.current].id) {
							clearTimeout(this.timer);
							this.isRolling = true; // 抽奖结束
							this.getIndex++;
							if(this.getIndex < this.getArr.length){
								this.giftId = this.getArr[this.getIndex].box_award_id;
								this.timerDelay = setTimeout(()=>{
									this.luckDraw()
								},1000)
							}else{
								this.timerDelay = setTimeout(()=>{
									this.closeAudio.play()
									// if(this.times == 1){
									// 	this.openDrawOne = true;
									// }else{
									// 	this.openDrawFive = true;
									// }
									this.disabled = false
									this.drawShow = true
									this.getIndex = 0
									this.disabled = false
									this.current = -1
								},1000)
							}
							return false
						}
					}
					else {
						// 若抽中的奖品不存在，则加速转动
						if(this.speed>10){
							this.speed -= this.diff;
						}
						// this.speed -= this.diff;
					}
					this.move();
				}, this.speed);
			},
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.double-con{
		background: url(~@/assets/img/double/body-bg.png);
		background-size: 100% auto;
	}
	.rule-con{
		width: 355px;
		height: 122px;
		background: url(~@/assets/img/double/rule-bg.png);
		background-size: 100% 100%;
		margin: 0 auto;
		white-space: pre-wrap;
		font-size: 12px;
		line-height: 20px;
		position: relative;
		padding: 12px 18px;
		.recharge-record{
			position: absolute;
			top: 50%;
			right: -10px;
			width: 29px;
			height: 79px;
			background: url(~@/assets/img/double/recharge-record.png);
			background-size: 100% 100%;
			transform: translateY(-50%);
		}
	}
	.nd-con{
		.nd-top{
			height: 318px;
			background: url(~@/assets/img/double/nd-bg.png);
			background-size: 100% 100%;
			text-align: center;
			padding-top: 294px;
			position: relative;
			font-size: 12px;
			.nd-record{
				position: absolute;
				right: 0;
				top: 84px;
				width: 25px;
				height: 79px;
				background: url(~@/assets/img/double/nd-record.png);
				background-size: 100% 100%;
				cursor: pointer;
			}
		}
		.rule-con{
			margin: 10px auto 10px;
		}
		.open-btn-con{
			@include Hcenter;
			gap: 16px;
			.btn{
				width: 154px;
				height: 57px;
				background-image: url(~@/assets/img/double/nd-open-1.png);
				background-size: 100% 100%;
				cursor: pointer;
				&.five{
					background-image: url(~@/assets/img/double/nd-open-5.png);
				}
			}
		}
	}
	.mession-con{
		padding: 9px 16px 0;
		width: 350px;
		height: 504px;
		background-image: url(~@/assets/img/double/mession-bg.png);
		background-size: 100% 100%;
		margin: 0 auto 0;
		.mession-title{
			@include Hcenter;
			gap: 20px;
			margin: 0 auto 12px;
			.item{
				width: 140px;
				height: 39px;
				font-size: 16px;
				background-size: 100% 100%;
				background-image: url(~@/assets/img/double/mession-type.png);
				@include HScenter;
				cursor: pointer;
				&.active{
					background-image: url(~@/assets/img/double/mession-type-active.png);
					span{
						background: linear-gradient(180deg, #FFF 25.42%, #FF3164 100%);
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}
			}
		}
		.invite-con{
			@include Scenter;
			margin-bottom: 8px;
			.code{
				flex: 1;
			}
			.copy{
				width: 62px;
				height: 30px;
				border-radius: 10px;
				background: #22222F !important;
				box-shadow: 0px 0px 43.2px 0px #22222F inset;
				margin-left: 10px;
				@include HScenter;
				font-size: 12px;
				color: #fff !important;
				white-space: nowrap;
				.van-button__text{
					font-size: 12px;
				}
				&.link{
					width: 92px;
				}
			}
		}
		.scroll-con{
			width: 317px;
			height: 389px;
			.el-scrollbar__wrap{
				overflow-x: hidden;
			}
		}
		.mession-list{
			display: flex;
			flex-direction: column;
			gap: 10px;
			.item{
				width: 317px;
				height: 73px;
				@include Scenter;
				background-image: url(~@/assets/img/double/mession-item-bg.png);
				background-size: 100% 100%;
				padding: 0 10px;
				img{
					width: 40px;
					height: 40px;
					margin-right: 10px;
				}
				.info{
					flex: 1;
					overflow: hidden;
					.title{
						font-size: 13px;
					}
					.progress{
						font-size: 12px;
						margin-top: 8px;
					}
				}
				.award{
					@include Scenter;
					flex-direction: column;
					.num{
						font-size: 12px;
					}
					.btn{
						width: 72px;
						height: 34px;
						background-image: url(~@/assets/img/double/mession-receive.png);
						background-size: 100% 100%;
						cursor: pointer;
						&.received{
							background-image: url(~@/assets/img/double/mession-received.png);
						}
					}
				}
			}
		}
	}
	.mession-title{
		height: 32px;
		display: block;
		margin: 8px auto 14px;
	}
	.mession-recharge-total{
		text-align: center;
		margin: 12px 0;
		span{
			font-size: 18px;
			color: #FFF500;
		}
	}
	.mession-recharge-list{
		display: flex;
		flex-wrap: wrap;
		width: 344px;
		margin: 0 auto;
		gap: 4px;
		.item{
			width: 170px;
			height: 184px;
			@include Scenter;
			flex-direction: column;
			background-image: url(~@/assets/img/double/recharge-item-bg.png);
			background-size: 100% 100%;
			padding-top: 30px;
			&.item-time{
				background-image: url(~@/assets/img/double/recharge-item-bg-times.png);
			}
			.title{

			}
			.award{
				border-radius: 19px;
				background: rgba($color: #A143DB, $alpha: 0.5);
				height: 24px;
				padding: 0 8px;
				margin-top: 10px;
				@include HScenter;
				font-size: 12px;
			}
			.progress{
				margin-top: 18px;
				text-align: center;
				font-size: 12px;
				line-height: 14px;
				height: 28px;
			}
			.btn{
				width: 77px;
				height: 31px;
				margin-top: 8px;
				background-image: url(~@/assets/img/double/recharge-btn-receive.png);
				background-size: 100% 100%;
				cursor: pointer;
				&.btn-time{
					background-image: url(~@/assets/img/double/recharge-btn-times.png);
				}
				&.received{
					background-image: url(~@/assets/img/double/recharge-btn-received.png);
				}
			}
		}
	}
	.box-nav{
		display: flex;
		justify-content: space-between;
		// background: #5728DD;
		margin-top: 12px;
		.item{
			width: 204px;
			height: 53px;
			@include HScenter;
			flex-direction: column;
			cursor: pointer;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/double/box-nav.png);
			&:nth-child(1){
				background-image: url(~@/assets/img/double/box-nav-1.png);
				&.active{
					background-image: url(~@/assets/img/double/box-nav-active-1.png);
				}
			}
			&.active{
				background-image: url(~@/assets/img/double/box-nav-active.png);
			}
			.title{
				font-size: 20px;
				font-weight: bold;
				font-style: italic;
			}
			.desc{
				font-size: 12px;
			}
			&:nth-child(1){
				margin-right: -20px;
			}
			&:nth-child(2){
				margin-left: -20px;
			}
		}
	}
	.box-con{
		height: 373px;
		// background: linear-gradient(180deg, #5728DD 59.79%, rgba(87, 40, 221, 0.00) 100%);
		background-image: url(~@/assets/img/double/box-con-bg.png);
		background-size: 100% 100%;
		padding-top: 5px;
		@include Scenter;
		flex-direction: column;
		.box-rule{
			width: 347px;
			height: 60px;
			background-image: url(~@/assets/img/double/box-rule.png);
			background-size: 100% 100%;
			@include HScenter;
			font-size: 12px;
			white-space: pre-wrap;
		}
		.box-rate{
			@include Scenter;
			width: 354px;
			gap: 2px;
			.item{
				height: 30px;
				padding-left: 14px;
				position: relative;
				font-size: 12px;
				@include Scenter;
				&::before{
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					border: 1px solid transparent;
					width: 10px;
					height: 10px;
					border-radius: 50%;
				}
				&::after{
					content: '';
					position: absolute;
					left: 3px;
					top: 50%;
					transform: translateY(-50%);
					width: 6px;
					height: 6px;
					border-radius: 50%;
				}
				&:nth-child(1){
					&::before{
						border-color: #FFF61B;
					}
					&::after{
						background: #FFF61B;
					}
				}
				&:nth-child(2){
					&::before{
						border-color: #FF1B44;
					}
					&::after{
						background: #FF1B44;
					}
				}
				&:nth-child(3){
					&::before{
						border-color: #BD7AFF;
					}
					&::after{
						background: #BD7AFF;
					}
				}
				&:nth-child(4){
					&::before{
						border-color: #6398FF;
					}
					&::after{
						background: #6398FF;
					}
				}
				&:nth-child(5){
					&::before{
						border-color: #BABABA;
					}
					&::after{
						background: #BABABA;
					}
				}
			}
		}
		.box-list{
			position: relative;
			@include HScenter;
			flex-direction: column;
			width: 354px;
			height: 224px;
			.item{
				width: 70px;
				height: 52px;
				background: url(~@/assets/img/double/box-item-bg.png);
				background-size: 100% 100%;
				position: absolute;
				@include HScenter;
				opacity: 0.5;
				&.active{
					opacity: 1;
				}
				.img-con{
					width: 50px;
					height: 38px;
					background-size: auto 100%;
					background-repeat: no-repeat;
					background-position: center center;
					@for $i from 1 to 6{
            &.level#{$i}{
              background-image: url('~@/assets/img/double/double-box-'+$i+'.png');
            }
          }
				}
				&:nth-child(1){
					left: 0;
					top: 0;
				}
				&:nth-child(2){
					left: 71px;
					top: 0;
				}
				&:nth-child(3){
					left: 142px;
					top: 0;
				}
				&:nth-child(4){
					left: 213px;
					top: 0;
				}
				&:nth-child(5){
					left: 284px;
					top: 0;
				}
				&:nth-child(6){
					left: 284px;
					top: 58px;
				}
				&:nth-child(7){
					left: 284px;
					top: 116px;
				}
				&:nth-child(8){
					left: 284px;
					top: 174px;
				}
				&:nth-child(9){
					left: 213px;
					top: 174px;
				}
				&:nth-child(10){
					left: 142px;
					top: 174px;
				}
				&:nth-child(11){
					left: 71px;
					top: 174px;
				}
				&:nth-child(12){
					left: 0;
					top: 174px;
				}
				&:nth-child(13){
					left: 0;
					top: 116px;
				}
				&:nth-child(14){
					left: 0;
					top: 58px;
				}
			}
			.box-list-total{
				width: 199px;
				height: 50px;
				@include HScenter;
				gap: 2px;
				font-size: 12px;
				background-image: url(~@/assets/img/double/box-total.png);
				background-size: 100% 100%;
				flex-direction: column;
				margin-top: 4px;
				span{
					color: #FFF500;
					font-size: 14px;
				}
			}
			.box-open-con{
				@include Hcenter;
				gap: 2px;
				margin-top: 8px;
				.box-open-btn{
					width: 101px;
					height: 52px;
					background-size: 100% 100%;
					padding-top: 10px;
					font-size: 12px;
					text-align: center;
					position: relative;
					&::before{
						content: '';
						position: absolute;
						width: 55px;
						height: 15px;
						background-size: 100% 100%;
						top: 26px;
						left: 50%;
						transform: translateX(-50%);
					}
					&:nth-child(1){
						background-image: url(~@/assets/img/double/box-open-1.png);
						color: #FD541E;
						&::before{
							background-image: url(~@/assets/img/double/box-open-1-title.png);
						}
					}
					&:nth-child(2){
						background-image: url(~@/assets/img/double/box-open-5.png);
						&::before{
							background-image: url(~@/assets/img/double/box-open-5-title.png);
						}
					}
				}
			}
		}
		.box-bottom{
			@include HScenter;
			gap: 35px;
			margin-top: 12px;
			.link{
				text-decoration: underline;
				font-size: 12px;
			}
			.skip-con{
				@include HScenter;
				font-size: 12px;
				.van-switch{
					border: none;
					margin-left: 6px;
				}
				.van-switch--on{
					background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
				}
			}
		}
	}
	.box-link-rule{
		width: 347px;
		height: 70px;
		@include HScenter;
		margin: 0 auto 17px;
		background-image: url(~@/assets/img/double/box-link-rule.png);
		background-size: 100% 100%;
		padding: 0 12px;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		white-space: pre-wrap;
	}
	.box-link-list{
		display: flex;
		flex-wrap: wrap;
		gap: 4px;
		width: 356px;
		margin: 0 auto;
		.item{
			width: 116px;
			height: 191px;
			@include Scenter;
			flex-direction: column;
			padding-top: 16px;
			background-image: url(~@/assets/img/double/box-link-item.png);
			background-size: 100% 100%;
			white-space: nowrap;
			.name{
				font-size: 12px;
				max-width: 80%;
				@include wordhide;
			}
			.img-con{
				width: 77px;
				height: 77px;
			}
			.recharge{
				margin: 4px 0;
				font-size: 13px;
				line-height: 15px;
			}
			.progress{
				font-size: 13px;
				line-height: 15px;
				color: #FFF500;
			}
			.see{
				width: 69px;
				height: 28px;
				background-image: url(~@/assets/img/double/box-link-see.png);
				background-size: 100% 100%;
				margin-top: 8px;
			}
		}
	}
	.double-popup-con{
		width: 355px;
		height: 340px;
		background-image: url(~@/assets/img/double/popup-bg.png);
		background-size: 100% 100%;
		padding-top: 15px;
		position: relative;
		&.big{
			background-image: url(~@/assets/img/double/popup-bg-big.png);
			height: 381px;
		}
		.title{
			width: 289px;
			margin: 0 auto 15px;
			@include Hcenter;
			img{
				height: 32px;
			}
		}
		.close{
			width: 24px;
			height: 24px;
			background-image: url(~@/assets/img/double/popup-close.png);
			background-size: 100% 100%;
			position: absolute;
			cursor: pointer;
			right: 6px;
			top: 6px;
		}
		.table{
			width: 329px;
			margin: 0 auto;
			table{
				width: 100%;
				border-collapse: separate;
				border-spacing: 3px;
				tr{
					th{
						height: 34px;
						background: #3A3A48;
						white-space: nowrap;
					}
					td{
						height: 34px;
						background: #262633;
						text-align: center;
						font-size: 12px;
					}
				}
			}
		}
		.page{
			margin-top: 10px;
			.el-pagination__total{
				color: #fff;
			}
		}
		.ng-img{
			width: 165px;
			height: 158px;
			margin:  0 auto;
			img{
				width: 100%;
			}
		}
		.nd-name{
			text-align: center;
			margin-top: 10px;
			font-size: 16px;
		}
		.btn{
			margin: 20px auto 0;
			@include HScenter;
			cursor: pointer;
			img{
				height: 43px;
			}
		}
		.btn-con{
			@include Hcenter;
			gap: 10px;
			.btn{
				margin: 0;
				cursor: pointer;
			}
		}
	}
	.draw-list{
		@include HScenter;
		gap: 8px;
		flex-wrap: wrap;
		margin-bottom: 15px;
		&.one{
			margin-bottom: 25px;
			.item{
				width: 165px;
				.item-main{
					height: 158px;
					background-image: url(~@/assets/img/double/draw-item-big.png) !important;
					.alias{
						height: 24px;
						padding-right: 8px;
					}
					.img-con{
						width: 126px;
						height: 96px;
					}
				}
				.name{
					font-size: 16px;
					line-height: 16px;
					margin-top: 15px;
				}
			}
		}
		.item{
			width: 90px;
			.item-main{
				height: 100px;
				position: relative;
				background-image: url(~@/assets/img/double/draw-item.png);
				background-size: 100% 100%;
				@include HScenter;
				&.active{
					background-image: url(~@/assets/img/double/draw-item-active.png);
				}
				.alias{
					position: absolute;
					right: 0;
					top: 0;
					width: 58px;
					height: 18px;
					font-size: 12px;
					@include HScenter;
				}
				.img-con{
					width: 74px;
					height: 56px;
					background-size: auto 100%;
					background-repeat: no-repeat;
					background-position: center center;
					@for $i from 1 to 6{
            &.level#{$i}{
              background-image: url('~@/assets/img/double/double-box-'+$i+'.png');
            }
          }
				}
				.price{
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 24px;
					@include HScenter;
					font-size: 12px;
					.coins{
						background-image: url(~@/assets/img/coins-white1.svg);
						width: 16px;
						height: 16px;
						margin-right: 2px;
					}
				}
			}
			.name{
				font-size: 12px;
				line-height: 12px;
				margin-top: 6px;
				@include wordhide;
				text-align: center;
				
			}
		}
	}
	.mession-recharge-type{
		@include Hcenter;
		gap: 20px;
		margin: 0 auto 12px;
		.item{
			width: 140px;
			height: 39px;
			font-size: 16px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/double/mession-type.png);
			@include HScenter;
			cursor: pointer;
			&.active{
				background-image: url(~@/assets/img/double/mession-type-active.png);
				span{
					background: linear-gradient(180deg, #FFF 25.42%, #FF3164 100%);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}
	}
	.bar-img{
		width: 375px;
		margin: 12px auto 0;
	}
</style>
