<template>
	<div></div>
	<!-- <div style="display: none;" id="back_top" v-show="Show" @click="reach" ref="BackTop">
		T
	</div> -->
</template>
<script>
	export default {
		name: "back_top",
		data() {
			return {
				Show:false,//是否显示
				isTop:true,//是否触发
				scrollToptimer:null,//定时器
				scrollTop:0,//字面意思
			}
		},
		mounted() {
			//addEventListener事件监听
			window.addEventListener('scroll', () => {
				this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
				if(this.isTop == false){//回顶过程中触发清除定时器
					clearInterval(this.scrollToptimer)
				}
				this.isTop = false //触发为false
			})
		},
		methods: {
			reach() {
				 this.scrollToptimer = setInterval(() => {
				    let top = document.body.scrollTop || document.documentElement.scrollTop//次次减少
				    let speed = top / 4 //递减距离
				    if (document.body.scrollTop!=0) { //若scrollTop不为0(滑动过)
				        document.body.scrollTop -= speed //逐次过度到顶部
				    }			
				    if (top == 0) {//等于0(到达顶部)
				        clearInterval(this.scrollToptimer)//清除定时器
				    }
					this.isTop = true					
				}, 30)
			}
		},
		watch:{
			scrollTop(v){
				if (v > 700) { //>700显示
					this.Show = true
				}
				if (v < 700) {//<700隐藏
					this.Show = false
				}
			}
		}
	}
</script>
<style scoped lang="scss">
	#back_top {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		font-size: 26px;
		background: #4854c9;
		position: fixed;
		z-index: 1000;
		bottom: 100px;
		right: 10px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
</style>
