<template>
	<div class="welfare-main-con">
		<div class="welfare-nav-con">
			<div v-for="(item,index) in navList" :key="index" class="item" :class="{'active': navType==item.value}" @click="navType=item.value">{{ item.label }}</div>
		</div>
		<div class="welfare-scroll-con">
			<el-scrollbar style="height:100%;">
				<div class="welfare-red-con" v-if="navType==1">
					<div class="group">加群602593290,不定期发放红包福利</div>
					<input type="text" placeholder="请输入口令" v-model="Red_key">
					<div class="receive-btn" @click="Exactly('2')"></div>
				</div>
				
				<div class="welfare-recharge-con" v-else>
					<div class="list">
						<div class="item" v-for="(item,index) in Red_data" :key="index">
							<img src="~@/assets/img/red-mark.png" alt="" class="red-mark">
							<div class="title">
								<div class="name">{{ item.title }}</div>
							</div>
							<div class="time">活动时间：{{ item.start_time }}至{{ item.end_time }}</div>
							<div class="">剩余数量：<span style="color: red;">{{ item.remainder }}</span></div>
							<!-- <div class="award">
								<img src="~@/assets/img/welfare-recharge-award.png" alt="">
								奖励：50-100平台币
							</div> -->
							<div class="receive-btn" @click="Exactly('1', item.id)">领取</div>
						</div>
					</div>
				</div>
			</el-scrollbar>
		</div>
	</div>
</template>

<script>
	import { Red, Red_open, Red_open_key } from '@/network/api.js'
	export default{
		name:'WelfareRed',
		data(){
			return{
				navType: 1,
				navList: [
					{label: '口令红包',value: 1},
					{label: '其他红包',value: 2},
				],
				Red_data: [],//红包列表
				Red_key: ''
			}
		},
		created() {
			this.GetRed()
		},
		methods:{
			// 获取红包列表
			GetRed() {
				Red().then((res) => {
					// console.log(res.data.data)
					this.Red_data = res.data.data
				})
			},
			//开启红包
			Exactly(v, id) {
				// if (v == '1') {
				// 	Red_open(v, id).then((res) => {
				// 		this.$emit('getRed', res.data.data.bean)
				// 	})
				// } else {
				// 	Red_open(v, '', this.Red_key).then((res) => {
				// 		this.$emit('getRed', res.data.data.bean)
				// 	})
				// }
				if (v == '1') {
					Red_open(id).then((res) => {
						this.$emit('getRed', {...res.data.data, v: 1})
					})
				} else {
					Red_open_key(this.Red_key).then((res) => {
						this.$emit('getRed', {...res.data.data, v: 2, Red_key: this.Red_key})
					})
				}
			}	
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.welfare-red-con{
		margin-top: 20px;
		width: 369px;
		height: 428px;
		background: url(~@/assets/img/welfare-red-bg.png);
		background-size: 100% 100%;
		@include Scenter;
		flex-direction: column;
		padding-top: 91px;
		padding-left: 10px;
		.group{
			color: #FF7E07;
		}
		input{
			text-align: center;
			border-radius: 22px;
			background: rgba(0, 0, 0, 0.70);
			box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.43) inset;
			width: 206px;
			height: 40px;
			margin: 15px 0 66px;
		}
		.receive-btn{
			width: 76px;
			height: 76px;
			border-radius: 50%;
			cursor: pointer;
		}
	}
	.red-mark{
		position: absolute;
		height: 24px;
		top: 6px;
		right: 4px;
	}
</style>
