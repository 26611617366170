<template>
	<div class="welfare-scroll-con">
		<el-scrollbar style="height:100%;">
			<div class="welfare-home-list">
				<div class="item" v-for="(item,index) in hotList" :key="item.id" @click="hotSkip(item)">
					<div class="title">
						{{ item.title }}
					</div>
					<div class="main-con">
						<div class="img" :style="`background-image: url(${item.img_mobile})`"></div>
						<div class="content">
							<div class="scroll-con">
								<el-scrollbar style="height:100%;">
									<div class="word-content">{{ item.desc }}</div>
								</el-scrollbar>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
	import { hotReport } from '@/network/api.js'
	export default{
		props: {
			hotList: {
				type: Array
			}
		},
		name:'WelfareHome',
		data(){
			return{
			}
		},
		created() {
			
		},
		methods:{
			hotReport(id){
				let params = {
					id,
					type: 2
				}
				hotReport(params)
			},
			hotSkip(item){
				this.hotReport(item.id)
				if(item.jump_type==2){
					this.$router.push(item.jump_url)
				}else if(item.jump_type==3){
					window.open(item.jump_url)
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.welfare-scroll-con{
		height: 100%;
		.el-scrollbar__wrap{
			overflow-x: hidden;
		}
	}
	.welfare-home-list{
		.item{
			width: 354px;
			margin: 0 auto 20px;
			cursor: pointer;
			.title{
				background-image: url(~@/assets/img/welfare-hot-title-bg.png);
				background-size: 100% auto;
				background-repeat: no-repeat;
				background-position: 0 10px;
				text-align: center;
				height: 42px;
				font-size: 18px;
				line-height: 25px;
				padding-top: 3px;
				font-weight: bold;
			}
			.main-con{
				border: 1px solid #BCBCBC;
				background: #2A2A38;
				.img{
					height: 108px;
					background-position: center center;
					background-size: cover;
					background-image: url(~@/assets/img/welfare-hot-top.png);
				}
				.content{
					height: 92px;
					padding: 10px;
					.scroll-con{
						height: 100%;
						.el-scrollbar__wrap{
							overflow-x: hidden;
							.word-content{
								font-size: 12px;
								white-space: pre-wrap;
							}
						}
					}
				}
			}
		}
	}
	
</style>
