<template>
	<div id="Recharge">
		<div class="box-detail">
			<div class="icon-return" @click="$router.back()"></div>
			<div class="box-info">
				<div class="img-con">
					<img :src="DetailData.cover" alt="">
				</div>
				<div class="info">
					<div class="name">{{ DetailData.name }}</div>
					<div class="price">
						<div class="coins"></div>
						{{ DetailData.bean }}
					</div>
				</div>
			</div>
			<div class="detail-title">箱内物品</div>
		</div>
		<Goods :onlyBox="true" :DetailList="DetailList" :id="DetailData.id"></Goods>
	</div>
</template>

<script>
	import Goods from '@/components/PubOpen/Goods.vue';
	import {
		BoxDetail
	} from '@/network/api.js'
	export default {
		name: 'BoxDetail',
		components: {
			Goods
		},
		data() {
			return {
				DetailList:[],//箱内物品
				DetailData:{},//箱子信息
			}
		},
		created() {
			this.GetBoxDetail()
		},
		methods: {
			//宝箱详情
			GetBoxDetail(){
				BoxDetail(this.$route.query.id).then((res) => {
					// console.log(res.data.data)
					this.DetailData = res.data.data
					this.DetailList = res.data.data.box_awards.sort(function(a,b){
						return Number(b.bean) - Number(a.bean)
					})
				})
			},
		},
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	#Recharge{
		background: #15161B;
	}
	.box-detail{
		position: relative;
		@include HScenter;
		background-image: url(~@/assets/img/box-detail-bg.png);
		background-size: 100% 100%;
		height: 182px;
		margin-bottom: 10px;
		.icon-return{
			position: absolute;
			top: 10px;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
		}
		.box-info{
			@include HScenter;
			gap: 12px;
			.img-con{
				width: 90px;
				height: 90px;
			}
			.info{
				width: 100px;
				.name{
					@include wordhideRow(2);
					margin-bottom: 4px;
					color: #bbb;
				}
				.price{
					@include Scenter;
					.coins{
						width: 20px;
						height: 20px;
						background-image: url(~@/assets/img/coins-yellow.svg);
					}
				}
			}
		}
		.detail-title{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 30px;
			@include HScenter;
		}
	}
	
</style>
