<template>
  <div id="Warehouse">
    <div class="recharge-title">
			<div class="icon-return" @click="$router.back()"></div>
      账单明细
		</div>
    <div class="bill-type">
      <div class="item" :class="{'active': type==1}" @click="type=1;refresh();">获得</div>
      <div class="item" :class="{'active': type==2}" @click="type=2;refresh();">消耗</div>
    </div>

    <div class="Warehouse-box">
      <div class="content">
        <div class="row" style="color: #FFB800" v-if="type==1">
          <div class="col-3 content-item1">ID</div>
          <div class="col-3 content-item1">奖励金额</div>
          <div class="col-3 content-item1">奖励类型</div>
          <div class="col-3 content-item1">奖励时间</div>
        </div>
        <div class="row" style="color: #FFB800" v-else>
          <div class="col-3 content-item1">ID</div>
          <div class="col-3 content-item1">消耗金额</div>
          <div class="col-3 content-item1">消耗类型</div>
          <div class="col-3 content-item1">消耗时间</div>
        </div>
        <div v-for="(item,index) in list" :key="index" class="row content-item1">
          <div class="col-3">{{item.id}}</div>
          <div class="col-3">{{item.amount}}</div>
          <div class="col-3">{{item.biz_type_text}}</div>
          <div class="col-3">{{item.created_at}}</div>
        </div>
      </div>
      <el-pagination small background layout="prev, pager, next" @current-change="pageChange" :page-size="10" :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    beanLogs
  } from '@/network/api.js'
  import {
    mapMutations
  } from 'vuex'
  export default {
    name: 'Bill',
    data() {
      return {
        list:[],
        page:1,
        total:10,
        type: 1
      }
    },

    mounted() {
      this.beanLogs()
    },


    methods: {
      refresh(){
        this.page = 1;
        this.beanLogs()
      },
      beanLogs(){
        this.list = 0
        beanLogs(this.page, this.type).then(res =>{
          this.list = res.data.data.records
          this.total = Number(res.data.data.total)
          // this.TakingListper_page = Number(res.data.data.per_page)
        })
      },
      pageChange(val){
        this.page = val;
        this.beanLogs()
      },
    },

    watch: {

    }
  }
</script>

<style scoped lang="scss">
  @import "@/assets/css/base.scss";
  .recharge-title{
		position: relative;
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
		height: 40px;
		@include HScenter;
		.icon-return{
			position: absolute;
			top: 50%;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
		span{
			font-size: 12px;
			color: #FFB800;
		}
		&:before{
			content: '';
			position: absolute;
			left: 1px;
			top: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
		&:after{
			content: '';
			position: absolute;
			right: 1px;
			bottom: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
	}
  .row{
    display: flex;
  }
  .col-3{
    width: 25%;
  }
  .Warehouse-box {
    width: 100%;
    padding: 0.4rem .2rem;
    box-sizing: border-box;
    background-color: #131426;
    color: #fff;
    & .content{
      width: 100%;
      &-item1{
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(255,255,255,.3);
      }
    }

  }
  .bill-type{
    @include HScenter;
    gap: 60px;
    height: 54px;
    .item{
      cursor: pointer;
      &.active{
        color: #FFB800;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          height: 2px;
          left: 0;
          width: 100%;
          bottom: -6px;
          background: #FFB800;
        }
      }
    }
  }
</style>
