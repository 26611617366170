<template>
	<div id="Battle_item" :class="status | ProcessName" @click="ToCombat">
		<div class="Battle_item_T">
			<div class="process">
				<img :src="status | ProcessImg" alt="">
				<p :style="status | ProcessSty">{{status | Process}}</p>
			</div>
			<div class="box_list">
				<div class="box_item" v-for="(item,index) in game_arena_box" :key="index">
					<img :src="item.cover | ImgFilter" alt="">
				</div>
			</div>
			<div class="round">
				{{Detail.box_num}}回合
			</div>
		</div>
		<div class="Battle_item_B">
			<div class="player" :class="{'mode2': Detail.mode==2}">
				<div class="player_item" v-for="(item,index) in PlayerArr" :key="index">
					<div class="player_pic">
						<img v-if="item != null" :src="item.avatar | ImgFilter" alt="">
						<img v-else src="@/assets/images/Vs/Empty.png">
					</div>
				</div>
			</div>
			<div class="TotalPrice">
				<img src="@/assets/images/public/Gold.png" alt="">{{Detail.total_bean}}
			</div>
		</div>
	</div>
</template>

<script>
	import { ArenaDetail } from '@/network/api.js'
	import { mapState } from 'vuex'
	export default{
		name:'Battle_item',
		props:{
			id:{
				type:Number,
			}
		},
		data(){
			return{
				Detail:{},//详情
				game_arena_box:[],//箱子列表
				status:'',//进程
				PlayerArr:[],//玩家列表
			}
		},
		
		created(){
			// this.GetArenaDetail()
		},
		
		methods:{
			//详情
			GetArenaDetail(){
				ArenaDetail(this.id).then((res) => {
					// console.log(res.data.data)
					this.Detail = res.data.data
					this.game_arena_box = res.data.data.game_arena_box
					this.status = res.data.data.status
					this.SeatScreening(res.data.data.user_num,res.data.data.game_arena_player)
				})
			},
			
			//再次调用
			GetArenaDetailT(){
				ArenaDetail(this.id).then((res) => {
					this.Detail = res.data.data
					this.game_arena_box = res.data.data.game_arena_box
					if(res.data.data.status == 2){
						this.ProcessDelay()
					}else{
						this.status = res.data.data.status
					}
					this.SeatScreening(res.data.data.user_num,res.data.data.game_arena_player)
				})
			},
			
			//确定座位顺序
			SeatScreening(num,arr){				
				let A_Arr = []
				for(let i = 0; i < num; i++){
					A_Arr[i] = null
				}
				
				for(let k = 0; k < arr.length; k++){
					A_Arr[arr[k].seat] = arr[k]
				}
				// console.log(A_Arr)
				this.PlayerArr = A_Arr

			},
			
			// 进程延迟(增加进行中状态)
			ProcessDelay(){
				this.status = 1 //进行
				setTimeout(()=>{
					this.status = 2 //结束
				},9000 * this.game_arena_box.length)
			},
			
			//路由跳转详情
			ToCombat(){
				this.$router.push({
					path:'/Combat',
					query:{
						id:this.id,
						type:this.status
					}
				})
			}
			
		},
		
		computed:{
			...mapState([
				'join',
				// 'end'
			])
		},
		
		watch:{
			join(v){
				if(v.game_arena_id == this.id){
					// console.log(v)
					this.GetArenaDetailT()
				}
			}
		},
		
		filters:{
			//class过滤
			ProcessName(v){//状态 0=>等待中 1=>进行中 2=>以结束
				switch (v) {
					case 0:
						return 'etc'
						break;
					case 1:
						return 'conduct'
						break;
					case 2:
						return 'end'
						break;
					default:
				}
			},
			
			//进程文本过滤
			Process(v){
				switch (v) {
					case 0:
						return '等待中'
						break;
					case 1:
						return '进行中'
						break;
					case 2:
						return '已结束'
						break;
					default:
				}
			},
			
			//进程字体颜色过滤
			ProcessSty(v){
				switch (v) {
					case 0:
						return 'color: #75cd66'
						break;
					case 1:
						return 'color: #d42d4f;'
						break;
					case 2:
						return 'color: #ffffff;'
						break;
					default:
				}
			},
			
			//进程图标颜色过滤
			ProcessImg(v){
				switch (v) {
					case 0:
						return require('@/assets/images/Vs/etc.png')
						break;
					case 1:
						return require('@/assets/images/Vs/conduct.png')
						break;
					case 2:
						return require('@/assets/images/Vs/end.png')
						break;
					default:
				}
			}
		},
	}
</script>

<style lang="scss">
	#Battle_item{
		width: 100%;
		height: 80px;
		border-radius: 10px;
		overflow: hidden;
		margin-bottom: 20px;
		&.etc{
			background: url(../../../../assets/images/Vs/etc_back.png) no-repeat center;
			background-size: 100% 100%;
		}
		&.conduct{
			background: url(../../../../assets/images/Vs/conduct_back.png) no-repeat center;
			background-size: 100% 100%;
		}
		&.end{
			background: url(../../../../assets/images/Vs/end_back.png) no-repeat center;
			background-size: 100% 100%;
		}
		.Battle_item_T{
			width: 100%;
			height: 40px;
			background: rgba($color: #000000, $alpha: .2);
			border-bottom: 1px solid rgba($color: #000000, $alpha: .3);
			padding: 0 10px;
			box-sizing: border-box;
			display: flex;
			.process{
				width: 60px;
				height: 100%;
				display: flex;
				align-items: center;
				margin-right: 5px;
				img{
					width: 16px;
					vertical-align: middle;
					margin-right: 3px;
				}
				p{
					font-size: 12px;
					font-weight: bold;
				}
			}
			.box_list{
				width: 200px;
				height: 100%;
				display: flex;
				.box_item{
					width: 33px;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					img{
						max-width: 100%;
						max-height: 100%;
						vertical-align: middle;
					}
				}
				
			}
			.round{
				width: 40px;
				height: 100%;
				margin-left: 5px;
				line-height: 40px;
				text-align: center;
				color: #fff;
				font-size: 14px;
			}
		}
		.Battle_item_B{
			width: 100%;
			height: 40px;
			padding: 0 10px;
			display: flex;
			box-sizing: border-box;
			.player{
				width: 220px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				&.mode2{
					.player_item{
						&:nth-child(1),&:nth-child(3){
							&:after{
								background: none;
								width: 0px;
							}
						}
					}
				}
				.player_item{
					height: 32px;
					display: flex;
					align-items: center;
					&::after{
						display: block;
						content: "";
						width: 20px;
						height: 20px;
						background: url(../../../../assets/images/Vs/vs.png) no-repeat center;
						background-size: contain;
						margin: 0 5px;
					}
					&:last-child::after{
						display: none;
					}
					.player_pic{
						width: 30px;
						height: 30px;
						border-radius: 50%;
						overflow: hidden;
						img{
							width: 100%;
							height: 100%;
							vertical-align: middle;
						}
					}
					
				}
			}
			.TotalPrice{
				width: 80px;
				height: 100%;
				margin-left: 5px;
				text-align: center;
				line-height: 40px;
				font-size: 14px;
				color: #75cd66;
				font-weight: bold;
				img{
					width: 18px;
					height: 18px;
					vertical-align: middle;
					margin-bottom: 2px;
				}
			}
		}
	}
</style>
