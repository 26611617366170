<template>
  <div id="Warehouse">
    <div class="recharge-title">
			<div class="icon-return" @click="$router.back()"></div>
      奖励明细
		</div>
    <div class="Warehouse-box">
        <div class="content">
          <div class="row" style="color: #FFB800">
            <div class="col-3 content-item1">ID</div>
            <div class="col-3 content-item1">奖励金额</div>
            <div class="col-3 content-item1">奖励类型</div>
            <div class="col-3 content-item1">奖励时间</div>
          </div>
          <div v-for="(item,index) in vipList" :key="index" class="row content-item1">
            <div class="col-3">{{index+1}}</div>
            <div class="col-3">{{item.bean}}</div>
            <div class="col-3">{{item.type_name}}</div>
            <div class="col-3">{{item.created_at}}</div>
          </div>
        </div>
      <el-pagination background layout="prev, pager, next" @current-change="PickChange" :page-size="TakingListper_page"
                     :total="TakingListPageNum">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {

    vipMe
  } from '@/network/api.js'
  import {
    mapMutations
  } from 'vuex'
  export default {
    name: '',
    data() {
      return {
        vipList:[],
        TakingListPageNum:0,
        TakingListper_page:0
      }
    },

    mounted() {
      this.init()
    },


    methods: {
      init(){
        vipMe(1).then(res =>{
          this.vipList = res.data.data.data
          this.TakingListPageNum = Number(res.data.data.total)
          this.TakingListper_page = Number(res.data.data.per_page)
        })
      },
      PickChange(val){
        this.PickShow = true
        vipMe(val).then(res =>{
          this.vipList = res.data.data.data
          this.TakingListPageNum = Number(res.data.data.total)
          this.TakingListper_page = Number(res.data.data.per_page)
        })
      },

      //个人信息
      GetPersonalInfo() {
        PersonalInfo().then((res) => {
          this.User(res.data.message)
        })
      },


    },

    watch: {

    }
  }
</script>

<style scoped lang="scss">
  @import "@/assets/css/base.scss";
  .recharge-title{
		position: relative;
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
		height: 40px;
		@include HScenter;
		.icon-return{
			position: absolute;
			top: 50%;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
		span{
			font-size: 12px;
			color: #FFB800;
		}
		&:before{
			content: '';
			position: absolute;
			left: 1px;
			top: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
		&:after{
			content: '';
			position: absolute;
			right: 1px;
			bottom: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
	}
.row{
  display: flex;
}
.col-3{
  width: 25%;
}
  .Warehouse-box {
    width: 100%;
    padding: 0.4rem .2rem;
    box-sizing: border-box;
    background-color: #131426;
    color: #fff;
    & .content{
      width: 100%;
      &-item1{
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(255,255,255,.3);
      }
    }

  }
</style>
