<template>
	<div class="Me">
		<div class="me-top">
			<div class="Portrait_box">
				<van-uploader :after-read="afterRead">
					<div class="Portrait">
						<img :src="User.avatar" alt="">
					</div>
				</van-uploader>
				<div class="user-info-con">
					<div class="name">{{ User.name }}</div>
					<div class="price">
						<div class="coins"></div>
						{{ User.bean || 0}}
					</div>
				</div>
				<div class="btn" @click="$router.push('/Recharge')">充值</div>
			</div>
			<div class="fast-list">
				<div class="item" @click="$router.push('/Warehouse')">
					<img src="~@/assets/img/user-icon1.png" alt="">
					<span>个人背包</span>
				</div>
				<div class="item" @click="$router.push('/Extension')">
					<img src="~@/assets/img/user-icon2.png" alt="">
					<span>会员推广</span>
				</div>
				<div class="item" @click="$router.push('/Bill')">
					<img src="~@/assets/img/user-icon3.png" alt="">
					<span>账单明细</span>
				</div>
				<div class="item" :class="{'hasRed': User.has_message}" @click="$router.push('/Message')">
					<img src="~@/assets/img/user-icon4.png" alt="">
					<span>消息中心</span>
				</div>
				<div class="item" @click="$router.push('/Help')">
					<img src="~@/assets/img/user-icon5.png" alt="">
					<span>帮助中心</span>
				</div>
			</div>
		</div>
		
		<van-field center clearable label="昵称" v-model="User.name" :readonly="!editName" ref="name">
			<template #button>
				<van-button v-if="!editName" size="small" type="info" @click="focusName">修改</van-button>
				<van-button v-else size="small" type="info" @click="GetSetName">保存</van-button>
			</template>
		</van-field>
		<van-field label="用户id" :value="User.id" readonly />
		<van-field label="手机号" :value="User.mobile" readonly />
		<van-field center label="密码" value="******" readonly>
			<template #button>
				<van-button size="small" type="info" @click="$router.push('/Changepass')">修改</van-button>
			</template>
		</van-field>
		<!-- <van-field label="Steam_id" :value="User.steam_id" readonly /> -->
		<van-field center clearable label="Steam链接" v-model="User.steam" :readonly="User.steam?true:false || !editSteam" ref="steam">
			<template v-if="!User.steam" #button>
				<van-button v-if="!editSteam" size="small" type="info" @click="focusSteam">绑定</van-button>
				<van-button v-else size="small" type="info" @click="GetSetSteam_url">保存</van-button>
			</template>
		</van-field>
		
		<van-field center label="邀请码" v-model="User.invite_code" disabled>
			<!-- <template #button>
				<van-button size="small" type="info" @click="GetSetInvite_code">修改</van-button>
			</template> -->
		</van-field>
		<van-field center label="绑定邀请码" v-model="inviter_code_bind" :readonly="User.inviter_id!=0 || !editInviter_code_bind" ref="code_bind">
			<template #button v-if="User.inviter_id==0">
				<van-button v-if="!editInviter_code_bind" size="small" type="info" @click="focusCodeBind">绑定</van-button>
				<van-button v-else size="small" type="info" @click="GetSetInvite_code">保存</van-button>
			</template>
		</van-field>
		<div class="desc-tit">(绑定邀请码只可修改一次)</div>
		<van-field center label="实名认证" v-model="realShow" readonly>
			<template #button v-if="User.is_real != 1">
				<van-button size="small" type="info" @click="openReal=true">去认证</van-button>
			</template>
		</van-field>
		<div class="desc-tit" style="color: #FFB800;">(认证后可获得{{ publicConfig.realname_award }}C币奖励)</div>
		
		<div class="logout-btn" @click="BackUp">退出登录</div>
		<van-overlay :show="openReal">
			<div class="wrapper">
				<div class="real-con">
					<div class="close" @click="openReal=false"></div>
					<div class="real-main" v-show="realState==2">
						<div class="row">
							<div class="label">请输入您的名字</div>
							<div class="input">
								<input type="text" v-model="name">
							</div>
						</div>
						<div class="row">
							<div class="label">请输入您的身份证</div>
							<div class="input">
								<input type="text" v-model="id_num">
							</div>
						</div>
						<div class="row">
							<div class="label">请输入验证码</div>
							<div class="input">
								<input type="text" v-model="verify">
								<van-button class="code" size="small" type="primary" v-show="VerCodeShow == false" @click='VerCodeSend(3)'>发送验证码</van-button>
								<van-button class="code disabled" size="small" type="primary" v-show="VerCodeShow == true">
									<van-count-down millisecond :time="Time" format="ss" :auto-start="false" @finish="finish" ref="countDown" />
								</van-button>
							</div>
						</div>
						<div class="btn-con">
							<div class="btn big" @click="realname">提交认证</div>
						</div>
					</div>
					<div class="real-main" v-show="realState==1">
						<div class="word">
							本网站禁止未成年人使用，请完成<br />实名身份认证，完成认证奖励
						</div>
						<div class="price">
							<div class="coins"></div>{{ publicConfig.realname_award }}
						</div>
						<div class="btn-con">
							<div class="btn cancel" @click="openReal=false">稍后认证</div>
							<div class="btn" @click="realState=2">立即认证</div>
						</div>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	import {
		ImageBase64,
		SetAvatar,
		PersonalInfo,
		SetSteam_url,
		SetName,
		SetInvite_code,
		set_inviter,
		realname,
		PostSendSms
	} from '@/network/api.js'
	export default {
		name: 'Me',
		data() {
			return {
				inviter_code_bind: '',
				openReal: false,
				name: '',
				id_num: '',
				verify: '',
				realState: 1,
				VerCodeShow: false, //验证码/倒计时
				Time: 60 * 1000, //倒计时时间
				editName: false,
				editInviter_code_bind: false,
				editSteam: false
			}
		},
		created (){
			this.GetPersonalInfo()
		},
		methods: {
			...mapActions([
				'Refresh'
			]),
			focusSteam(){
				this.editSteam=true;
				this.$nextTick(() => {
					this.$refs.steam.focus();
				});
			},
			focusName(){
				this.editName=true;
				this.$nextTick(() => {
					this.$refs.name.focus();
				});
			},
			focusCodeBind(){
				this.editInviter_code_bind=true;
				this.$nextTick(() => {
					this.$refs.code_bind.focus();
				});
			},
			//验证码发送
			VerCodeSend() {
				this.VerCodeShow = true
				this.$refs.countDown.start();
				PostSendSms(this.User.mobile, '4').then((res) => {
					this.$notify({
						type: 'success',
						message: '发送成功'
					})
				})
			},
			//重置倒计时
			finish() {
				this.VerCodeShow = false
				this.$refs.countDown.reset();
			},
			realname(){
				let params = {
					name: this.name,
					id_num: this.id_num,
					verify: this.verify
				}
				realname(params).then((res) => {
					this.$notify({
						type: 'success',
						message: '认证成功'
					})
					this.openReal = false
					this.GetPersonalInfo()
				})
			},
			//修改头像
			afterRead(file) {
				// console.log(file)
				ImageBase64(file.content).then((res) => {
					// console.log(res.data.data.url)
					SetAvatar(res.data.data.url).then((res) => {
						// console.log(res)
						this.$notify({
							type: 'success',
							message: '修改成功'
						})
						this.GetPersonalInfo()
					})
				})
			},

			//修改steam链接
			GetSetSteam_url(){
				SetSteam_url(this.User.steam).then((res) => {
					console.log(res)
					this.editSteam = false
					this.$notify({
						type: 'success',
						message: '修改成功'
					})
					this.GetPersonalInfo()
				})
			},

			//修改昵称
			GetSetName(){
				SetName(this.User.name).then((res) => {
					// console.log(res)
					this.editName = false
					this.$notify({
						type: 'success',
						message: '修改成功'
					})
					this.GetPersonalInfo()
				})
			},

			//修改推广码
			GetSetInvite_code(){
				set_inviter(this.inviter_code_bind).then((res) => {
					// console.log(res)
					this.editInviter_code_bind = false
					this.$notify({
						type: 'success',
						message: '绑定成功'
					})
					this.GetPersonalInfo()
				})
			},
			

			//更新个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.inviter_code_bind = res.data.message.inviter_id!=0 ? res.data.message.inviter.invite_code : ''
					this.PostUser(res.data.message)
				})
			},
			//退出登录
			BackUp() {
				this.PostIndividualsShow(false)
				localStorage.clear()
				sessionStorage.clear()
				this.$router.push('/')
			},
			...mapMutations([
				'PostUser',
				'PostIndividualsShow'
			])
		},
		computed: {
			...mapState([
				'User',
				'publicConfig'
			]),
			realShow(){
				return this.User.is_real==1 ? '已认证' : '未认证'
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.Me {
		width: 100%;
		padding:10px 10px 40px;
		box-sizing: border-box;

		.Portrait_box {
			@include Scenter;
			padding: 14px;
			gap: 15px;
			height: 106px;
			
			.Portrait {
				width: 64px;
				height: 64px;
				border-radius: 50%;
				// overflow: hidden;
				margin: 10px auto;
				border-radius: 85px;
				background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
				padding: 2px;
				position: relative;
				&::after{
					content: '';
					position: absolute;
					z-index: 2;
					background: url(~@/assets/img/photo-edit.png);
					background-size: 100% 100%;
					width: 17px;
					height: 17px;
					bottom: 0;
					right: 0;
				}
				img {
					width: 100%;
					height: 100%;
					vertical-align: middle;
					border-radius: 50%;
				}
			}
			.user-info-con{
				flex: 1;
				overflow: hidden;
				.name{
					@include wordhide;
					width: 100%;
					margin-bottom: 10px;
				}
				.price{
					@include Scenter;
					.coins{
						width: 14px;
						height: 14px;
						background-image: url(~@/assets/img/coins-white1.svg);
					}
				}
			}
			.btn{
				width: 74px;
				height: 34px;
				background: var(--LOGO, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
				@include HScenter;
				cursor: pointer;
			}
		}

		.van-cell {
			background: none;
			margin-top: 14px;
			background: #1D1D28;
			padding: 0 0 0 16px;
			.van-field__label {
				width: 60px;
				color: #bbb;
				font-size: 12px;
				line-height: 42px;
			}

			.van-field__control {
				color: #fff;
				height: 42px;
				padding-right: 16px;
			}
			.van-button{
				width: 76px;
				height: 42px;
				border-radius: 0;
			}
		}

		.van-cell::after {
			display: none;
		}
	}
	.desc-tit{
		margin-top: 10px;
		font-size: 10px;
	}
	.logout-btn{
		background: #1D1D28;
		height: 42px;
		@include HScenter;
		margin-top: 28px;
	}
	.real-con{
		width: 364px;
		height: 241px;
		flex-shrink: 0;
		padding-top: 36px;
		position: relative;
		background-size: 100% 100%;
		background-image: url(~@/assets/img/realname-bg.png);
		.close{
			width: 24px;
			height: 24px;
			position: absolute;
			top: 3px;
			right: 12px;
			background-image: url(~@/assets/img/rule-close.png);
			background-size: 100% 100%;
			cursor: pointer;
		}
		.real-main{
			height: 198px;
			@include HScenter;
			flex-direction: column;
		}
		.row{
			@include Scenter;
			margin: 0 0 16px;
			.label{
				width: 120px;
				font-weight: bold;
				color: #000;
			}
			.input{
				width: 170px;
				height: 22px;
				display: flex;
				border-radius: 4px;
				background: #CCCCD3;
				box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) inset;
				input{
					height: 100%;
					color: #000;
					width: 100%;
					padding: 0 10px;
				}
				.code{
					font-size: 12px;
					border-radius: 4px;
					background: #E3B66D;
					box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) inset;
					width: 76px;
					height: 100%;
					@include HScenter;
					flex-shrink: 0;
					color: #000;
					cursor: pointer;
					border: none;
					&.disabled{
						filter: grayscale(1);
					}
				}
			}
			
		}
		.btn-con{
			margin-top: 10px;
			display: flex;
			gap: 33px;
			.btn{
				background-size: 100% 100%;
				color: #000;
				width: 98px;
				height: 36px;
				background-image: url(~@/assets/img/realname-btn.png);
				@include HScenter;
				cursor: pointer;
				&.big{
					width: 192px;
					height: 36px;
					background-image: url(~@/assets/img/realname-btn-big.png);
				}
				&.cancel{
					background-image: url(~@/assets/img/realname-btn-cancel.png);
				}
			}
		}
		.price{
			color: #000;
			margin-top: 12px;
		}
		.word{
			color: #000;
		}
	}
	.van-field__label{
		white-space: nowrap;
	}
	.me-top{
		background: linear-gradient(180deg, rgba(29, 29, 40, 0.00) 49.9%, #1D1D28 100%);
		margin: 0 -10px;
	}
	.fast-list{
		display: flex;
		.item{
			flex: 1;
			@include HScenter;
			flex-direction: column;
			height: 56px;
			position: relative;
			cursor: pointer;
			&.hasRed{
				&:after{
					content: '';
					position: absolute;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					right: 20px;
					top: 10px;
					background: red;
				}
			}
			&::before{
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 1px;
				background: var(--LOGO, linear-gradient(180deg, rgba(0,0,0,0) 20%, rgba(255,255,255,0.3) 50%, rgba(0,0,0,0) 80%));
			}
			&:first-child{
				&::before{
					display: none;
				}
			}
			img{
				width: 30px;
				height: 30px;
				margin-bottom: 4px;
			}
			span{
				color: #999;
				font-size: 12px;
			}
		}
	}
	.van-button__text{
		white-space: nowrap;
	}
</style>
