<template>
	<div class="container roll-con">
		<div class="roll-top">
			<!-- <div class="game-title">ROLL福利</div> -->
			<!-- <div class="create-btn">创建ROLL房</div> -->
			<div class="roll-search-input">
				<input type="text" placeholder="ROLL房搜索" v-model="keyword">
			</div>
			<div class="roll-nav">
				<!-- <div class="item" v-if="$store.state.User.anchor == 1" :class="{'active' : Status == '4'}" @click="auditRolls('4')">审核中</div> -->
				<div class="item" :class="{'active' : Status == '1'}" @click="ChangeRolls('1')">进行中</div>
				<div class="item" :class="{'active' : Status == '2'}" @click="ChangeRolls('2')">已结束</div>
				<div class="item" :class="{'active' : Status == '3'}" @click="ChangeRolls('3')">我参加的</div>
				<div class="item" @click="G_Show=true">玩法规则</div>
			</div>
		</div>
		<div class="roll-scroll-con">
			<el-scrollbar style="height:100%;">
				<div class="roll-list">
					<div class="roll-item"  v-for="(item) in searchList" :key="item.id">
						<div class="disable-front" v-if="item.status == 2" @click="toRoom(item.id)">
							<img v-if="item.is_cancel==1" src="~@/assets/img/roll-cancel-btn.png" alt="">
							<img v-else src="~@/assets/img/roll-disabled-btn.png" alt="">
						</div>
						<div class="roll-item-head">
							<div class="photo" :class="{'official': item.type==1}">
								<img v-if="item.type==1" src="~@/assets/img/roll-official-photo.png" alt="">
								<img v-else :src="item.user.avatar" alt="">
							</div>
							<div class="info-con">
								<div class="name">{{ item.name }}</div>
								<div class="time-con">
									开奖倒计时：
									<div class="time">{{ item.timeObj.day }}</div>
									天
									<div class="time">{{ item.timeObj.hour }}</div>
									时
									<div class="time">{{ item.timeObj.min }}</div>
									分
								</div>
							</div>
						</div>
						<!-- <div class="roll-item-head">
							<div class="official-tip" v-if="item.type == 1">官方</div>
							<img src="~@/assets/img/roll-head-bg.png" alt="">
							<div class="time">ROLL时间：{{ item.end_time }}</div>
						</div> -->
						<div class="roll-item-con">
							<!-- <div class="roll-item-title">
								<img src="~@/assets/img/roll-item-title.png" alt="">
								<div class="name">{{ item.name }}</div>
							</div> -->
							<div class="roll-box-list">
								<div class="roll-box-item" v-for="(value) in item.list.slice(0,3)" :key="value.id">
									<div class="img-con">
										<img v-if="value.gunType!='bean'" :src="value.cover" alt="">
										<img v-else src="@/assets/img/jinbi1.png" alt="">
									</div>
								</div>
							</div>
							<div class="total-con">
								<div class="num">件数：<span>{{item.box_record_count}}</span></div>
								<div class="num">人数：<span>{{item.join_number}}</span></div>
								<div class="num right">
									奖池
									<div class="price">
										<div class="coins"></div>
										{{ item.award_bean }}
									</div>
								</div>
							</div>
							<div class="roll-item-open" :class="[item.status == 1 ? 'active' : item.status == 2 ? 'disabled' : 'check', item.has_join_room ? 'joined':'']" @click="toRoom(item.id)">
								<span v-if="item.status == 2">查看详情</span>
								<span v-else-if="item.status == 1">
									{{ item.has_join_room ? '已参与' : '进入房间' }}
								</span>
								<span v-else>房间待审核</span>
							</div>
						</div>
					</div>
				</div>
			</el-scrollbar>
		</div>
		<van-overlay :show="G_Show">
			<div class="wrapper">
				<div class="rule-popup-con">
					<div class="rule-popup-header">
						ROLL规则
						<div class="close" @click="G_Show = false"></div>
					</div>
					<div class="rule-popup-main">
						<div class="rule-popup-content" v-html="publicConfig.desc_room"></div>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {
		Rooms,
		UserRooms,
	rollPending
	} from '@/network/api.js'
	import {mapState} from 'vuex'
	export default {
		name: 'Roll',
		data() {
			return {
				RoomList: [], //房间列表
				Status: '1', //房间状态
				G_Show: false,
				keyword: ''
			}
		},
		computed: {
			...mapState(['publicConfig']),
			searchList(){
				if(this.keyword == ''){
					return this.RoomList;
				}else{
					return this.RoomList.filter(item=>{
						return item.name.indexOf(this.keyword)>-1
					})
				}
			}
		},
		filters: {
			StyChoice(val) {
				// console.log(val)
				if (val == '1') {
					return 'border-bottom-color:#e6ba1c;background-image: linear-gradient(to top, rgba(230, 186, 28,.5), transparent)'
				} else if (val == '2') {
					return 'border-bottom-color:#d32ce6;background-image: linear-gradient(to top, rgba(211, 44, 230,.5), transparent)'
				} else if (val == '3') {
					return 'border-bottom-color:#834fff;background-image: linear-gradient(to top, rgba(131, 79, 255,.5), transparent)'
				} else if (val == '4') {
					return 'border-bottom-color:#4b69ff;background-image: linear-gradient(to top, rgba(75, 105, 255,.5), transparent)'
				} else {
					return 'border-bottom-color:#bbbbbb;background-image: linear-gradient(to top, rgba(187, 187, 187,.5), transparent)'
				}
			}
		},
		created() {
			this.GetRooms()
		},
	mounted() {
		console.log(this.$store.state.User.anchor)
	},

		methods: {
			getTimeObj(end) {
				// 将字符串转换为日期对象
				const startTime = new Date();
				const	endTime = new Date(end);

				// 计算时间差的毫秒数
				const diff = endTime - startTime;
				let last = diff
				if(diff<0){
					return {
						day: 0,
						hour: 0,
						min: 0
					}
				}

				// 计算天、时、分
				const day = Math.floor(diff / (24 * 3600 * 1000));
				const	hour = Math.floor((diff % (24 * 3600 * 1000)) / (3600 * 1000));
				const min = Math.floor(((diff % (24 * 3600 * 1000)) % (3600 * 1000)) / (60 * 1000));

				return {
					day,
					hour,
					min
				};
			},
			// 主播审核列表
			auditRolls(v){
				this.Status = v
				rollPending().then(res => {
					res.data.data.data.map(item=>{
						item.bean_awards.map(item1=>item1.gunType='bean')
						item.list = [...item.box_records, ...item.bean_awards]
						item.timeObj = this.getTimeObj(item.end_time)
					})
					this.RoomList = res.data.data.data
				})

			},
			//获取房间列表(初始)
			GetRooms() {
				Rooms('1', this.Status).then((res) => {
					// console.log(res.data.data.data)
					res.data.data.data.map(item=>{
						item.bean_awards.map(item1=>item1.gunType='bean')
						item.list = [...item.box_records, ...item.bean_awards]
						item.timeObj = this.getTimeObj(item.end_time)
					})
					let arrofficial = res.data.data.data.filter(item=>{
						return item.type == 1
					}).sort((a,b)=>{
						return b.sort-a.sort
					})
					let arrpersonal = res.data.data.data.filter(item=>{
						return item.type != 1
					}).sort((a,b)=>{
						return b.sort-a.sort
					})
					this.RoomList = [...arrofficial,...arrpersonal]
				})
			},

			//参加房间记录(我参与的)
			GetUserRooms() {
				UserRooms().then((res) => {
					res.data.data.data.map(item=>{
						item.bean_awards.map(item1=>item1.gunType='bean')
						item.list = [...item.box_records, ...item.bean_awards]
						item.timeObj = this.getTimeObj(item.end_time)
					})
					let arrofficial = res.data.data.data.filter(item=>{
						return item.type == 1
					}).sort((a,b)=>{
						return b.sort-a.sort
					})
					let arrpersonal = res.data.data.data.filter(item=>{
						return item.type != 1
					}).sort((a,b)=>{
						return b.sort-a.sort
					})
					this.RoomList = [...arrofficial,...arrpersonal]
				})
			},

			//改变房间列表
			ChangeRolls(v) {
				this.Status = v
				switch (v) {
					case '1':
						this.GetRooms()
						break;
					case '2':
						this.GetRooms()
						break;
					default:
						this.GetUserRooms()
				}
			},

			//路由跳转房间详情
			toRoom(id){
				// console.log(id)
				this.$router.push({
					path:'/room',
					query:{
						id:id
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/css/base.scss";
	.roll-con{
		display: flex;
		align-items: center;
		flex-direction: column;
		height: calc(100vh - 54px);
	}
	.create-btn{
		width: 288px;
		height: 32px;
		@include HScenter;
		border: 1px solid #DDD;
		margin: 0 auto;
	}
	.roll-search-input{
		width: 342px;
		height: 32px;
		background: linear-gradient(180deg, #23232B 0%, #223 100%);
		margin: 0 auto;
		input{
			width: 100%;
			height: 100%;
			text-align: center;
		}
	}
	.roll-nav{
		display: flex;
		justify-content: center;
		justify-content: space-between;
		width: 342px;
		margin: 12px auto;
		
		.item{
			background: linear-gradient(180deg, #23232B 0%, #223 100%);
			width: 78px;
			height: 34px;
			@include HScenter;
			cursor: pointer;
			&.active{
				background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));;
			}
		}
	}
	.roll-scroll-con{
		flex: 1;
		overflow: hidden;
		.el-scrollbar__wrap{
			overflow-x: hidden;
		}
	}
	.roll-list{
		.roll-item{
			width: 340px;
			height: 254px;
			margin: 0 auto 10px;
			@include Scenter;
			flex-direction: column;
			padding: 8px 0 0;
			background: url(~@/assets/img/roll-item-bg.png);
			background-size: 100% 100%;
			position: relative;
			.disable-front{
				position: absolute;
				z-index: 5;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba($color: #000000, $alpha: 0.6);
				@include HScenter;
				img{
					width: 188px;
				}
			}
			.roll-item-head{
				position: relative;
				margin-bottom: 22px;
				width: 315px;
				height: 68px;
				@include Hcenter;
				padding-top: 7px;
				.photo{
					width: 36px;
					height: 36px;
					border-radius: 50%;
					border: 1px solid #8D8D8D;
					// overflow: hidden;
					position: relative;
					img{
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}
					&.official{
						&::before{
							background-image: url(~@/assets/img/roll-mark-official.png);
						}
					}
					&::before{
						content: '';
						position: absolute;
						left: 50%;
						bottom: -4px;
						z-index: 2;
						width: 41px;
						height: 11px;
						background-image: url(~@/assets/img/roll-mark-user.png);
						background-size: 100% 100%;
						transform: translateX(-50%);
					}
				}
				.info-con{
					width: 223px;
					overflow: hidden;
					margin-left: 13px;
					.name{
						@include wordhide;
						font-size: 16px;
						margin-bottom: 7px;
					}
					.time-con{
						@include Scenter;
						gap: 6px;
						font-size: 12px;
						.time{
							font-size: 14px;
							width: 26px;
							height: 24px;
							background: #3B3B44;
							border-radius: 4px;
							@include HScenter;
						}
					}
				}
			}
			.roll-item-con{
				width: 293px;
				margin: 0 auto;
				.roll-item-title{
					@include Scenter;
					margin-bottom: 34px;
					img{
						width: 24px;
						height: 24px;
						margin-right: 10px;
					}
					.name{
						font-size: 18px;
						line-height: 24px;
						@include wordhide;
					}
				}
				.roll-box-list{
					display: flex;
					gap: 10px;
					.roll-box-item{
						width: 90px;
						height: 67px;
						@include HScenter;
						background: linear-gradient(180deg, #2E2328 0%, #1D1D23 100%);
						border-bottom: 2px solid #561C20;
						.img-con{
							width: 71px;
							height: 48px;
						}
					}
				}
				.total-con{
					@include Scenter;
					margin-top: 8px;
					.num{
						@include Scenter;
						color: #bbb;
						margin-right: 10px;
						&.right{
							margin-left: auto;
							margin-right: 0;
						}
						span{
							color: #fff;
							font-weight: bold;
						}
						.price{
							@include Scenter;
							color: #FFB800;
							font-weight: bold;
							.coins{
								margin: 0 9px;
							}
						}
					}
				}
				.roll-item-open{
					@include HScenter;
					margin: 12px auto 0;
					height: 50px;
					font-size: 18px;
					line-height: 24px;
					cursor: pointer;
					&.active{
						background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
					&.disabled{
						background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						&.joined{
							opacity: 1;
						}
					}
					&.check{
						color: #FD4453;
					}
					&.joined{
						opacity: 0.5;
					}
				}
			}
		}
	}
</style>
