import originAxios from 'axios'
import { Notify } from 'vant'
import store from '@/store/index'
export default function axios(option) {
	return new Promise((resolve, reject) => {
		const instance = originAxios.create({
			baseURL: process.env.VUE_APP_BASE_URL,
			timeout: 5000
		});	
		//token
		instance.interceptors.request.use(config => {
			localStorage.getItem('token') && (config.headers.Authorization = localStorage.getItem('token'))
			return config
		}, err => {
			return err
		})
		// 接口错误拦截
		instance.interceptors.response.use(res => {
			res.status != 200 && (res.data = { code: 0, message: '网络错误' })
			if(res.data.code == 401){
				store.commit('PostUser',{})
				store.commit('PostIndividualsShow',false)
				localStorage.clear()
				sessionStorage.clear()
				// location.reload()
				// this.$router.go(0)
				location.href = '/'
				Notify({ type: 'danger', message: res.data.message })
				return Promise.reject(res)
			}
			if(res.data.code == 503){
				store.commit('setMaintenance',true)
				store.commit('setMaintenanceContent',res.data.message)
				return Promise.reject(res)
			}else if (res.data.code == 200){
				store.commit('setMaintenance',false)
				return res
			}
			else{
				store.commit('setMaintenance',false)
				Notify({ type: 'danger', message: res.data.message })
			}
				
			return Promise.reject(res)

		})

		//传入对象进行网络请求
		instance(option).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}
