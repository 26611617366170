<template>
	<div>{{ context }}</div>
</template>
<script>
	export default {
		name: "back_top",
		data() {
			return {
				context:`发布日期：2023年05月28日
生效日期：2023年05月28日
Wanskins非常重视保护使用Wanskins产品和服务之用户（以下统称“用户”或“您”）的隐私。您在使用Wanskins服务时，我们可能会收集和使用您的相关信息，我们深知个人信息对您的重要性，会尽全力保护您的个人信息安全可靠。我们致力于维护您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
请在使用我们的服务前，仔细阅读并了解《Wanskins隐私政策》（以下统称“政策”或者“隐私政策”）：
本政策适用于Wanskins提供的一切服务。在您使用Wanskins产品或者服务前，请您务必认真阅读本政策，在充分理解并同意后使用相关产品或者服务。一旦您点击确认同意本隐私政策，开始使用Wanskins任何产品或者服务，即表示您已充分理解并同意我们按照《Wanskins隐私政策》收集、使用、储存和分享您的个人信息。
需要特别说明的是，本政策仅适用于Wanskins产品及服务，不适用您通过我们的产品/服务而接入的其他第三方产品/服务（包括但不限于任何第三方网站以及第三方服务提供者，如第三方支付），而应当适用该第三方的隐私政策或类似声明，我们对于前述第三方收集、使用您个人信息的行为及后果不承担任何责任。
请您注意，为了您能够正常使用Wanskins的产品或服务，您应当开启或授权网络权限，确保本地链接、WLAN或移动网络的链接，并可能产生流量宽带费用，具体费用请咨询当地运营商。
我们会不时地检查或更新我们的功能或服务，更新隐私政策，如果某一功能或服务未在前述说明中且收集了你的信息，我们会通过页面提示、弹窗提示、网站公告等方式向你说明信息收集的内容、范围和目的，以征得您的同意。若您在阅读完本政策后对本政策或者与本政策有关的事宜有任何问题的，请及时与我们客服联系
本政策将帮助您了解以下内容：
一. 我们如何收集和使用您的个人信息
二. 我们如何使用cookie和同类技术
三. 我们如何分享、转让或披露您的个人信息
四. 个人信息安全保护措施及能力
五. 您如何管理您的个人信息
六. 我们如何处理未成年人的个人信息
七. 通知和修订
八. 如何联系我们
一、我们如何收集和使用您的个人信息
“个人信息”是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。请您理解并同意，前述基本信息以及其他无法通过其单独或者与其他信息结果识别您个人身份或活动情况的信息不属于您的个人信息。
我们将通过如下途径收集和获得您的个人信息：
在您使用我们的服务时，我们可能需要收集和使用的您的个人信息包括如下两种：
1、为实现向您提供我们服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；
2、为实现向您提供我们服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。
我们可能间接收集到您的个人信息，包括如下情形：
1、在您注册成为Wanskins用户以及使用服务过程中，经您授权我们可以向Wanskins的关联方、合作伙伴等获得您的有关信息，以便于我们处理您的订单并保证服务顺利完成，或者更好地预防互联网欺诈。
2、我们在您使用第三方账号登录时，会从第三方获取您共享的账号信息（如昵称、头像、地区、性别），并在您同意本隐私政策后将您的第三方账户与Wanskins账户进行绑定。
我们会出于以下目的，收集和使用您的个人信息：
1、帮助您完成注册
1、 基础用户服务
我们通过Wanskins账户为您提供基础用户服务。为注册成为用户并使用我们的基础服 务，您需要提供电子邮箱、STEAM账户、拟使用的用户名和密码用于创建Wanskins账户。如果您仅需 使用浏览、搜索服务，您不需要注册成为我们的用户及提供上述信息。 
对于需要通过Wanskins账户才能使用的服务，我们可能会根据您提供的上述信息校验您的 用户身份，确保我们是在为您本人提供服务。 
如您选择邮箱验证码方式登陆，您授权同意我们向您发 送邮件以验证您的身份。 
2、 附加用户服务 
授权登录：经您同意后，我们可能向第三方共享您的账户信息（头像、昵称及其他页面 提示的信息），使您可以便捷地实现第三方账户的注册或登录。此外，我们可能会根据 您的授权从第三方处获取您的第三方账户信息，并与您的Wanskins账户进行绑定，使您可 通过第三方账户直接登录、使用我们的产品及/或服务。我们将在您授权同意的范围内使 用您的相关信息。 
3、 账户信息展示： 
如果您已拥有Wanskins账户，我们可能会在Wanskins显示您的上述个人信息（实人认证实名 认证仅显示认证是否通过的结果），以及您在Wanskins或与Wanskins账户相关联的产品和服 务中执行的操作，包括通过Wanskins集中展示您的个人资料、优惠权益、现金奖励。我们 会尊重您对Wanskins服务和Wanskins账户设置所做的选择。 
2、为您提供商品或服务信息展示 
在您使用我们服务过程中，为识别账号异常状态、识别订单交易风险、交易安全以及系统运行安全、了解产品适配性、我们会收集您在使用我们服务过程中产生的相关信息，包括： 
设备信息：我们会根据您在软件安装或使用中的具体操作，接收并记录您所使用的设备相关信息（包括硬件型号、MAC地址、版本信息、操作系统、设备设置、唯一设备标识符（IMEI、IMSI、IDFV、IDFA、安卓广告主标识符）、电话状态、设备环境、SIM卡信息、终端制造厂商、语言所在地、硬盘、CPU和电池使用情况、电信运营商、使用语言）、位置信息（包括IP地址、您授权的GPS位置、WLAN接入点、蓝牙、基站以及其他传感器信息）。如您使用安卓设备，需要您在开启设备信息权限。
日志信息：当您使用我们的服务时，我们会自动收集您对我们服务的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、预购、添加至库存、创建交易、售后、分享信息，以及浏览器类型、访问日期和时间。
请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
如您使用的是安卓设备，在使用我们服务的过程中，需要您开启存储权限，用于缓存商品图片、视频、音频、日志，以及在为您提供分享、信息发布、上传等功能时进行文件缓存或读取您的图片/照片/视频。
如您不希望继续接收我们推送的消息，您可要求我们停止推送，或根据短信退订指引要求我们停止发送推广短信，或在移动端设备中进行设置，不再接收我们推送的消息等；但我们依法律规定或单项服务的服务协议约定发送消息的情形除外。
3、帮助您完成下单及订单管理
当您在Wanskins订购具体商品或服务时，我们会通过系统为您生成订单。为保证交付，您需提供STEAM交易链接、STEAM64位ID，如果您拒绝提供此类信息，我们将无法完成相关交付服务。如您通过Wanskins服务为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向我们提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意。
同时订单信息里会载明您所购买的商品或服务信息、订单号、创建时间、交易流水号、付款时间、物流信息、商品总价。我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客服与售后服务及其他我们明确告知的目的。
4、帮助您完成支付
为完成订单支付，您需要提供选择付款方式，为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您同意我们可向您所选择的交易对象收集与支付进度相关信息。
5、向您完成产品或服务的交付
为保证您购买的商品及/或服务能够顺利、安全、准确送达，我们会向物流服务商提供您的订单信息。
为使我们及时获悉并确认交付进度及状态，向您提供售后与争议解决服务，您同意我们可向物流服务商收集与交付进度相关信息。
6、客服及争议处理
当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的会员身份。为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息、订单信息。
7、为您提供安全保障
为确保向您提供更好的安全保障，我们可能使用或整合您的账号信息、订单信息、设备信息、日志信息，以及我们的合作伙伴取得您授权或依据法律共享的信息，这类信息是必须收集的基础信息，用来综合判断您账户及交易风险、进行身份验证、客户服务、安全防范、诈骗监测，并依法采取必要的记录、审计、分析、处置措施，确保我们向您提供的服务的安全性。
8、其他用途
为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。
在不透露单个用户隐私资料的前提下，Wanskins有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。Wanskins可能会对网络服务使用情况进行统计。同时，Wanskins可能会与公众分享这些统计信息，以展示我们服务的整体使用趋势。这些统计信息不包含您的身份识别信息。
9、征得授权同意的例外
根据相关法律法规规定，以下情形中收集、您的信息无需征得您的授权同意：
（1）与我们履行法律法规规定的义务相关的； 
（2）与国家安全、国防安全直接相关的； 
（3）与公共安全、公共卫生、重大公共利益直接相关的； 
（4）与犯罪侦查、起诉、审判和判决执行等直接相关的； 
（5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的； 
（6）所收集的信息是您自行向社会公众公开的； 
（7）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道； 
（8）用于维护Wanskins服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障； 
（9）根据您的要求签订合同所必需的； 
（10）学术研究机构基于公共利益开展统计或者学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； 
（11）法律法规规定的其他情形。 
10、我们申请的设备权限
Wanskins在提供服务过程中，会调用您的一些涉及个人信息的设备权限。以下是Wanskins调用权限对应的业务功能、调用权限的目的以及调用权限前向您询问的情况。
您可以在设备的设置功能中选择关闭部分或全部权限，从而拒绝Wanskins收集相应的个人信息。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或指引。
二、我们如何使用Cookie
为确保网站正常运转，为您提供更为便捷的访问体验，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie,网站或客户端能够存储您的偏好或购物篮内的商品等数据。
我们不会将Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有Cookie,大部分网络浏览器都设有阻止Cookie 的功能。但如果您这么做,则需要在每一次访问我们的网站时亲自更改用户设置。
三、我们如何分享、转让或披露您的个人信息
（一）分享
除以下情形外，我们不会与Wanskins之外的任何第三方分享您的信息：
1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以实现您需要的核心功能或提供您需要的服务，例如：向客户服务、物流服务的供应商提供对应的账号信息及订单信息；
2、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以帮助我们为您提供更有针对性、更完善的服务，例如：向受我们委托的服务商发出短信或推送通知，用于分析广告和服务的有效性。
3、向委托我们进行推广的合作伙伴等第三方共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供可以识别您身份的信息，例如姓名、手机号码、收件地址；或者我们将这些信息进行汇总，以便它不会识别您个人。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。
4、基于不同的业务场景，我们有时会接入第三方SDK，我们仅会出于合法、正当、必要的目的分享您的个人信息，并采取严格的安全措施保护您的个人信息：
支付宝SDK、微信支付SDK，收集您的订单信息（交易金额、订单号、时间），用于向您提供支付服务；
智齿客服SDK，收集您的账号信息、订单信息、与我们的通信记录，用于向您提供在线客服与帮助服务；
MobTech SDK，收集您的账号信息，用于向您提供发送短信验证码服务；
Bugly SDK，深圳市腾讯计算机系统有限公司，收集软件系统崩溃信息、升级软件，改进软件质量；
Wechat SDK，深圳市腾讯计算机系统有限公司，向您提供微信第三方账号登录；
5、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他Wanskins用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息。
6、在获取明确同意的情况下共享，获得您的明确同意后，我们会与其他方共享您的个人信息。
7、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息。对我们与之共享信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本《隐私政策》以及其他任何相关的保密和安全措施来处理信息。
（二）转让
我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您信息的公司、组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。
2、在获得您的明确同意后，我们会向其他方转让您的信息。
（三）披露
我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：
1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；
2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。
（四）授权同意的例外
以下情形中,我们分享、转让、披露您的个人信息无需事先征得您的授权同意:
1、与我们履行法律法规规定的义务相关的;
2、与国家安全、国防安全直接相关的;
3、与公共安全、公共卫生、重大公共利益直接相关的;
4、与犯罪侦查、起诉、审判和判决执行等直接相关的;
5、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;
6、您自行向社会公众公开的个人信息;
7、从合法公开披露的信息中收集个人信息的,如合法的新闻报道、政府信息公开等渠道。
四、个人信息安全保护措施及能力
（一）数据安全技术措施
我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的信息，采取加密技术对您的信息进行加密保存，并通过隔离技术进行隔离。
在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。
（二）我们为保护信息采取的其他安全措施
我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。
我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。
（三）我们仅允许有必要知晓这些信息的Wanskins员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与Wanskins的合作关系。
（四）我们会采取一切合理可行的措施，确保未收集无关的信息。
（五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。
（六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
（七）安全事件处置
在通过Wanskins服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。
为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。
请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入Wanskins服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。
五、您如何管理您的个人信息
（一）访问、更正和删除
1、您有权访问您的个人信息，除法律法规规定的例外情况。您可以通过以下方式自行访问：
账户信息——如果您希望访问或编辑您的账户中的个人资料信息,您可以通过登录Wanskins的应用执行此类操作，点击路径为：“我的”/“设置”/“账号与安全”。
搜索信息——您可以在Wanskins的网站或应用中访问或清除您的搜索历史记录。
订单信息——您可以在Wanskins的网站或应用中查阅、管理您的订单及记录、交易记录，点击路径为：“我的”/“我的订单”。
收货地址——您可以在Wanskins应用中查询、管理您的收货地址，点击路径为：“我的”/“收货地址”。
2、我们鼓励您更正和修改您的信息以使其更准确有效。您能通过Wanskins服务访问您的信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充和删除。我们将采取适当的技术手段，尽可能保证您可以访问、更新和更正自己的信息或使用Wanskins服务时提供的其他信息。
3、在以下情形中，您可以向我们提出删除个人信息的请求:
（1）我们处理个人信息的行为违反法律法规的；
（2）我们收集、使用您的个人信息，却未征得您的同意的；
（3）我们处理个人信息的行为违反了与您的约定的；
（4）您不再使用我们的产品或服务，或您注销了账号的；
（5）我们不再为您提供产品或服务的。
如果我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非另有规定，或该等实体获得您的独立授权。
另外，我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，例如，您在Wanskins服务中所上传或发布的信息、您对其他人上传或发布的信息作出的回应，通过电子邮件或在Wanskins服务中不特定用户可见的公开区域内上传或公布您的个人信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息。
（二）注销
在符合Wanskins单项服务的服务协议约定条件及国家相关法律法规规定的情况下，您的该项Wanskins服务账号可能被注销或删除。当账号注销或被删除后，与该账号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理。当您主动申请注销时，您可以依次点击“我的”/“设置”/“账号与安全”/“注销”进行操作。
（三）改变您授权同意的范围或撤回授权
您总是可以选择是否披露信息。有些信息是使用Wanskins服务所必需的，但大多数其他信息的提供是由您决定的。您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权。
当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。
（四）有关敏感信息的提示
某些信息因其特殊性可能被认为是敏感信息，例如您的种族、宗教、个人健康和医疗信息等，以及身份证明文件、个人生物识别信息、财产信息、行踪轨迹、未成年人的信息等。
请注意，您在Wanskins服务中所提供、上传或发布的内容和信息（例如有关您社交活动的照片或信息），可能会泄露您的敏感信息。您需要谨慎地考虑，是否使用Wanskins服务披露您的敏感信息。
您同意您的敏感信息按本《隐私政策》所述的目的和方式来处理。
（五）响应您的请求
为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
我们将在15天内做出答复，如您不满意，还可以向我们提起投诉。
对于您的合理请求,我们原则上不收取费用,但对多次重复、超出合理限度的请求,我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求,我们可能会予以拒绝。
以下情形中，按照法律法规要求，我们将无法响应您的请求：
1、与我们履行法律法规规定的义务相关的；
2、与国家安全、国防安全直接相关的;
3、与公共安全、公共卫生、重大公共利益直接相关的;
4、与犯罪侦查、起诉、审判和判决执行等直接相关的;
5、有充分证据表明您存在主观恶意或滥用权利的;
6、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
7、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
8、涉及商业秘密的。
（六）信息的存储
（1）信息存储的地点：原则上，我们在中华人民共和国境内运营中收集和产生的个人信息，将存储于中国境内，但以下情况除外：
法律法规另有明确规定；
获得您的明确授权；
您主动发起的跨境交易行为等。
（2）信息存储的期限
您的个人信息将保留至用户注销账户时，除非法律有强制的存留要求。当账号注销时，我们将根据法律法规的要求尽快删除您的个人信息或作匿名化处理，有关注销账号的相关规则和流程，请参见本协议。您理解并同意，相关交易记录可能须在平台后台保存5年甚至更长时间。
手机号码、微信号：若您使用Wanskins产品或服务时，我们一直保存您的手机号、微信号，以保证您正常使用该服务，当您注销Wanskins账户后，我们将删除相应的信息或进行匿名化处理。
当我们的产品或服务发生停止运营的情形时，我们将以推送通知、公告等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。
六、我们如何处理未成年人的个人信息
我们非常重视对未成年人个人信息的保护。在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
七、通知和修订
我们可能适时修改本《隐私政策》的条款，对于重大变更，我们会提供显著的通知，您可以选择停止使用Wanskins服务；在该种情况下，如您仍然继续使用Wanskins服务的，即表示同意受经修订的本《隐私政策》的约束。
本《隐私政策》所指的重大变更包括但不限于：
1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等;
2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等;
3、个人信息共享、转让或公开披露的主要对象发生变化;
4、您参与个人信息处理方面的权利及其行使方式发生重大变化;
5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时;
6、个人信息安全影响评估报告表明存在高风险时。
八、如何联系我们
如您有关于网络信息安全的投诉和举报，或您对本《隐私政策》、您的信息的相关事宜有任何问题、意见或建议，以及有关本声明或Wanskins的隐私措施的问题通过836890935@qq.com与我们联系。
如您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
九、箱子中奖概率 
1．Wanskins中，每个箱子的中奖概率均可在开箱界面左上角查看，开启箱子可获得的奖励，均可在开箱界面下方查看，且可查看该箱子可开启出的奖励。`,
			}
		},
		mounted() {
		},
		methods: {
			
		},
	}
</script>
<style scoped lang="scss">
	#back_top {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		font-size: 26px;
		background: #4854c9;
		position: fixed;
		z-index: 1000;
		bottom: 100px;
		right: 10px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
</style>
