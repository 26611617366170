<template>
  <div class="container">
    <div class="game-title">会员中心</div>
    <div class="vip-table">
      <div class="vip-bar" :class="['level'+User.vip_level]">
        <img src="~@/assets/img/vip-current.png" alt="">
      </div>
      <div class="vip-tr">
        <div class="vip-td">VIP等级</div>
        <div class="vip-td">当前<br />等级</div>
        <div class="vip-td">充值金额</div>
        <div class="vip-td">升级红包</div>
        <div class="vip-td">充值加成</div>
      </div>
      <div class="vip-tr" v-for="(item,index) in VipList" :key="index">
        <div class="vip-td"><div class="vip-tip" :class="['level'+item.level]"></div></div>
        <div class="vip-td"></div>
        <div class="vip-td"><div class="coins"></div>{{item.threshold}}</div>
        <div class="vip-td">{{item.packet}}</div>
        <div class="vip-td">{{item.rebate}}%</div>
      </div>
    </div>
    <div class="vip-desc">所有充值红包系统自动发放</div>
    <div class="vip-question">
      <div class="vip-title">VIP等级说明</div>
      <div class="desc">
        <div class="item">
          <div class="tilte">会员如何升级？</div>
          <div class="info">注册wanskins之后，绑定并校验您的Steam交易链接。达到一定的充值门槛，即可享受所对应的VIP等级的福利。</div>
        </div>
        <div class="item">
          <div class="tilte">是否可以一次上升多个等级？</div>
          <div class="info">wanskins支持VIP会员单次充值上升多个等级，即一次充值达到一定等级的，则自动升级至已覆盖的最高的会员等级。并且可以一次性获得已覆盖全部等级所对应的累计升级红包。</div>
        </div>
        <div class="item">
          <div class="tilte">升级红包只能领取1次吗？</div>
          <div class="info">是的，只可领取一次等级专属红包。红包随VIP升级自动发放到用户余额，请自行至账户收支明细中查看。
      
          </div>
        </div>
        <!-- <div class="item">
          <div class="tilte">在何处查看我的等级？</div>
          <div class="info">是的，只可领取一次等级专属红包。红包随VIP升级自动发放到用户余额，请自行至账户收支明细中查看。</div>
        </div> -->
        <div class="item">
          <div class="tilte">在何处查看我的等级？</div>
          <div class="info">用户登录wanskins之后，点击导航栏“会员中心”可看到当前等级。</div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- <div class="content py-3 ">
    <div class="Title ">
      <p > VIP 会员  </p>
      <div class="Title-lower"></div>
    </div>
    <div class="h3 text-white text-center position-relative">
      <div class="mb-2">{{$route.meta.title}}
       <span style="font-size: 16px">VIP{{user_info.vip_level}}</span>
      </div>
    </div>
    <div class="content-box pb-1 m-auto">
      <div class="d-flex vip m-auto flex-nowrap content-box-table flex-wrap">
        <div>
          <div class="vip-title">VIP奖励等级</div>
          <div class="vip-title">充值金额</div>
          <div class="vip-title">升级红包</div>
          <div class="vip-title">充值加成</div>
          <div class="vip-title">红包领取</div>
        </div>
        <div  class="content-box-scroll">
          <div class="d-flex">

            <div v-for="(item,index) in VipList" :key="index" class="d-flex">
             :class="index+1 == user_info.vip_level ?'activeVip':''" 
              <div class="vip-con" >
                <img style="height: 20px" :src="require('@/assets/images/Nav/wan_vip'+(item.level)+'.png')"
                     alt="">
              </div>
            </div>
          </div>
          <div class="d-flex">

            <div v-for="(item,index) in VipList" :key="index" class="d-flex">
              <div class="vip-con" >
                {{item.threshold}}
              </div>
            </div>
          </div>
          <div class="d-flex">

            <div v-for="(item,index) in VipList" :key="index" class="d-flex">
              <div class="vip-con" >
                {{item.packet}}
              </div>
            </div>
          </div>
          <div class="d-flex">

            <div v-for="(item,index) in VipList" :key="index" class="d-flex">
              <div class="vip-con" >
                {{item.rebate}}%
              </div>
            </div>
          </div>
          <div class="d-flex souyouhongbao">
            <div class="w-100">
              <div class="vip-con-btn">
                <div class=" w-100">
                  所有充值红包系统自动发放，
                 请在收支明细中查看
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="text-white text-center mt-2 mb-3 position-relative">
      <div class="Title ">
        <p > VIP 等级说明  </p>
        <div class="Title-lower"></div>
      </div>
    </div>
      <div class="desc">
        <div class="item">
          <div class="tilte">会员如何升级？</div>
          <div class="info">注册wanskins之后，绑定并校验您的Steam交易链接。达到一定的充值门槛，即可享受所对应的VIP等级的福利。</div>
        </div>
        <div class="item">
          <div class="tilte">是否可以一次上升多个等级？</div>
          <div class="info">wanskins支持VIP会员单次充值上升多个等级，即一次充值达到一定等级的，则自动升级至已覆盖的最高的会员等级。并且可以一次性获得已覆盖全部等级所对应的累计升级红包。</div>
        </div>
        <div class="item">
          <div class="tilte">升级红包只能领取1次吗？</div>
          <div class="info">是的，只可领取一次等级专属红包。红包随VIP升级自动发放到用户余额，请自行至账户收支明细中查看。
      
          </div>
        </div>
      
        <div class="item">
          <div class="tilte">在何处查看我的等级？</div>
          <div class="info">是的，只可领取一次等级专属红包。红包随VIP升级自动发放到用户余额，请自行至账户收支明细中查看。</div>
        </div>
        <div class="item">
          <div class="tilte">在何处查看我的等级？</div>
          <div class="info">用户登录wanskins之后，点击导航栏“VIP会员”可看到当前等级。</div>
        </div>
      </div>
  </div> -->
</template>

<script>
  import * as $api from '@/network/api.js'
  import {
		mapState
	} from 'vuex'
export default {
  name: "index",
  components: {},
  data() {
    return {
      VipList: [],
      Viptips: {},
    }
  },
  filters: {

  },
  computed: {
    ...mapState([
      'User',
    ])
  },
  created() {

  },
  mounted() {
    this.init()
    // console.log(this.$store.state.user.vip_level)

  },
  methods: {
    init(){
      $api.getVipLists().then(res => {
        this.VipList = res.data.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/css/base.scss";
  .vip-table{
    width: 328px;
    margin: 0 auto;
    position: relative;
    .vip-bar{
      position: absolute;
      top: 43px;
      left: 89px;
      bottom: 0;
      width: 4px;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: var(--logo, linear-gradient(-90deg, #448EFD 0%, #44FDE7 100%));
        opacity: 0.3;
        border-radius: 2px;
      }
      &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: var(--logo, linear-gradient(-90deg, #448EFD 0%, #44FDE7 100%));
        border-radius: 2px;
      }
      img{
        position: absolute;
        width: 16px;
        height: 16px;
        left: 50%;
        margin-left: -8px;
        z-index: 3;
      }
      @for $i from 1 to 11{
        &.level#{$i}{
          img{
            top: 13px+($i - 1)*43px;
          }
          &::after{
            height: 21px+($i - 1)*43px;
          }
        }
      }
      &.level10{
        &::after{
            height: 100%;
          }
      }
    }
    .vip-tr{
      display: flex;
      gap: 1px;
      margin-bottom: 1px;
      .vip-td{
        width: 72px;
        height: 42px;
        text-align: center;
        font-size: 12px;
        background: #2A2A38;
        @include HScenter;
        &:nth-child(2){
          width: 36px;
        }
        .coins{
          width: 14px;
          height: 14px;
          margin-right: 4px;
          background-image: url(~@/assets/img/coins-white1.svg);
        }
        .vip-tip{
          width: 48px;
          height: 20px;
          background-size: 100% 100%;
          @for $i from 1 to 11{
            &.level#{$i}{
              background-image: url('~@/assets/img/vip-level'+$i+'.png');
            }
          }
        }
      }
    }
  }
  .vip-desc{
    width: 328px;
    text-align: center;
    @include HScenter;
    color: #C1C1C1;
    background: rgba(0, 0, 0, 0.30);
    margin: 18px auto;
  }
  .vip-question{
    .vip-title{
      height: 40px;
      @include HScenter;
      background: url(~@/assets/img/rule-header.png);
      background-size: 100% 100%;
    }
    .desc{
      padding: 20px 40px;
      .item{
        margin-bottom: 12px;
        .tilte{
          margin-bottom: 12px;
          font-weight: bold;
        }
        .info{
          font-size: 12px;
        }
      }
    }
  }
</style>
