<template>
	<div class="container">
		<div v-for="(box,index) in HotList" :key="index + 'a'" style="margin-bottom: .5rem;">
			<div class="Title">
				{{box.name}}
			</div>
			<div class="Blind_boxS">
				<div class="Blind_item" v-for="(item,index) in box.box" :key="index" @click="ToOpen(item.id)">
					<div class="img-con">
						<div class="front" :style="`background-image: url(${item.cover})`"></div>
						<img :src="item.weapon_cover" alt="">
					</div>
					<div class="name">{{item.name}}</div>
					<div class="btn">
						<div class="price">
							<div class="coins-white"></div><span>{{item.bean}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Rooms, GetHotBox } from '@/network/api.js'
	export default{
		name:"Home",
		data(){
			return{
				Headlines:[],

				HotList: [],
				BladeList: [],
				recruitList: [],
				vitalityList: [],
				classicList: [],
				soulList:[],
				XyList:[]
			}
		},
		filters:{
			StrIntercept(val){
				return val.length > 9 ? val.substr(0, 9) + '...' : val
			}
		},
		watch: {
		  $route(to, from) {
		    this.ObtainHotBox()
		  },
		},
		created() {
			this.GetRooms()
			this.ObtainHotBox()
			if(this.$route.query.ref){
				sessionStorage.setItem('ref', this.$route.query.ref)
			}
		},
		methods:{
			skipTo(){
				this.$router.push('/Double')
			},
			slideTo(type){
				if(type=='prev'){
					this.$refs.swipe.prev();
				}else{
					this.$refs.swipe.next();
				}
			},
			//房间
			GetRooms() {
				Rooms('1','1').then((res) => {
					// console.log(res.data.data.data)
					this.Headlines = res.data.data.data.slice(0,2)
					// console.log(this.Headlines)
				})
			},
			//热门宝箱
			ObtainHotBox() {
				GetHotBox().then((res) => {
					this.HotList = res.data.data
					this.$nextTick(function(){
						this.scrollTo();
					}.bind(this))
				})

			},

			//路由跳转房间详情
			toRoom(id){
				// console.log(id)
				this.$router.push({
					path:'/room',
					query:{
						id:id
					}
				})
			},
			scrollTo(){
				if(sessionStorage.getItem('indexScroll')){
					window.scrollTo(0,sessionStorage.getItem('indexScroll'));
					sessionStorage.removeItem('indexScroll');
				}
			},
			//路由跳转开箱
			ToOpen(id){
				sessionStorage.setItem('indexScroll',window.pageYOffset)
				this.$router.push({
					path:'/Open',
					query:{
						id:id
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	
	.container{
		width: 100%;
		/*padding:10px;*/
		box-sizing: border-box;
		.rooms{
			width: 100%;
			display: flex;
			justify-content: space-between;
			.rooms_item{
				width: 49%;
				padding:0 4px 6px;
				box-sizing: border-box;
				border-radius:8px;
				background: url(../../assets/images/rooms/roll_item.png) no-repeat center;
				background-size: 100% 100%;
				&:last-child{
					background: url(../../assets/images/rooms/roll_item2.png) no-repeat center;
					background-size: 100% 100%;
				}
				.top{
					width: 100%;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.pic{
						width: 30px;
						height: 30px;
						border-radius: 50%;
						overflow: hidden;
						img{
							width: 100%;
							height: 100%;
						}
					}
					.room_data{
						width: calc(100% - 40px);
						.room_title{
							width: 100%;
							height: 20px;
							line-height: 20px;
							font-size: 12px;
							font-weight: bold;
							color: #fff;
						}
						.room_price{
							width: 100%;
							height: 20px;
							line-height: 20px;
							color: #f4f4f4;
							display: flex;
							span{
								display: flex;
								align-items: center;
								font-size: 12px;
								font-weight: bold;
								color: #75cd66;
								img{
									width: 18px;
								}
							}
						}
					}
				}

				.center{
					width: 80px;
					height: 80px;
					margin: 0 auto;
					.pic{
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						background-size: 90% 90% !important;
						background-position: center !important;
						background-repeat: no-repeat !important;
						img{
							max-width: 110%;
							max-height: 110%;
						}
					}
				}
				.countDown {
					width: 100%;
					margin-top: 5px;
					display: flex;
					justify-content: center;
					.colon {
						display: inline-block;
						width: 20px;
						height: 20px;
						text-align: center;
						color: #fff;
						font-size: 12px;
					}

					.block {
						display: inline-block;
						width: 30px;
						height: 20px;
						background: rgba(48, 57, 81, 0.8);
						border-top: 1px solid #49587e;
						color: #fff;
						font-size: 12px;
						line-height: 20px;
						letter-spacing: 0.4em;
						padding-left: 5px;
						text-align: center;
						background-size: cover;
						box-sizing: border-box;
						border-radius: 4px;
					}
				}

			}
		}

		.Title{
			margin: 24px auto;
			background-size: 100% auto;
			background-position: bottom center;
			background-repeat: no-repeat;
			width: 363px;
			background-image: url(~@/assets/img/home-title-bg.png);
		}
	}
	.times{
		opacity: 0;
		color: #ffba2e;
		text-align: center;
		margin-top: 0.1rem;
		font-size: .12rem;
		&.show{
			opacity: 1;
		}
	}
	.Blind_boxS{
		width: 334px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		row-gap: 24px;
		.Blind_item{
			width: 160px;
			display: flex;
			align-items: center;
			flex-direction: column;
			.img-con{
				width: 160px;
				height: 160px;
				position: relative;
				img{
					position: relative;
					z-index: 1;
				}
				.front{
					background-size: 100% auto;
					background-repeat: no-repeat;
					background-position: center center;
					position: absolute;
					z-index: 10;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
				}
			}
			.name{
				margin: 4px 0 6px;
				max-width: 100%;
				@include wordhide;
			}
			.btn{
				background-image: url(~@/assets/img/box-btn-bg.png);
				background-size: 100% 100%;
				@include HScenter;
				width: 93px;
				height: 32px;
				.price{
					.coins-white{
						background-image: url(~@/assets/img/coins-spring.svg);
						width: 16px;
						height: 16px;
						margin-right: 4px;
					}
					span{
						background: linear-gradient(180deg, #FFF 42.5%, #FFF59B 98.36%);
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}
			}
		}
	}
</style>
