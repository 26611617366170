const vFilter = {
	ImgFilter: function(value) {
		// 截取当前数据到小数点后两位
		if (value.indexOf(localStorage.getItem('static_file_url')) != -1) {
			return value
		}else{
			let realVal = localStorage.getItem('static_file_url') + value
			return realVal
		}
		
	}
}

export default vFilter
