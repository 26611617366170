<template>
	<div id="box_mod">
		<div class="box_mod_nav">
			<img src="@/assets/img/icon-battle.png" alt="">
			盲盒对战
			<div class="right">
				<div class="tit" @click="openSeed">
					修改种子
				</div>
				<div class="tit" @click="G_Show = true">
					游戏规则
				</div>	
			</div>
		</div>
		<div class="swiper-con">
			<div class="arrow" @click="signSwiperSlide('prev')"></div>
			<swiper :options="swiperOptions" ref="sign">
				<swiper-slide class="swiper-slide" v-for="(item,index) in ArenaBoxList" :key="index" @click.stop="">
					<div class="box_mod_item" @click="$router.push('/BoxDetail?id='+item.id)">
						<div class="img-con">
							<img :src="item.cover" alt="">
						</div>
						<div class="name">{{item.name}}</div>
						<div class="price"><div class="coins"></div>{{item.game_bean}}</div>
					</div>
				</swiper-slide>
			</swiper>
			<div class="arrow right" @click="signSwiperSlide('next')"></div>
		</div>
		<van-overlay :show="G_Show">
			<div class="wrapper">
				<div class="rule-popup-con">
					<div class="rule-popup-header">
						对战规则
						<div class="close" @click="G_Show = false"></div>
					</div>
					<div class="rule-popup-main">
						<div class="scroll-con">
							<el-scrollbar style="height:100%;">
								<div class="rule-popup-content" v-html="publicConfig.desc_arena"></div>
							</el-scrollbar>
						</div>
					</div>
				</div>
			</div>
		</van-overlay>
		
		<!-- 物品遮罩层 -->
		<van-overlay :show="Show" :lock-scroll="false">
			<div class="wrapper">
				<div class="LogOut_box box_mod_out">
					<div class="close" @click="Show = false"><img style="filter: invert(1);" src="@/assets/images/public/close.png" alt=""></div>
					<div class="box_mod_top">
						<img :src="ArenaImg" alt="">
						<p>{{ArenaTitle}}</p>
					</div>
					<div class="mod_boxlist">
						<div class="mod_boxitem" v-for="(item,index) in ArenaArr" :key="index">
							<div class="mod_boxpic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
								<img :src="item.cover" alt="">
							</div>
							<p>{{item.name}}</p>
							<span><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span>
						</div>
					</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="openHashSeed">
			<div class="wrapper">
				<div class="hash-con">
					<div class="close" @click="openHashSeed=false"></div>
					<img class="title-img" src="~@/assets/img/hash-popup-title.png" alt="">
					<div class="title"><span>种子信息</span></div>
					<div class="label">
						用户种子：
					</div>
					<div class="row">
						<div class="input">
							<input type="text" v-model="seedForm.user_seed" :readonly="!edit_user_seed" ref="user_seed">
						</div>
						<div v-if="!edit_user_seed" class="btn" @click="editUserSeed">修改</div>
						<div v-else class="btn" @click="setUserSeed(1)">保存</div>
						<div class="btn" @click="setUserSeed(2)">随机</div>
					</div>
					<div class="label">
						服务器种子hash：
					</div>
					<div class="row">
						<div class="input">
							<input type="text" v-model="seedForm.server_seed_hash" readonly>
						</div>
						<van-button class="btn" size="small" type="info" v-clipboard:copy="seedForm.server_seed_hash" v-clipboard:success="copy" v-clipboard:error="onError">复制</van-button>
					</div>
					<div class="title"><span>种子说明</span></div>
					<div class="desc">
						{{
`在您开始游戏前，您可以任意修改您的用户种子，以保证该局游戏的公平性
为了方便您管理自己的种子，您设置的种子将用于所有需要Hash算法的游戏;
如需在游戏后对该局游戏进行验证，请提前复制服务器种子Hash值保存，
以验证并确保游戏前后服务器所给出的种子一致;
游戏公平Hash算法详细规则请参考网页顶部《公平性游戏》;`
						}}
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	import { ArenaBoxList, userSeed, setUserSeed } from '@/network/api.js'
	import { mapState } from 'vuex'
	export default{
		name:'box_mod',
		components: {
			Swiper,
			SwiperSlide,
		},
		data(){
			return{
				G_Show:false,//规则弹框
				ArenaBoxList:[],//宝箱列表
				Show:false,//物品弹框
				ArenaTitle:'',//弹框标题
				ArenaImg:'',//弹框图片
				ArenaArr:[],//箱内物品列表
				swiperOptions: {
					slidesPerView :3.2,
				  speed:500,
				  observer:true,
				},
				openHashSeed: false,
				seedForm: {
					server_seed_hash: '',
					user_seed: ''
				},
				edit_user_seed: false
			}
		},
		computed: {
			...mapState(['publicConfig'])
		},
		created() {
			this.GetArenaBoxList()
		},
		methods:{
			generateRandomCode(length) {
				var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				var randomCode = '';

				for (var i = 0; i < length; i++) {
						var randomIndex = Math.floor(Math.random() * characters.length);
						randomCode += characters.charAt(randomIndex);
				}

				return randomCode;
			},
			setUserSeed(type){
				setUserSeed({seed: type==1 ? this.seedForm.user_seed : this.generateRandomCode(6)}).then((res) => {
					this.edit_user_seed = false
					this.seedForm.user_seed = res.data.data.user_seed
					this.$notify({
						type: 'success',
						message: '修改成功'
					})
				})
			},
			editUserSeed(){
				this.edit_user_seed = true
				this.$nextTick(()=>{
					this.$refs.user_seed.focus()
				})
			},
			//复制文本
			copy(e) {
				// console.log(e)
				this.$notify({
					type: 'success',
					message: '复制成功'
				})
			},
			onError(e) {
				// console.log(e)
				this.$notify({
					type: 'danger',
					message: '复制失败'
				})
			},
			openSeed(){
				this.openHashSeed = true
				userSeed().then((res) => {
					this.seedForm = res.data.data
				})
			},
			signSwiperSlide(type){
				let swiper = this.$refs.sign.$swiper;
				if(type=='next'){
					swiper.slideNext()
				}else{
					swiper.slidePrev()
				}
			},
			//宝箱列表
			GetArenaBoxList(){
				ArenaBoxList().then((res) => {
					// console.log(res.data.data)
					res.data.data.forEach(item=>{
						item.box_awards = item.box_awards.sort((a,b)=>{
							return Number(b.bean) - Number(a.bean)
						})
					})
					this.ArenaBoxList = res.data.data.sort((a,b)=>{
						return Number(a.game_bean) - Number(b.game_bean)
					})
				})
			},
			//箱内物品
			GetArenaArr(item){
				// console.log(item)
				this.ArenaTitle = item.name
				this.ArenaImg = item.cover
				this.ArenaArr = item.box_awards
				this.Show = true
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	#box_mod{
		width: 100%;
		padding-top: 10px;
		.box_mod_nav{
			width: 100%;
			display: flex;
			align-items: center;
			img{
				width: 20px;
				height: 20px;
				margin-right: 6px;
			}
			.right{
				margin-left: auto;
				@include Scenter;
				gap: 10px;
				.tit{
					width: 82px;
					height: 34px;
					display: flex;
					align-items: center;
					
					justify-content: center;
					background: linear-gradient(180deg, #23232B 0%, #223 100%);
					cursor: pointer;
				}
			}
			
		}
		.box_mod_list{
			width: 100%;
			margin-top: 10px;
			background: rgba($color: #000000, $alpha: .2);
			border-radius: 6px;
			overflow: hidden;
			overflow-x: auto;
			white-space: nowrap;
			padding: 5px;
			box-sizing: border-box;
		}
		.box_mod_item{
			width: 87px;
			height: 127px;
			background: url(~@/assets/img/battle-scroll-bg.png);
			background-size: 100% 100%;
			@include Scenter;
			flex-direction: column;
			padding-top: 10px;
			.img-con{
				width: 64px;
				height: 64px;
			}
			.name{
				max-width: 90%;
				font-size: 12px;
				@include wordhide;
				display: block;
			}
			.price{
				font-size: 12px;
				@include Scenter;
				margin: 3px 0;
				font-weight: bold;
				.coins{
					width: 14px;
					height: 14px;
					margin-right: 4px;
				}
			}
		}
		.box_mod_out{
			.box_mod_top{
				width: 100%;
				height: 34px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #11151f;
				img{
					height: 34px;
					vertical-align: middle;
					margin-right: 10px;
				}
				p{
					font-size: 14px;
					color: #fff;
					font-weight: bold;
				}
			}
			.mod_boxlist{
				width: 100%;
				height: 400px;
				background: #20222b;
				border-radius:0 0 6px 6px;
				overflow-y: auto;
				padding:5px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.mod_boxitem{
					display: inline-block;
					width: 48%;
					margin-bottom: 10px;
					background: rgba($color: #fff, $alpha: .02);
					border-radius: 4px;
					text-align: center;
					padding-bottom: 10px;
					.mod_boxpic{
						width: 120px;
						height: 120px;
						margin: 0 auto;
						display: flex;
						align-items: center;
						justify-content: center;
						background-size: 70%;
						background-position: center;
						background-repeat: no-repeat;
						
						img {
							vertical-align: middle;
							max-width: 80%;
							max-height: 80%;
						}
					}
					p{
						font-size: 14px;
						padding: 0 8px;
						text-align: center;
						box-sizing: border-box;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						color: #fff;
					}
					span {
						display: block;
						margin-top: 5px;
						text-align: center;
						font-size: 14px;
						color: #75cd66;
						margin: 5px 0;
					
						img {
							width: 16px;
							height: 16px;
							vertical-align: middle;
							margin-bottom: 2px;
						}
					}
				}
				
			}
			
		}
	}
	.swiper-con{
		display: flex;
		align-items: center;
		margin-top: 10px;
		.swiper-container{
			flex: 1;
			flex-shrink: 0;
			margin: 0 10px;
		}
		.arrow{
			width: 14px;
			height: 23px;
			background-image: url(~@/assets/img/icon-battle-left.png);
			background-size: 100% 100%;
			cursor: pointer;
			flex-shrink: 0;
			&.right{
				background-image: url(~@/assets/img/icon-battle-right.png);
			}
		}
	}
	.hash-con{
		@include Scenter;
		flex-direction: column;
		padding-top: 15px;
		background-image: url(~@/assets/img/hash-popup.png);
		background-size: 100% 100%;
		width: 350px;
		height: 537px;
		position: relative;
		.close{
			width: 24px;
			height: 24px;
			background-image: url(~@/assets/img/rule-close.png);
			background-size: 100% 100%;
			position: absolute;
			right: 10px;
			top: 12px;
			cursor: pointer;
		}
		.title-img{
			height: 34px;
			margin-bottom: 12px;
		}
		.title{
			margin-bottom: 12px;
			span{
				position: relative;
				&::before{
					content: '';
					position: absolute;
					top: 50%;
					left: -110%;
					height: 1px;
					width: 53px;
					background: linear-gradient(270deg, #D9D9D9 50%, rgba(217, 217, 217, 0.00) 99.06%);
				}
				&::after{
					content: '';
					position: absolute;
					top: 50%;
					right: -110%;
					height: 1px;
					width: 53px;
					background: linear-gradient(90deg, #D9D9D9 50%, rgba(217, 217, 217, 0.00) 99.06%);
				}
			}
		}
		.label{
			width: 305px;
			margin-bottom: 12px;
		}
		.row{
			margin-bottom: 12px;
			width: 305px;
			@include Scenter;
			gap: 18px;
			.input{
				flex: 1;
				height: 32px;
				border: 1px solid; /* 设置一个基本的边框 */
				border-image: linear-gradient(to bottom, #448EFD, #44FDE7); /* 使用线性渐变作为边框图像 */
				border-image-slice: 1; /* 保持整个边框都是渐变色 */
				padding: 1px; /* 可选，用于确保内容不被边框覆盖 */
				padding: 1px 8px;
				input{
					width: 100%;
					height: 100%;
				}
			}
			.btn{
				background: #FFB800 !important;
				width: 48px;
				height: 32px;
				color: #15161B;
				@include HScenter;
				cursor: pointer;
				border-radius: 0;
			}
		}
		.desc{
			width: 305px;
			height: 220px;
			white-space: pre-line;
			padding: 0 10px;
			overflow-y: auto;
			overflow-x: hidden;
			//滚动条宽度
			&::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}
			/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
			&::-webkit-scrollbar-track {
				background: rgba(255, 255, 255, 0.5);
			}
			//滚动条设置
			&::-webkit-scrollbar-thumb {
				// border-radius: 5px;
				background: rgba(255, 255, 255, 1);
			}
		}
		.list{
			width: 327px;
			height: 423px;
			white-space: pre-line;
			padding: 0 10px;
			overflow-y: auto;
			overflow-x: hidden;
			//滚动条宽度
			&::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}
			/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
			&::-webkit-scrollbar-track {
				background: rgba(255, 255, 255, 0.5);
			}
			//滚动条设置
			&::-webkit-scrollbar-thumb {
				// border-radius: 5px;
				background: rgba(255, 255, 255, 1);
			}
			.item{
				.item-top{
					height: 28px;
					margin-bottom: 12px;
					@include Scenter;
					background: #2A2D3D;
					padding: 0 12px;
					font-size: 12px;
					color: #999;
					.rate{
						margin-left: auto;
						background: var(--LOGO, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 12px;
					}
				}
				.gun-info{
					@include Scenter;
					gap: 12px;
					.img-con{
						width: 72px;
						height: 65px;
						background-size: auto 100%;
						background-position: center center;
						background-repeat: no-repeat;
						&.lv1{
							background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
						}
						&.lv2{
							background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
						}
						&.lv3{
							background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
						}
						&.lv4{
							background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
						}
						&.lv5{
							background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
						}
					}
					.info{
						flex: 1;
						overflow: hidden;
						.name{
							@include wordhide;
							font-size: 12px;
						}
						.alias{
							font-size: 12px;
							color: #999;
						}
					}
					.btn-con{
						.btn{
							width: 88px;
							height: 30px;
							background: #FFB800;
							color: #15161B;
							@include HScenter;
							cursor: pointer;
							&:first-child{
								margin-bottom: 8px;
							}
						}
					}
				}
				.seed-detail{
					font-size: 12px;
					word-break: break-all;
					margin: 12px 0;
					color: #999;
					span{
						color: #09FFC4;
					}
				}
			}
		}
	}
</style>
