<template>
	<div id="Recharge">
		<div class="recharge-title">
			充值金额
			<span>（首充13%）</span>
		</div>
		<div class="recharge-type-list">
			<!-- <div class="item" :class="{'active': selectType==6}" @click="selectType=6"><img src="~@/assets/img/icon-recharge-alipay.png" alt="">支付宝1</div>
			<div class="item" :class="{'active': selectType==3}" @click="selectType=3"><img src="~@/assets/img/icon-recharge-alipay.png" alt="">支付宝2</div> -->
			<div class="item" :class="{'active': selectType==10}" @click="selectType=10"><img src="~@/assets/img/icon-recharge-wxpay.png" alt=""> 微信支付</div>
			<div class="item" :class="{'active': selectType==3}" @click="selectType=3"><img src="~@/assets/img/icon-recharge-alipay.png" alt="">支付宝</div>
			<div class="integral-btn" v-if="publicConfig.integral_to_bean_on==1" @click="$router.push('/Integral')">兑换金额</div>
		</div>
		<div class="recharge-list">
			<div class="item" :class="{'active': item.id == selectId}" v-for="(item,index) in BeanList" :key="index" @click="selectId=item.id">
				<div class="icon-coins"></div>
				{{item.price}}
			</div>
		</div>
		<div class="recharge-type">
			支付方式
			<div class="right">
				<img v-if="selectType==10" src="~@/assets/img/icon-recharge-wxpay.png" alt="">
				<img v-else src="~@/assets/img/icon-recharge-alipay.png" alt="">
				{{selectType == 10 ? '微信' : '支付宝'}}支付
			</div>
		</div>
		<div class="recharge-tit">（支付前必须先绑定交易链接）</div>
		<div class="recharge-btn" @click="G_Show=true">
			立即充值
		</div>
		
		<div class="recharge-title">
			充值记录
		</div>
		<div class="recharge-record">
			<div class="record_item th">
				<p>金币</p>
				<p>价格</p>
				<p>订单号</p>
				<p>状态</p>
			</div>
			<div class="record_item" v-for="(item,index) in RecordList" :key="index">
				<p>{{item.bean}}</p>
				<p>{{item.price}}</p>
				<p>{{item.code}}</p>
				<p>{{item.status_alias}}</p>
			</div>
			<div class="page-con">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" @current-change="handleCurrentChange" :pager-count='5' prev-text="上一页" next-text="下一页" small>
				</el-pagination>
			</div>
		</div>
		<van-overlay :show="qr_show">
			<div class="wrapper">
				<div class="rule-popup-con">
					<div class="rule-popup-header">
						{{selectType == 10 ? '微信' : '支付宝'}}扫码
						<div class="close" @click="qr_show = false"></div>
					</div>
					<div class="rule-popup-main">
						<div class="img-con qr-code">
							<img style="width: 100%;height: 100%;" :src="qr_code" alt="">
						</div>
						<div class="qr-tit">请使用{{selectType == 10 ? '微信' : '支付宝'}}扫码支付</div>
					</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="G_Show">
			<div class="wrapper">
				<div class="rule-popup-con">
					<div class="rule-popup-header">
						充值须知
						<div class="close" @click="G_Show = false"></div>
					</div>
					<div class="rule-popup-main" style="height: 458px;">
						<div class="rule-popup-content" style="height: 350px;overflow: auto;line-height: 20px;">
							<div style="color: #FFB800;text-align: center;margin-bottom: 20px;">请阅读以下协议，点击同意即可前往充值</div>
							<div>{{ content }}</div>
						</div>
						<div class="agree-btn" @click="ToRecharge">我已阅读并同意该协议</div>
					</div>
				</div>
			</div>
		</van-overlay>
		<div v-html="payHtml" style="position: fixed;z-index: -1;opacity: 0;"></div>
		<van-overlay :show="openReal">
			<div class="wrapper">
				<div class="real-con">
					<div class="close" @click="openReal=false"></div>
					<div class="real-main" v-show="realState==2">
						<div class="row">
							<div class="label">请输入您的名字</div>
							<div class="input">
								<input type="text" v-model="name">
							</div>
						</div>
						<div class="row">
							<div class="label">请输入您的身份证</div>
							<div class="input">
								<input type="text" v-model="id_num">
							</div>
						</div>
						<div class="row">
							<div class="label">请输入验证码</div>
							<div class="input">
								<input type="text" v-model="verify">
								<van-button class="code" size="small" type="primary" v-show="VerCodeShow == false" @click='VerCodeSend(3)'>发送验证码</van-button>
								<van-button class="code disabled" size="small" type="primary" v-show="VerCodeShow == true">
									<van-count-down millisecond :time="Time" format="ss" :auto-start="false" @finish="finish" ref="countDown" />
								</van-button>
							</div>
						</div>
						<div class="btn-con">
							<div class="btn big" @click="realname">提交认证</div>
						</div>
					</div>
					<div class="real-main" v-show="realState==1">
						<div class="word">
							本网站禁止未成年人使用，请完成<br />实名身份认证，完成认证奖励
						</div>
						<div class="price">
							<div class="coins"></div>{{ publicConfig.realname_award }}
						</div>
						<div class="btn-con">
							<div class="btn cancel" @click="openReal=false">稍后认证</div>
							<div class="btn" @click="realState=2">立即认证</div>
						</div>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {
		Beans,
		RechargeRecord,
		CardRecharge,
		PostRecharge,
		realname,
		PostSendSms
	} from '@/network/api.js'
	import {
		mapState
	} from 'vuex'
	import QRCode from 'qrcode';
	export default {
		name: 'Recharge',
		data() {
			return {
				active: 0, //tab分栏
				BeanList: [], //金豆列表
				pay_way: '1', //支付类别,1支付宝,2微信
				CardCode:'',//卡密兑换码
				RecordList: [], //充值记录

				total:0,//总数据数量
				per_page:0,//一页数据
				page_size:1,//页码
				selectId: '',
				qr_code: '',
				qr_show: false,
				payHtml: '',
				selectType: 10,
				G_Show: false,
				content: `1.我已满18岁具备完全行为能力，且当前账号为本人操作。

2.本人仅在经济承受范围内消费，且将为消费行为承担后果，继续充值即代表同意《用户协议》和《隐私协议》。

3.购买虚拟物品成功后不可退还或换货，知晓取回的虚拟道具将在5min-24h内发货，且获取的虚拟道具禁止转售。

4.网站玩法以娱乐为主，请保持理性消费。

5.网站充值的金额用于购买开启饰品宝箱所需碎片。
`,
				openReal: false,
				name: '',
				id_num: '',
				verify: '',
				realState: 1,
				VerCodeShow: false, //验证码/倒计时
				Time: 60 * 1000, //倒计时时间
			}
		},
		created() {
			this.GetBeans()
			this.GetRechargeRecord()
		},
		computed: {
			...mapState(['User', 'publicConfig']),
		},
		methods: {
			//验证码发送
			VerCodeSend() {
				this.VerCodeShow = true
				this.$refs.countDown.start();
				PostSendSms(this.User.mobile, '4').then((res) => {
					this.$notify({
						type: 'success',
						message: '发送成功'
					})
				})
			},
			//重置倒计时
			finish() {
				this.VerCodeShow = false
				this.$refs.countDown.reset();
			},
			realname(){
				let params = {
					name: this.name,
					id_num: this.id_num,
					verify: this.verify
				}
				realname(params).then((res) => {
					this.$notify({
						type: 'success',
						message: '认证成功'
					})
					this.openReal = false
				})
			},
			//金豆列表
			GetBeans() {
				Beans().then((res) => {
					// console.log(res.data.data)
					this.BeanList = res.data.data
					this.selectId = res.data.data[0].id
				})
			},
			// 支付(携带参数跳转页面，防止拦截)
			
			ToRecharge() {
				this.G_Show = false
				if(!this.User.id){
					this.$notify({
						type: 'danger',
						message: '请先登录'
					})
					return false
				}
				PostRecharge(this.selectId, this.selectType ).then((res) => {
					if(res.data.data.needIdentity==1){
						this.$notify({
							type: 'danger',
							message: '充值需要实名认证'
						})
						this.openReal = true
						return false
					}
					if(this.selectType == 3){
						this.payHtml = res.data.data.html
						this.$nextTick(() => {
							document.forms['0'].submit();  //重点--这个才是跳转页面的核心,获取第一个表单并提交
						});
					}else if(this.selectType == 6){
						if(this._isMobile()){
							location.replace(res.data.data.list.h5)
						}else{
							this.qr_code = res.data.data.list.qr_url
							this.qr_show = true
						}
					}else if(this.selectType == 10){
						// if(this._isMobile()){
						// 	location.replace(res.data.data.list)
						// }else{
						// 	QRCode.toDataURL(res.data.data.list).then(resUrl => {
						// 		this.qr_code = resUrl
						// 	});
						// 	this.qr_show = true
						// }
						QRCode.toDataURL(res.data.data.list).then(resUrl => {
							this.qr_code = resUrl
						});
						this.qr_show = true
					}else if(this.selectType == 11){
						// alert(res.data.data.list)
						if(this._isMobile()){
							location.replace(res.data.data.list)
						}else{
							window.open(res.data.data.list)
						}
					}
				})
			},
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag
			},
			//卡密购买
			ToCard(v){
				// console.log(v)
				window.open(v,'_self')
			},

			PustCardRecharge(){
				CardRecharge(this.CardCode).then((res) => {
					// console.log(res)
					this.$notify({
						type: 'success',
						message: res.data.message
					})
				})
			},

			//充值记录
			GetRechargeRecord() {
				RechargeRecord(this.page_size).then((res) => {
					// console.log(res.data.data)
					this.total = res.data.data.total
					this.per_page = res.data.data.per_page
					this.RecordList = res.data.data.data
				})
			},
			//充值记录分页
			handleCurrentChange(val) {
				// console.log(val)
				this.page_size = val
				this.GetRechargeRecord()
			}
		},

		watch: {
			active(v) {
				// console.log(v)
				this.pay_way = v + 1
				// console.log(this.pay_way)
			}
		}

	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.recharge-title{
		position: relative;
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
		height: 40px;
		@include HScenter;
		span{
			font-size: 12px;
			color: #FFB800;
		}
		&:before{
			content: '';
			position: absolute;
			left: 1px;
			top: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
		&:after{
			content: '';
			position: absolute;
			right: 1px;
			bottom: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
	}
	.recharge-list{
		width: 330px;
		margin: auto;
		display: flex;
		flex-wrap: wrap;
		gap: 12px 15px;
		padding-top: 20px;
		.item{
			width: 100px;
			height: 42px;
			@include HScenter;
			background: #1D1D28;
			font-size: 17px;
			font-weight: bold;
			box-sizing: border-box;
			cursor: pointer;
			.icon-coins{
				width: 20px;
				height: 20px;
				flex-shrink: 0;
				background-image: url(~@/assets/img/coins-yellow.svg);
				background-size: 100% 100%;
				margin-right: 6px;
			}
			&.active{
				border: 1px solid #FFB800;
				background: rgba(255, 184, 0, 0.15);
				color: #FFB800;
				.icon-coins{
					// background-image: url(~@/assets/img/icon-coins-big-active.png);
				}
			}
		}
	}
	.recharge-type{
		@include Scenter;
		width: 330px;
		margin: 18px auto 7px;
		background: #1D1D28;
		height: 40px;
		padding: 0 16px;
		.right{
			@include Scenter;
			margin-left: auto;
			img{
				width: 14px;
				height: 14px;
				margin-right: 4px;
			}
		}
	}
	.recharge-tit{
		font-size: 12px;
		width: 330px;
		margin: 0 auto;
	}
	.recharge-btn{
		width: 275px;
		height: 56px;
		background-image: url(~@/assets/img/open-btn-bg.png);
		background-size: 100% 100%;
		margin: 12px auto;
		@include HScenter;
		font-size: 17px;
		cursor: pointer;
	}
	.recharge-record{
		.record_item{
			display: flex;
			overflow: hidden;
			color: #ddd;
			margin-top: 14px;
			&.th{
				color: #FFB800;
			}
			p{
				padding-left: 20px;
				box-sizing: border-box;
				flex-shrink: 0;
				&:nth-child(1){
					width: 20%;
				}
				&:nth-child(2){
					width: 20%;
				}
				&:nth-child(3){
					width: 40%;
				}
				&:nth-child(4){
					flex: 1;
				}
			}
		}
	}
	.page-con{
		padding: 20px 0;
	}
	.qr-code{
		width: 200px;
		height: 200px;
		margin: 0 auto;
	}
	.qr-tit{
		text-align: center;
		font-size: 12px;
		color: #bbb;
		margin-top: 12px;
	}
	.recharge-type-list{
		@include Scenter;
		gap: 14px;
		margin: 15px auto 0px;
		width: 330px;
		white-space: nowrap;
		.item{
			line-height: 20px;
			cursor: pointer;
			@include Scenter;
			img{
				width: 12px;
				height: 12px;
				margin-right: 4px;
			}
			&.active{
				position: relative;
				&::before{
					content: '';
					position: absolute;
					left: 10%;
					right: 10%;
					bottom: -5px;
					height: 2px;
					background: #FFB800;
				}
			}
		}
		.integral-btn{
			margin-left: auto;
			color: #FFB800;
			text-decoration: underline;
			cursor: pointer;
		}
	}
	.agree-btn{
		width: 257px;
		height: 51px;
		background-image: url(~@/assets/img/agree-btn-bg.png);
		background-size: 100% 100%;
		@include HScenter;
		margin: 10px auto 0;
		cursor: pointer;
	}
	.real-con{
		width: 364px;
		height: 241px;
		flex-shrink: 0;
		padding-top: 36px;
		position: relative;
		background-size: 100% 100%;
		background-image: url(~@/assets/img/realname-bg.png);
		.close{
			width: 24px;
			height: 24px;
			position: absolute;
			top: 3px;
			right: 12px;
			background-image: url(~@/assets/img/rule-close.png);
			background-size: 100% 100%;
			cursor: pointer;
		}
		.real-main{
			height: 198px;
			@include HScenter;
			flex-direction: column;
		}
		.row{
			@include Scenter;
			margin: 0 0 16px;
			.label{
				width: 120px;
				font-weight: bold;
				color: #000;
			}
			.input{
				width: 170px;
				height: 22px;
				display: flex;
				border-radius: 4px;
				background: #CCCCD3;
				box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) inset;
				input{
					height: 100%;
					color: #000;
					width: 100%;
					padding: 0 10px;
				}
				.code{
					font-size: 12px;
					border-radius: 4px;
					background: #E3B66D;
					box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) inset;
					width: 76px;
					height: 100%;
					@include HScenter;
					flex-shrink: 0;
					color: #000;
					cursor: pointer;
					border: none;
					&.disabled{
						filter: grayscale(1);
					}
				}
			}
			
		}
		.btn-con{
			margin-top: 10px;
			display: flex;
			gap: 33px;
			.btn{
				background-size: 100% 100%;
				color: #000;
				width: 98px;
				height: 36px;
				background-image: url(~@/assets/img/realname-btn.png);
				@include HScenter;
				cursor: pointer;
				&.big{
					width: 192px;
					height: 36px;
					background-image: url(~@/assets/img/realname-btn-big.png);
				}
				&.cancel{
					background-image: url(~@/assets/img/realname-btn-cancel.png);
				}
			}
		}
		.price{
			color: #000;
			margin-top: 12px;
		}
		.word{
			color: #000;
		}
	}
	.van-button__text{
		white-space: nowrap;
	}
</style>
