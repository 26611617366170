<template>
	<div id="BroadSide">
		<div class="BroadSide-con" :class="{ 'on': !Switch }">
			<div class="switch" @click="changeHide">
				<van-icon :name="IconName" color="#fff" />
			</div>
			<div class="content">
				<div class="Red_env">
					<img src="@/assets/img/icon-welfare.png" alt="" @click="RouteJump('/Welfare')">
					<span>福利<br />中心</span>
				</div>
				<div class="Red_env" v-if="publicConfig && publicConfig.sign_in_activity_on">
					<img src="@/assets/img/icon-side5.png" alt="" @click="openSign=true">
					<span>签到</span>
				</div>
				<div class="Red_env">
					<img src="@/assets/img/icon-side1.png" alt="" @click="Changeredshow">
					<span>红包</span>
				</div>
				<!-- <div class="Red_env">
					<img src="@/assets/img/icon-side2.png" alt="" @click="huixie = true">
					<span>回血</span>
				</div> -->
				<div class="Red_env" @click="RouteJump('/Extension')">
					<img src="@/assets/img/icon-side3.png" alt="">
					<span>推广</span>
				</div>
				<div class="Red_env" @click="kefu">
					<img src="@/assets/img/icon-side4.png" alt="">
					<span>群聊</span>
				</div>
				<!-- <div class="Red_env" @click="goPackage">
					<img src="@/assets/img/icon-package.png" alt="">
					<span>背包</span>
				</div> -->
				<div class="Red_env" @click="toTop">
					<img src="@/assets/img/icon-top.png" alt="">
					<span>顶部</span>
				</div>
			</div>

			
		</div>
		<van-overlay :show="redshow" :lock-scroll="false">
			<div class="wrapper">
				<div class="red-con">
					<div class="red-close" @click="redshow=false"></div>
					<div class="red-top">
						<div>联系客服获取口令红包</div>
						<div>加群<span>602593290</span>，不定期发放红包福利</div>
					</div>
					<input class="red-input" type="text" placeholder="请输入红包口令" v-model="Red_key">
					<img class="red-open" @click="Exactly('2')" src="~@/assets/img/red-open.png" alt="">
					<div class="red-title">
						<span>红包活动</span>
					</div>
					<div class="scroll-con">
						<el-scrollbar style="height:100%;">
							<div class="red-list">
								<div class="item" v-for="(item, index) in Red_data" :key="index">
									<div class="name"><span>{{ item.title }}</span></div>
									<div class="desc">(参与条件：{{ item.briefe }})</div>
									<div class="open" @click="Exactly('1', item.id)"></div>
									<div class="time">结束时间:{{ item.end_time }}</div>
								</div>
							</div>
						</el-scrollbar>
					</div>
				</div>
				<!-- <div class="red_box" @click.stop>
					<van-notice-bar background="#b5462c" color="#fff" left-icon="volume-o" scrollable :text="site_bulletin"
						:key="key" />
					<div class="qq">加群<span>602593290</span>,不定期发放红包福利</div>
					<input type="text" placeholder="请输入红包口令" v-model="Red_key">
					<div class="red_open" @click="Exactly('2')">
						<img src="@/assets/images/BroadSide/open.png" alt="">
					</div>
					<div class="red_tit"><img src="@/assets/images/BroadSide/red_tit.png" alt=""></div>
					<div class="red_activity">
						<div class="red_item" v-for="(item, index) in Red_data" :key="index">
							<div class="red_item_top">
								<div class="red_img">
									<p>剩余数量</p>
									<span>{{ item.remainder }}</span>
								</div>
								<div class="red_data">
									<b>{{ item.title }}</b>
									<p>参与条件:{{ item.briefe }}</p>
									<span>结束时间:{{ item.end_time }}</span>
								</div>
							</div>
							<div class="red_btn" @click="Exactly('1', item.id)">抢红包</div>
						</div>
					</div>
					<div class="red_rule">口令红包规则</div>
				</div> -->
			</div>
		</van-overlay>

		<van-overlay :show="quanmin" :lock-scroll="false" @click="quanmin = false">
			<div class="wrapper">
				<div class="red_box" style="width: 363px;height: auto;background: none;">
					<img style="width: 100%;" src="@/assets/img/daili-img.png" alt="">
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="huixie" :lock-scroll="false" @click="huixie = false">
			<div class="wrapper">
				<div class="red_box" style="height: auto;background-color: transparent;">
					<img style="width: 100%;" src="@/assets/images/Rank/huixie-banner.png" alt="">
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="dialog" :lock-scroll="false" @click="dialog = false">
			<div class="wrapper">
				<div class="red-get-con">
					<img class="title" src="~@/assets/img/red-get-title.png" alt="">
					<div class="price">
						<div class="coins"></div>
						{{ bean }}
					</div>
					<img class="btn" @click="dialog = false" src="~@/assets/img/red-get-btn.png" alt="">
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="luckDialog" :lock-scroll="false" @click="luckDialog = false">
			<div class="wrapper">
				<div class="red-get-con">
					<template v-if="luckData.type==1 || (luckData.type==2 && luckData.status==0)">
						<img class="title" src="~@/assets/img/red-get-title.png" alt="">
						<div class="price">
							<div class="coins"></div>
							{{ luckData.bean }}
						</div>
					</template>
					<template v-else>
						<img v-if="luckData.status==1" class="fail-title" src="~@/assets/img/red-fail-title1.png" alt="">
						<img v-if="luckData.status==2" class="fail-title" src="~@/assets/img/red-fail-title2.png" alt="">
						<img v-if="luckData.status==3" class="fail-title" src="~@/assets/img/red-fail-title3.png" alt="">

						<img v-if="luckData.status==1" class="face" src="~@/assets/img/red-get-received.png" alt="">
						<img v-else class="face" src="~@/assets/img/red-get-fail.png" alt="">
					</template>
					<img class="btn" @click="openLuckRecord" src="~@/assets/img/red-get-record.png" alt="">
					<div class="close" @click="luckDialog = false"></div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="luckRecordDialog" :lock-scroll="false" @click="luckRecordDialog = false">
			<div class="wrapper">
				<div class="red-record-con">
					<div class="red-top">
						<div class="title">领取记录</div>
						<div class="right">
							领取<span>{{ luckRecordData.received }}/{{ luckRecordData.total }}</span>
						</div>
					</div>
					<div class="list">
						<div class="item" v-for="(item, index) in luckRecord" :key="item.id">
							<img :src="item.user.avatar" alt="">
							<div class="name">{{ item.user.name }}</div>
							<div class="price">
								<div class="coins"></div>
								{{ item.bean }}
							</div>
						</div>
					</div>
					<div class="close" @click="luckRecordDialog=false"></div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="openSign" :lock-scroll="false">
			<div class="wrapper">
				<div class="sign-con">
					<div class="close" @click="openSign = false"></div>
					<img src="~@/assets/img/sign-top.png" alt="" class="sign-top">
					<div class="sign-list">
						<div class="item" v-for="(item,index) in week_tasks" :key="index" @click="signFinish(item)">
							<div class="day">{{ item.title }}</div>
							<div class="name" v-if="index<6">{{ item.type==2 ? item.award.name : 'W币' }}</div>
							<template v-if="item.type==2">
								<div class="img-con">
									<img :src="item.award.cover" alt="">
								</div>
								<div class="award-name" v-if="index==6">{{ item.award.name }}</div>
							</template>
							<template v-else>
								<div class="coins-bag"></div>
							</template>
							<div class="num" v-if="!(index==6 && item.type==2)">
								{{ item.type==2 ? 'X1' : '随机福袋' }}
								<!-- <div class="coins" v-if="item.type==1"></div> -->
							</div>
							<div class="btn" :class="{'disabled': item.progress.finish}">{{ item.progress.finish ? '已领取' : '立即领取' }}</div>
						</div>
					</div>
					<div class="sign-bottom">
						<div class="sign-total-list">
							<div class="item" :class="{'finish': item.progress.finish}" v-for="(item,index) in month_tasks" :key="index" @click="signFinish(item)">
								<div class="img-con">
									<img v-if="item.type==2" :src="item.award.cover" alt="">
									<img v-else src="~@/assets/img/sign-get-bag.png" alt="">
									<div class="num" :class="{'bean': item.type==1}"><span>{{ item.type==2 ? 'X1' : '随机福袋' }}</span></div>
								</div>
								<div class="btn">{{ item.title }}</div>
							</div>
						</div>
						<div class="desc">十二月已累计签到 {{ signData.sign_in_days }} 天，月累计签到还能获取更多福利哦~~</div>
					</div>
					<div class="sign-rule" @click="openSignRule=true">签到规则</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="openSignGet" :lock-scroll="false">
			<div class="wrapper">
				<div class="sign-get-con">
					<div class="get-title">恭喜获得</div>
					<div class="close" @click="openSignGet=false"></div>
					<div class="get-con">
						<div class="img-con">
							<img v-if="getAward.award" :src="getAward.award.cover" alt="">
							<img v-else src="~@/assets/img/sign-get-bag.png" alt="">
						</div>
						<div class="name">
							{{ getAward.award ? getAward.award.name : 'W币' }}
						</div>
						<div class="price">
							<div class="coins"></div>
							{{ getAward.award ? getAward.award.bean : getAward.bean }}
						</div>
					</div>
					<div class="desc">{{ getAward.award ? '已放入您的背包' : '已放入您的余额' }}</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="openSignRule" :lock-scroll="false">
			<div class="wrapper">
				<div class="sign-get-con rule">
					<div class="get-title">签到规则</div>
					<div class="close" @click="openSignRule=false"></div>
					<div class="sign-rule-con">
						{{ signData.desc || '' }}
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import { Red, Red_open, Red_open_key, Red_luck_records, ApiInfo, signinInfo, signinFinish } from '@/network/api.js'
import { mapState, mapActions } from 'vuex'
export default {
	name: 'BroadSide',
	data() {
		return {
			Switch: true, //切换
			RightSty: 0, //偏移值
			IconName: 'arrow', //vant icon name
			redshow: false, //红包内容显示
			site_bulletin: '',//公告
			key: 0,
			dialog: false, //dialog显示隐藏
			boom: new Audio(require('@/assets/audio/boom.aac')), //红包声音
			Red_data: [],//红包列表
			
			Red_key: '',//红包口令
			quanmin: false,//红包口令
			huixie: false,
			openSign: false,
			openSignGet: false,
			openSignRule: false,
			signData: {

			},
			month_tasks: [],
			week_tasks: [],
			getAward:{
				bean: '1.00',
				award: null
			},
			bean: 0,//红包
			luckDialog: false,
			luckData: {
				bean: 0,
				type: 1,
				status: 0
			},
			luckRecord: [],
			luckRecordData: {
				received: 0,
				total: 10
			},
			luckRecordDialog: false
		}
	},
	computed: {
		...mapState(['User', 'publicConfig'])
	},
	watch: {
		//切换
		Switch(v) {
			if (v == true) {
				this.RightSty = 0
				this.IconName = 'arrow'
			} else {
				this.RightSty = -34
				this.IconName = 'arrow-left'
			}
		},
		openSign(val){
			val && this.getSigninInfo()
		}
	},
	created() {
		this.GetRed()
		this.GetApiInfo()
		if(sessionStorage.getItem('toolBarClose')){
			if(sessionStorage.getItem('toolBarClose')=='1'){
				this.Switch = true;
			}else{
				this.Switch = false;
			}
		}
	},

	methods: {
		...mapActions([
			'Refresh'
		]),
		openLuckRecord(){
			Red_luck_records(this.Red_key).then((res) => {
				this.luckRecord = res.data.data.records
				this.luckRecordData = {
					received: res.data.data.received,
					total: res.data.data.total
				},
				this.luckDialog = false
				this.luckRecordDialog = true
			})
		},
		signFinish(data){
			signinFinish({name: data.name}).then(res=>{
				this.getAward = res.data.data
				this.openSignGet = true
				this.getSigninInfo()
				this.Refresh()
			})
		},
		getSigninInfo(){
			signinInfo().then(res=>{
				this.signData = res.data.data
				this.month_tasks = res.data.data.month_tasks
				this.week_tasks = res.data.data.week_tasks
			})
		},
		goPackage(){
			if(!this.User.id){
				this.$notify({
					type: 'danger',
					message: '请登录后查看'
				})
			}else{
				this.$router.push('/Warehouse')
			}
		},
		toTop(){
			window.scrollTo(0,0)
		},
		//Nav路由跳转
		RouteJump(str) {
			if (str == this.$route.fullPath) {
				this.$router.go(0)
			} else {
				this.$router.push(str)
			}

		},
		kefu() {
			window.open('https://qm.qq.com/cgi-bin/qm/qr?k=BLCyl8SujqD9EIItKMP0tGuMj116-y2_&jump_from=webapi&authKey=jP759Kle43e7HSOcjW01WGGyOAwQ6eD8/WvGkdim42YpU6g40ws3Rtsz/LUgrlKG')
		},
		//获取站点信息
		GetApiInfo() {
			ApiInfo().then((res) => {
				// console.log(res.data.data.site_bulletin)
				this.site_bulletin = res.data.data.site_bulletin
			})
		},

		// 获取红包列表
		GetRed() {
			Red().then((res) => {
				// console.log(res.data.data)
				this.Red_data = res.data.data
			})
		},

		//红包内容显示
		Changeredshow() {
			this.key += 1
			this.redshow = true
		},


		//开启红包
		Exactly(v, id) {
			if (v == '1') {
				Red_open(id).then((res) => {
					this.bean = res.data.data.bean
					this.boom.play()
					this.redshow=false
					this.dialog = true

				})
			} else {
				Red_open_key(this.Red_key).then((res) => {
					this.redshow=false
					this.boom.play()
					if(res.data.data.type == 1){
						this.bean = res.data.data.bean
						this.dialog = true
					}else{
						this.luckData = res.data.data
						this.luckDialog = true
					}
				})
			}

		},
		changeHide(){
      this.Switch = !this.Switch;
      if(this.Switch){
        sessionStorage.setItem('toolBarClose','1')
      }else{
        sessionStorage.setItem('toolBarClose','0')
      }
    },

	},
}
</script>

<style lang="scss">
@import "@/assets/css/base.scss";
#BroadSide {
	position: fixed;
	left: 50%;
	top: 0;
	bottom: 0;
	width: 375px;
	transform: translateX(-50%);
	overflow: hidden;
	pointer-events: none;
	z-index: 1111;
	.van-overlay{
		pointer-events: visible;
	}
	.BroadSide-con {
		position: absolute;
		right: 2px;
		top: 50%;
		// margin-top: -100px;
		// transform: translateY(-50%);
		z-index: 10;
		display: flex;
		align-items: center;
		transition: .3s;
		transform: translateY(-50%) scale(0.8);
		transform-origin: right;
		pointer-events: visible;
		background: url(~@/assets/img/side-bg.png);
		background-size: 100% 100%;
		&.on {
			right: -40px;
		}

		.switch {
			width: 16px;
			height: 120px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			@include HScenter;
			.van-icon{
				font-size: 20px;
				flex-shrink: 0;
			}
		}

		.content {
			/*width: 60px;*/
			// height: 80px;
			// background: #131426;
			border-radius: 6px 0 0 6px;
			padding: 5px;
			/*justify-content: center;*/

			.Red_env {
				width: 36px;
				margin-left: 2px;
				text-align: center;
				color: #fff;
				margin: 2px 2px;
				cursor: pointer;

				img {
					width: 100%;
				}
			}
		}

		.van-overlay {
			.wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;

				.red_box {
					width: 260px;
					height: 410px;
					background: #c02932 url(../../assets/images/BroadSide/Red_top.png) no-repeat top;
					background-size: 100%;
					border-radius: 6px;
					position: relative;

					&::after {
						display: block;
						content: '';
						width: 50px;
						height: 200px;
						background: url(../../assets/images/BroadSide/decorateleft.png) no-repeat top;
						background-size: contain;
						position: absolute;
						left: -10px;
						top: -15px;
					}

					&::before {
						display: block;
						content: '';
						width: 50px;
						height: 200px;
						background: url(../../assets/images/BroadSide/decorateright.png) no-repeat top;
						background-size: contain;
						position: absolute;
						right: -10px;
						top: -15px;
					}

					.van-notice-bar {
						height: 30px;
						width: 80%;
						border-radius: 10px;
						margin: 10px auto;
					}

					.qq {
						text-align: center;
						color: #fff;
						font-size: 14px;

						span {
							color: #fcb744;
							font-weight: bold;
						}
					}

					input {
						display: block;
						margin: 10px auto;
						width: 180px;
						height: 28px;
						border-radius: 6px;
						border: none;
						background: #fffad8;
						padding: 0 10px;
						box-sizing: border-box;
						text-align: center;
						font-size: 14px;
						color: #dbb389;

					}

					input::-webkit-input-placeholder {
						color: #dbb389;
					}

					input::-ms-input-placeholder {
						color: #dbb389;
					}

					input::-moz-input-placeholder {
						color: #dbb389;
					}

					.red_open {
						width: 40px;
						height: 40px;
						margin: 0 auto;
						background: #fcb744;
						border-radius: 50%;
						border: 4px solid #fca714;
						box-sizing: border-box;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							max-width: 70%;
							max-height: 70%;
							vertical-align: middle;
						}
					}

					.red_tit {
						text-align: center;
						height: 12px;
						margin: 10px auto;

						img {
							height: 100%;
						}
					}

					.red_activity {
						width: 100%;
						height: 180px;
						overflow-y: auto;

						.red_item {
							width: 94%;
							margin: 0 auto 10px;
							background: #ad252c;
							border-radius: 4px;
							padding: 10px;
							box-sizing: border-box;

							.red_item_top {
								width: 100%;
								height: 70px;
								display: flex;
								justify-content: space-between;

								.red_img {
									width: 70px;
									height: 70px;
									background: url(../../assets/images/BroadSide/activity_img.png) no-repeat center;
									background-size: contain;
									padding-top: 36px;
									box-sizing: border-box;
									text-align: center;
									color: #ff8975;
									font-size: 12px;

									span {
										color: #f5ba7f;
									}
								}

								.red_data {
									width: 150px;
									color: #ff8975;
									font-size: 12px;

									b {
										font-size: 14px;

									}

									p {
										margin-bottom: 2px;
									}

									span {
										font-size: 10px;
									}
								}
							}

							.red_btn {
								width: 100%;
								height: 30px;
								margin-top: 5px;
								background: #bd3840;
								border-radius: 20px;
								text-align: center;
								line-height: 30px;
								color: #ffc700;
								font-size: 14px;
								font-weight: bold;
							}
						}
					}

					.red_rule {
						text-align: center;
						margin-top: 10px;
						color: #feb5a3;
						font-size: 12px;
					}

				}
			}
		}

		.van-dialog {
			z-index: 10002 !important;
			width: 300px;
			background: none;

			.genus {
				width: 100%;
				height: 500px;
				background: url(../../assets/images/BroadSide/genus.png) no-repeat center;
				background-size: contain;
				// padding-top: 400px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				.amount {
					width: 160px;
					margin: 0 auto;
					height: 90px;
					font-size: 42px;
					font-weight: bold;
					display: flex;
					justify-content: center;
					align-items: center;
					color: transparent;
					color: #ffcf72;
					text-shadow: 1px 1px 2px #FF0000;
				}

				.genus_btn {
					width: 100px;
					height: 30px;
					text-align: center;
					line-height: 32px;
					font-size: 20px;
					font-weight: bold;
					margin: 0 auto;
					color: #ed3e26;
					border-radius: 20px;
					background-image: linear-gradient(#ebfd01, #fbd31e);
					cursor: pointer;
				}
			}
		}


	}
}

	.red-con{
		background: url(~@/assets/img/red-bg.png);
		background-size: 100%;
		position: relative;
		width: 375px;
		height: 621px;
		margin-top: -40px;
		@include Scenter;
		flex-direction: column;
		padding-top: 70px;
		.red-close{
			position: absolute;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%);
			width: 29px;
			height: 29px;
			background: url(~@/assets/img/red-close.png);
			background-size: 100% 100%;
			cursor: pointer;
		}
		.red-top{
			text-align: center;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			text-decoration-line: underline;
			span{
				color: #FAFF00;
			}
		}
		.red-input{
			width: 250px;
			height: 42px;
			text-align: center;
			border-radius: 10px;
			background: rgba(0, 0, 0, 0.34);
			margin-top: 10px;
		}
		.red-open{
			width: 69px;
			height: 69px;
			margin-top: 6px;
			cursor: pointer;
		}
		.red-title{
			margin-top: 6px;
			span{
				position: relative;
				padding: 0 2px;
				&::before{
					content: '';
					position: absolute;
					left: -30px;
					top: 50%;
					height: 1px;
					width: 30px;
					background: linear-gradient(270deg, #EED2D4 0%, rgba(255, 255, 255, 0.00) 93.33%);
				}
				&::after{
					content: '';
					position: absolute;
					right: -30px;
					top: 50%;
					height: 1px;
					width: 30px;
					background: linear-gradient(90deg, #EED2D4 0%, rgba(255, 255, 255, 0.00) 93.33%);
				}
			}
		}
		.scroll-con{
			width: 288px;
			height: 290px;
			margin-top: 10px;
			.el-scrollbar__wrap{
				overflow-x: hidden;
			}
		}
		.red-list{
			display: flex;
			flex-direction: column;
			gap: 6px;
			.item{
				@include Scenter;
				flex-direction: column;
				background: url(~@/assets/img/red-item-bg.png);
				background-size: 100% 100%;
				height: 138px;
				color: #E4313E;
				.name{
					margin-top: 14px;
					font-size: 16px;
					font-weight: bold;
					span{
						position: relative;
						padding: 0 2px;
						&::before{
							content: '';
							position: absolute;
							left: -15px;
							top: 50%;
							height: 2px;
							width: 15px;
							background: linear-gradient(270deg, #E4313E 0%, rgba(228, 49, 62, 0.00) 93.33%);
						}
						&::after{
							content: '';
							position: absolute;
							right: -15px;
							top: 50%;
							height: 2px;
							width: 15px;
							background: linear-gradient(90deg, #E4313E 0%, rgba(228, 49, 62, 0.00) 93.33%);
						}
					}
				}
				.desc{

				}
				.open{
					width: 162px;
					height: 38px;
					background: url(~@/assets/img/red-item-open.png);
					background-size: 100% 100%;
					margin-top: 5px;
					cursor: pointer;
				}
				.time{
					font-size: 12px;
					margin-top: 9px;
				}
			}
		}
	}
	.red-get-con{
		background: url(~@/assets/img/red-get-bg.png);
		background-size: 100% 100%;
		width: 283px;
		height: 344px;
		@include Scenter;
		flex-direction: column;
		padding-top: 68px;
		position: relative;
		.close{
			position: absolute;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%);
			width: 29px;
			height: 29px;
			background: url(~@/assets/img/red-close.png);
			background-size: 100% 100%;
			cursor: pointer;
		}
		.title{
			width: 108px;
		}
		.price{
			font-size: 30px;
			line-height: 36px;
			@include Scenter;
			color: #FF003D;
			margin-top: 24px;
			font-weight: bold;
			.coins{
				width: 30px;
				height: 30px;
				background-image: url(~@/assets/img/coins-red.png);
			}
		}
		.fail-title{
			height: 29px;
			margin-top: -10px;
		}
		.face{
			width: 90px;
			height: 90px;
			margin: 14px 0 -30px;
		}
		.btn{
			width: 162px;
			margin-top: 101px;
			cursor: pointer;
		}
	}

	.sign-con{
		position: relative;
		.close{
			position: absolute;
			right: 0;
			top: 0;
			width: 28px;
			height: 28px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/sign-close.png);
			z-index: 2;
			cursor: pointer;
		}
		.sign-top{
			width: 357px;
			height: 121px;
			display: block;
			margin: 0 auto 4px;
			transform: translateX(10px);
		}
		.sign-list{
			display: flex;
			flex-wrap: wrap;
			gap: 2px;
			width: 340px;
			margin: 0 auto;
			.item{
				width: 83px;
				height: 166px;
				background-size: 100% 100%;
				background-image: url(~@/assets/img/sign-item.png);
				@include Scenter;
				flex-direction: column;
				padding-top: 8px;
				.day{
					font-size: 12px;
					color: #7C3500;
				}
				.img-con{
					width: 60px;
					height: 42px;
				}
				.name{
					@include wordhide;
					width: 70%;
					text-align: center;
					font-size: 12px;
					margin-top: 5px;
					margin-bottom: 3px;
				}
				.award-name{
					@include wordhide;
					width: 70%;
					text-align: center;
					color: #7C3500;
					font-size: 12px;
					margin-bottom: 3px;
				}
				.coins-bag{
					width: 45px;
					height: 42px;
					background-image: url(~@/assets/img/sign-get-coins.png);
					background-size: 100% 100%;
				}
				.num{
					// width: 60px;
					padding: 0 4px;
					min-width: 60px;
					height: 20px;
					border-radius: 10px;
					border: 1px dashed #D5884F;
					margin-top: 2px;
					@include HScenter;
					color: #7C3500;
					font-size: 12px;
					.coins{
						background-image: url(~@/assets/img/coins-yellow.svg);
						width: 12px;
						height: 12px;
					}
				}
				.btn{
					width: 71px;
					height: 24px;
					@include HScenter;
					font-size: 12px;
					background-size: 100% 100%;
					background-image: url(~@/assets/img/sign-item-btn.png);
					margin-top: 8px;
					cursor: pointer;
					&.disabled{
						background-image: url(~@/assets/img/sign-item-btn-disabled.png);
					}
				}
				&:nth-child(7){
					width: 164px;
					height: 164px;
					background-image: url(~@/assets/img/sign-item-big.png);
					.img-con{
						width: 83px;
						height: 56px;
						margin-top: 10px;
					}
					.award-name{
						width: 70%;
					}
					.coins-bag{
						transform: scale(1.3);
						margin: 20px 0 0px;
					}
					.btn{
						width: 97px;
						height: 27px;
						background-image: url(~@/assets/img/sign-item-btn-big.png);
						&.disabled{
							background-image: url(~@/assets/img/sign-item-btn-disabled-big.png);
						}
					}
				}
			}
		}
		.sign-bottom{
			width: 367px;
			background: url(~@/assets/img/sign-bottom.png) bottom center no-repeat;
			background-size: 100% auto;
			margin-top: 10px;
			.sign-total-list{
				display: flex;
				justify-content: center;
				gap: 26px;
				.item{
					position: relative;
					@include Scenter;
					flex-direction: column;
					&.finish{
						&::before{
							background: #D55B00;
						}
						.btn{
							background: #D55B00;
						}
					}
					&:last-child{
						&::before{
							display: none;
						}
					}
					&::before{
						content: '';
						position: absolute;
						width: 36px;
						left: 60px;
						height: 4px;
						background: #7C7C7C;
						bottom: 8px;
					}
					.img-con{
						width: 42px;
						height: 42px;
						border-radius: 8px;
						background: #D55B00;
						position: relative;
						overflow: hidden;
						cursor: pointer;
						&::before{
							content: '';
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							background-image: url(~@/assets/img/sign-gun-front.png);
							background-size: 100% 100%;
							width: 100%;
							height: 100%;
						}
						img{
							max-width: 90%;
							max-height: 90%;
							margin-top: -4px;
						}
						.num{
							position: absolute;
							right: 0;
							bottom: 0;
							background: linear-gradient(302deg, rgba(255, 255, 255, 0.66) 5.29%, rgba(255, 255, 255, 0.00) 124.72%);
							padding: 0 3px;
							font-size: 12px;
							color: #7C3500;
							border-radius: 4px 0 0 0;
							span{
								transform: scale(0.8);
								white-space: nowrap;
								display: block;
							}
							&.bean{
								left: 0;
								border-radius: 0;
								@include HScenter;
							}
						}
					}
					.btn{
						width: 62px;
						height: 18px;
						border-radius: 9px;
						background: #7C7C7C;
						font-size: 12px;
						margin-top: 7px;
						@include HScenter;
						position: relative;
						z-index: 2;
					}
				}
			}
			.desc{
				@include wordhide;
				font-size: 12px;
				line-height: 25px;
				text-align: center;
				width: 90%;
				margin: 10px auto 0;
			}
		}
		.sign-rule{
			text-decoration: underline;
			@include HScenter;
			margin-top: 5px;
			font-size: 12px;
			color: #BEBEBE;
			cursor: pointer;
		}
	}
	.sign-get-con{
		background-size: 100% 100%;
		background-image: url(~@/assets/img/sign-get-bg.png);
		@include Scenter;
		flex-direction: column;
		position: relative;
		padding-top: 16px;
		width: 211px;
		height: 251px;
		.get-title{
			color: #7C3500;
			margin-bottom: 10px;
		}
		.close{
			position: absolute;
			right: 12px;
			top: 9px;
			width: 14px;
			height: 14px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/sign-close1.png);
			cursor: pointer;
		}
		.get-con{
			@include HScenter;
			flex-direction: column;
			margin: 0 auto;
			width: 181px;
			height: 152px;
			.img-con{
				width: 97px;
				height: 71px;
				img{

				}
			}
			.name{
				@include wordhide;
				color: #7C3500;
				margin-bottom: 5px;
			}
			.price{
				@include Scenter;
				color: #7C3500;
				.coins{
					width: 14px;
					height: 14px;
					margin-right: 4px;
				}
			}
		}
		.desc{
			color: #D65D04;
			text-align: center;
			margin-top: 12px;
		}
		&.rule{
			width: 219px;
			height: 255px;
			background-image: url(~@/assets/img/sign-rule-bg.png);
			.sign-rule-con{
				width: 180px;
				height: 150px;
				overflow: auto;
				padding: 0 10px;
				font-size: 12px;
				line-height: 20px;
				white-space: pre-line;
				color: #7C3500;
				margin-top: 5px;
				//滚动条宽度
				&::-webkit-scrollbar {
					width: 3px;
					height: 3px;
				}
				/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
				&::-webkit-scrollbar-track {
					background: rgba(255, 255, 255, 0.1);
				}
				//滚动条设置
				&::-webkit-scrollbar-thumb {
					// border-radius: 5px;
					background: rgba(255, 255, 255, 0.3);
				}
			}
		}
	}
	.red-record-con{
		background: url(~@/assets/img/red-record-bg.png);
		background-size: 100% 100%;
		width: 299px;
		height: 346px;
		padding-top: 42px;
		position: relative;
		.close{
			position: absolute;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%);
			width: 29px;
			height: 29px;
			background: url(~@/assets/img/red-close.png);
			background-size: 100% 100%;
			cursor: pointer;
		}
		.red-top{
			@include Scenter;
			width: 220px;
			margin: 0 auto 10px;
			.title{
				background: linear-gradient(180deg, #FF7674 0%, #E41041 51.91%, #DF2E3E 100%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				font-weight: bolder;
			}
			.right{
				margin-left: auto;
				color: #333;
				font-size: 12px;
				span{
					color: #FF003D;
				}
			}
		}
		.list{
			width: 220px;
			height: 240px;
			overflow-y: auto;
			overflow-x: hidden;
			margin: 0 auto;
			//滚动条宽度
			&::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}
			/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
			&::-webkit-scrollbar-track {
				background: #FFD1D6;
				
			}
			//滚动条设置
			&::-webkit-scrollbar-thumb {
				background: #DE4C60;
			}
			.item{
				@include Scenter;
				height: 50px;
				width: 196px;
				margin: 0 auto;
				border-bottom: 1px solid #FFD1D6;
				img{
					width: 34px;
					height: 34px;
					border-radius: 50%;
				}
				.name{
					@include wordhide;
					color: #333;
					font-size: 12px;
					width: 72px;
					margin: 0 10px;
					flex-shrink: 0;
				}
				.price{
					color: #FF003D;
					.coins{
						background-image: url(~@/assets/img/coins-red.svg);
					}
				}
			}
		}
	}
</style>
