<template>
  <div id="Warehouse">
    <div class="recharge-title">
			<div class="icon-return" @click="$router.back()"></div>
      {{ nameCn }}
		</div>
    <div style="padding: 15px; white-space: pre-wrap;">
      <components :is="name" />
    </div>
  </div>
</template>

<script>
  import user from '@/components/Agreement/user.vue'
  import private1 from '@/components/Agreement/private.vue'
  import fcm from '@/components/Agreement/fcm.vue'
  import fdb from '@/components/Agreement/fdb.vue'
  import recharge from '@/components/Agreement/recharge.vue'
  import retrieve from '@/components/Agreement/retrieve.vue'
  import traffic from '@/components/Agreement/traffic.vue'
  import identity from '@/components/Agreement/identity.vue'
  export default {
    name: 'Article',
    components:{
      user,
      private1,
      fcm,
      fdb,
      recharge,
      retrieve,
      traffic,
      identity
    },
    data() {
      return {
        name: 'user',
        nameCn: '用户'
      }
    },
    created(){
      this.name = this.$route.query.type
      this.nameCn = this.$route.query.name
    },
    mounted() {
      
    },


    methods: {
      
    },
  }
</script>

<style scoped lang="scss">
  @import "@/assets/css/base.scss";
  .recharge-title{
		position: relative;
		border-top: 1px solid #D9D9D9;
		border-bottom: 1px solid #D9D9D9;
		height: 40px;
		@include HScenter;
		.icon-return{
			position: absolute;
			top: 50%;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
		span{
			font-size: 12px;
			color: #FFB800;
		}
		&:before{
			content: '';
			position: absolute;
			left: 1px;
			top: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
		&:after{
			content: '';
			position: absolute;
			right: 1px;
			bottom: 1px;
			width: 3px;
			height: 3px;
			background: #D9D9D9;
		}
	}
  .help-list{
    padding: 0 22px;
    .item{
      position: relative;
      border-bottom: 1px solid #59596B;
      height: 52px;
      @include Scenter;
      cursor: pointer;
      &::before{
        content: '';
        width: 20px;
        height: 20px;
        background-image: url(~@/assets/img/icon-right.png);
        background-size: 100% 100%;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
      }
    }
  }
</style>
