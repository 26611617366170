import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueFilter from '@/assets/js/filter.js'//公共过滤器

//px转rem
import 'lib-flexible'
//element按需引入
// import { Pagination } from 'element-ui';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
//vant
import Vant from 'vant'
import 'vant/lib/index.css'
//文本复制
import VueClipboard from 'vue-clipboard2'
for (let key in vueFilter){//公共过滤器
  Vue.filter(key,vueFilter[key])
}

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Vant)
// Vue.use(Pagination)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
