<template>
	<div>{{ context }}</div>
</template>
<script>
	export default {
		name: "back_top",
		data() {
			return {
				context:`1、交易链接如何更换？
抱歉，暂不支持更换为不同steam账号的交易链接
如遇到红锁、封禁等情况，请提供充足证明到网站右侧栏客服处进行申请更换！

2、交易链接解绑
抱歉！为了保护您的账号安全，暂不支持交易链接解绑！`,
			}
		},
		mounted() {
		},
		methods: {
			
		},
	}
</script>
<style scoped lang="scss">
	#back_top {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		font-size: 26px;
		background: #4854c9;
		position: fixed;
		z-index: 1000;
		bottom: 100px;
		right: 10px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
</style>
