<template>
	<div>{{ context }}</div>
</template>
<script>
	export default {
		name: "back_top",
		data() {
			return {
				context:`亲爱的玩家，您们好! 
为了维护纯净的网络环境，营造良好的氛围，满足用户们正常娱乐需求，WanSkins向广大用户告知如下:

1、WanSkins平台向用户提供的宝箱玩法，仅为了增加用户的趣味性、互动性，平台通过限制用户每日充值金额、抽宝箱的次数、金额、奖品兑换等措施防止涉嫌赌博。（各类宝箱的具体玩法请点击相关教程！）

2、WanSkins禁止用户在平台内、平台外通过任何方式、行为散布传播赌博信息，进行各种性质的赌博活动。WanSkins工作人员并不会主动向用户索要账号、密码等私密信息，请广大平台用户保护好账户密码安全，防止个人私密信息泄露或被他人盗用，以免被不法分子用于违法犯罪活动。

3、各位用户请勿在第三方交易平台进行WanSkins账号的充值或者进行WanSkins的虚拟货币、虚拟道具和账号交易或其他线下活动。

4、所有消息，均以WanSkins官方公告为准。WanSkins致力打造一个公平公正、绿色健康的平台环境，我们的运营理念与国家相关主管部门净化网络环境的要求高度一致，平台将全力维护用户合法权益，并保留追究相关违法人员法律责任的一切权利。  
`,
			}
		},
		mounted() {
		},
		methods: {
			
		},
	}
</script>
<style scoped lang="scss">
	#back_top {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		font-size: 26px;
		background: #4854c9;
		position: fixed;
		z-index: 1000;
		bottom: 100px;
		right: 10px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
	}
</style>
