<template>
	<div class="Changepass">
		<div class="title">
			修改密码
		</div>
		<div class="Changepass_box">
			<van-field v-model="mobile" type="text" label="手机号" />
			<van-field v-model="Password" type="password" maxlength="20" label="密码" />
			<van-field v-model="VerificationCode" center clearable label="验证码">
				<template #button>
					<van-button size="small" type="primary" v-show="VerCodeShow == false" @click='VerCodeSend(3)'>发送验证码
					</van-button>
					<van-button size="small" type="primary" v-show="VerCodeShow == true">
						<van-count-down millisecond :time="Time" format="ss" :auto-start="false" @finish="finish" ref="countDown" />
					</van-button>

				</template>
			</van-field>

			<div class="Changepass_nth">
				<van-button type="info" @click="PostPass">确定修改</van-button>
			</div>

		</div>
	</div>
</template>

<script>
import {
	mapMutations
} from 'vuex'
import {
	PostSendSms,
	Reset_password
} from '@/network/api.js'
export default {
	name: 'Changepass',
	data() {
		return {
			mobile: '', //手机号
			Password: '', //密码
			VerificationCode: '', //邮箱验证码
			VerCodeShow: false, //验证码/倒计时
			Time: 60 * 1000, //倒计时时间
		}
	},
	methods: {

		//邮箱验证码发送
		VerCodeSend() {
			this.VerCodeShow = true
			this.$refs.countDown.start();
			PostSendSms(this.mobile, '3').then((res) => {
				this.$notify({
					type: 'success',
					message: '发送成功'
				})
			})
		},

		//重置倒计时
		finish() {

			this.VerCodeShow = false
			this.$refs.countDown.reset();
		},

		//修改
		PostPass() {
			Reset_password(this.mobile, this.Password, this.VerificationCode).then((res) => {
				this.$notify({
					type: 'success',
					message: '修改成功,请重新登录!'
				})
				this.PostIndividualsShow(false)
				localStorage.clear()
				sessionStorage.clear()
				this.$router.push('/Home')
			})
		},
		...mapMutations([
			'PostIndividualsShow'
		])
	},

	//组件路由守卫(离开之后清空表单)
	beforeRouteLeave(to, from, next) {
		// console.log(to)
		this.Account = '',
			this.Password = '',
			this.VerificationCode = ''
		next()
	},

}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.Changepass {
		width: 343px;
		margin: 12px auto 0;
		background: #1D1D28;
		.title{
			background: url(~@/assets/img/rule-header.png);
			background-size: 100% 100%;
			height: 40px;
			@include HScenter;
		}
		.Changepass_box {
			width: 100%;
			box-sizing: border-box;
			padding: 30px 10px;
			.van-cell {
				background: none;
				margin-top: 10px;
				padding: 0 16px;
				.van-field__label {
					width: 50px;
					color: #fff;
					line-height: 34px;
					font-size: 12px;
				}

				.van-field__control {
					height: 34px;
					padding-left: 10px;
					color: #fff;
					background: #292936;
				}
			}
			.van-field__button{
				height: 34px;
				padding: 0;
				
			}
			.van-button{
				border-radius: 0;
			}
			.van-cell::after {
				display: none;
			}

			.van-count-down {
				color: #fff;
				font-size: 14px;
			}

			.Changepass_nth {
				text-align: center;
				margin-top: 10px;
				padding: 10px 16px;
				.van-button--normal {
					width: 100%;
					padding-left: 40px;
					padding-right: 40px;
					font-size: 12px;
				}
			}
		}
	}
</style>
