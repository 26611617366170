<template>
	<div class="welfare-main-con">
		<div class="welfare-user-con">
			<div class="user-con">
				<div class="photo">
					<img :src="User.avatar" alt="">
				</div>
				<div class="info">
					<div class="name">{{ User.name }}</div>
					<div class="vip-tip" :class="['level'+User.vip_level]"></div>
				</div>
			</div>
			<div class="total-con">
				<div class="item">
					累计已领取
					<div class="price">
						<div class="coins"></div>
						<span>{{ info.act_data.bean_total }}</span>
					</div>
				</div>
				<div class="item">
					今日已领取
					<div class="price">
						<div class="coins"></div>
						<span>{{ info.act_data.bean_today }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="recommend-title">推荐任务</div>
		<div class="welfare-scroll-con">
			<el-scrollbar style="height:100%;">
				<div class="welfare-recharge-con">
					<div class="list">
						<div class="item" v-for="(item,index) in currentList" :key="index">
							<div class="title">
								<div class="name">{{ item.title }}</div>
								<div class="num"><span>{{ item.progress.bean }}</span>/{{ item.type==1 ? item.rule.recharge_bean : item.rule.use_bean }}</div>
							</div>
							<div class="progress">
								<div class="inner" :style="`width: ${item.progress.bean / (item.type==1 ? item.rule.recharge_bean : item.rule.use_bean) * 100}%;`"></div>
							</div>
							<div class="time">活动时间：{{ item.start_time }}至{{ item.end_time }}</div>
							<div class="day" v-if="item.rule.total_days">
								<img src="~@/assets/img/welfare-recharge-day.png" alt="">
								充值天数：{{ item.rule.total_days }}天
							</div>
							<!-- <div class="desc" v-if="item.type==2">{{ item.rule.award_desc }}</div> -->
							<div class="award">
								<img src="~@/assets/img/welfare-recharge-award.png" alt="">
								{{ item.rule.award_desc }}
								<!-- 奖励：{{ item.rule.award_bean_start==item.rule.award_bean_end ? item.rule.award_bean_start : (item.rule.award_bean_start+'-'+item.rule.award_bean_end) }}平台币 -->
							</div>
							<div class="receive-btn finish" v-if="!item.progress.achieve&&item.type==2" :class="[item.progress.finish?'disabled':'']" @click="toFinish(item)">去完成</div>
							<div class="receive-btn" v-else :class="{'disabled': item.progress.finish}" @click="$emit('receive', item.id)">{{ item.progress.finish ? '已领取' : '领取' }}</div>
						</div>
					</div>
				</div>
			</el-scrollbar>
		</div>
	</div>
	
</template>

<script>
	import { Welfare } from '@/network/api.js'
	import {mapState} from 'vuex'
	export default{
		name:'WelfareRecommend',
		props: {
			list: {
				type: Array,
			},
			info:{
				type: Object,
			}
		},
		data(){
			return{
				navType: 1,
				navList: [
					{label: '新人充值',value: 1},
					{label: '充值有礼',value: 2}
				],
				ruleShow: false
			}
		},
		computed: {
			currentList(){
				return this.list.filter(item=>item.is_recommend==1&&item.type!=3)
			},
			...mapState([
				'User',
			])
		},
		created() {
			
		},
		methods:{
			toFinish(item){
				if(item.rule.type==1){
					this.$router.push('/Home')
				}else if(item.rule.type==2){
					// this.$router.push('/Battle')
				}else if(item.rule.type==3){
					this.$router.push('/Replacement')
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.welfare-user-con{
		width: 354px;
		height: 154px;
		background: url(~@/assets/img/welfare-user-bg.png);
		background-size: 100% 100%;
		.user-con{
			@include Scenter;
			padding-left: 29px;
			height: 84px;
			.photo{
				width: 56px;
				height: 56px;
				margin-right: 16px;
				img{
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
			}
			.info{
				.name{
					font-size: 12px;
					margin-bottom: 12px;
				}
				.vip-tip{
          width: 48px;
          height: 20px;
          background-size: 100% 100%;
          @for $i from 1 to 11{
            &.level#{$i}{
              background-image: url('~@/assets/img/vip-level'+$i+'.png');
            }
          }
        }
			}
		}
		.total-con{
			height: 54px;
			display: flex;
			.item{
				width: 50%;
				@include HScenter;
				flex-direction: column;
				.price{
					.coins{
						width: 14px;
						height: 14px;
						margin-right: 3px;	
					}
					span{
						background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 16px;
					}
				}
			}
		}
	}
	.recommend-title{
		background-image: url(~@/assets/img/welfare-hot-title-bg.png);
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: 0 10px;
		text-align: center;
		height: 42px;
		font-size: 20px;
		line-height: 25px;
		padding-top: 3px;
		font-weight: bold;
		// font-style: italic;
		margin: 10px 0 0;
	}
</style>
