<template>
	<div class="container">
		<div class="Title">
			<p>{{DetailData.name}}</p>
			<div class="icon-return" @click="$router.back()"></div>
		</div>
		<Details :DetailData="DetailData" :NumIdx="NumIdx" @openRule="G_Show = true"></Details>
		<Dynamic :DetailList="DetailList" :Awardlist="Awardlist" :Dynamic_checked="Dynamic_checked" :Dynamic_num="Dynamic_num" @setAnimation="setAnimation" @setEquipment="setEquipment" v-show="AnimationShow == '1'" :Music_checked="Music_checked"></Dynamic>
		<div class="price" v-if="type!='double'" style="justify-content: center;margin: 6px 0 15px;font-size: 17px;">
			<div class="coins"></div>{{(DetailData.bean*NumIdx).toFixed(2)}}
		</div>
		<div class="box_num" v-if="type!='double'" v-show="AnimationShow == '0'">
			<p v-for="(i,index) in 5" :class="{'active' : NumIdx == index + 1}" @click="NumIdx = index + 1">x{{i}}</p>
		</div>
		<div class="open_btn" v-show="AnimationShow == '0'">
			<!-- <div class="Animation_switch" style="margin-bottom: 10px">
				<p>跳过动画</p>
				<van-switch v-model="Animation_checked" size="24px" />
			</div> -->
			<div class="btn" @click="PostOpen"></div>
			<!-- <div class="tit">
				<van-icon name="question-o" color="#fff" size="20"/>
				<p @click="G_Show = true">玩法规则</p>
			</div> -->
		</div>
		<div class="hash-btn-con" v-if="User.id">
			<img src="~@/assets/img/box-hash-btn1.png" alt="" @click="openSeed">
			<img src="~@/assets/img/box-hash-btn2.png" alt="" @click="openHistory">
		</div>
		<div class="switch-con">
			<div class="skip-con">
				开启音乐
				<van-switch v-model="Music_checked" size="16px" />
			</div>
			<div class="skip-con">
				跳过动画
				<van-switch v-model="Animation_checked" size="16px" />
			</div>
		</div>
		<!-- <Equipment :Dynamic_num="Dynamic_num" :EquipmentItem="EquipmentItem" v-show="EquipmentShow == '1'" :key="EquipmentShow"></Equipment> -->
		<Goods :DetailList="DetailList" :id="DetailData.id"></Goods>
		<Show v-show="WinningShow" :Music_checked="Music_checked" :WinningShow="WinningShow" @setShow="setShow" :Awardlist="Awardlist"></Show>
		
		<van-overlay :show="G_Show">
			<div class="wrapper">
				<div class="rule-popup-con">
					<div class="rule-popup-header">
						宝箱规则
						<div class="close" @click="G_Show = false"></div>
					</div>
					<div class="rule-popup-main">
						<div class="rule-popup-content" v-html="publicConfig.desc_box"></div>
					</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="openHashSeed">
			<div class="wrapper">
				<div class="hash-con">
					<div class="close" @click="openHashSeed=false"></div>
					<img class="title-img" src="~@/assets/img/hash-popup-title.png" alt="">
					<div class="title"><span>种子信息</span></div>
					<div class="label">
						用户种子：
					</div>
					<div class="row">
						<div class="input">
							<input type="text" v-model="seedForm.user_seed" :readonly="!edit_user_seed" ref="user_seed">
						</div>
						<div v-if="!edit_user_seed" class="btn" @click="editUserSeed">修改</div>
						<div v-else class="btn" @click="setUserSeed(1)">保存</div>
						<div class="btn" @click="setUserSeed(2)">随机</div>
					</div>
					<div class="label">
						服务器种子hash：
					</div>
					<div class="row">
						<div class="input">
							<input type="text" v-model="seedForm.server_seed_hash" readonly>
						</div>
						<van-button class="btn" size="small" type="info" v-clipboard:copy="seedForm.server_seed_hash" v-clipboard:success="copy" v-clipboard:error="onError">复制</van-button>
					</div>
					<div class="title"><span>种子说明</span></div>
					<div class="desc">
						{{
`在您开始游戏前，您可以任意修改您的用户种子，以保证该局游戏的公平性
为了方便您管理自己的种子，您设置的种子将用于所有需要Hash算法的游戏;
如需在游戏后对该局游戏进行验证，请提前复制服务器种子Hash值保存，
以验证并确保游戏前后服务器所给出的种子一致;
游戏公平Hash算法详细规则请参考网页顶部《公平性游戏》;`
						}}
					</div>
				</div>
			</div>
		</van-overlay>
		<van-overlay :show="openHashHistory">
			<div class="wrapper">
				<div class="hash-con">
					<div class="close" @click="openHashHistory=false"></div>
					<img class="title-img" src="~@/assets/img/hash-popup-title.png" alt="">
					<div class="title"><span>开箱历史</span></div>
					<div class="list">
						<div class="item" v-for="(item,index) in hashRecord" :key="index">
							<div class="item-top">
								{{ item.created_at }}
								<!-- <div class="rate">概率10%</div> -->
							</div>
							<div class="gun-info">
								<div class="img-con" :class="['lv'+item.lv]">
									<img :src="item.cover" alt="">
								</div>
								<div class="info">
									<div class="name">{{ item.name }}</div>
									<div class="alias">{{ item.quality_alias }} ({{ item.dura_alias }})</div>
								</div>
								<div class="btn-con">
									<div class="btn" @click="openHashUrl(1)">验证</div>
									<div class="btn" @click="openHashUrl(2)">16进制转换</div>
								</div>
							</div>
							<div class="seed-detail" v-if="item.hash_info">
								服务器种子HASH：{{ item.hash_info.server_seed_hash }}<br>
								服务器种子: {{ item.hash_info.server_seed }}<br>
								用户种子: {{ item.hash_info.user_seed }}<br>
								结果种子Hash:<br>
								{{ item.hash_info.hash }}<br>
								Number= {{ item.hash_info.divisor_hex }}(16进制) / ffffff(16进制) = <br>
								{{ item.hash_info.divisor }}/16777215 = <br>
								<span>{{ item.hash_info.result }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import Details from '@/components/PubOpen/Details.vue'
	import Dynamic from '@/components/PubOpen/Dynamic.vue'
	import Equipment from '@/components/PubOpen/Equipment.vue'
	import Goods from '@/components/PubOpen/Goods.vue'
	import Show from '@/components/PubOpen/Show.vue'
	import { BoxDetail, Open, PersonalInfo, activity20240210Info, activity20240210Draw, userSeed, setUserSeed, boxUserRecords } from '@/network/api.js'
	import { mapMutations, mapState } from 'vuex'
	export default {
		name: 'Open',
		data() {
			return {
				DetailData:{},//箱子信息
				NumIdx:1,//开箱数量
				DetailList:[],//箱内物品
				Animation_checked: false, //动画开关
				Music_checked: true, //音乐开关
				WinningShow:false,//奖品遮罩层
				Awardlist:[],//获得奖品数据
				Dynamic_checked:'0',//播放动画('0':不播放,'1':播放)
				Dynamic_num:0,//奖品数量
				AnimationShow:'0',//动画显示隐藏'0':不显示,'1':显示)
				EquipmentItem:{},//下方奖品栏子级
				EquipmentShow:'0',//下方奖品栏显示
				G_Show:false,
				type: '',
				activityInfo:{
					user:{
						act_bean: 0
					}
				},
				openHashSeed: false,
				openHashHistory: false,
				seedForm: {
					server_seed_hash: '',
					user_seed: ''
				},
				hashRecord: [],
				edit_user_seed: false
			}
		},
		computed: {
			...mapState(['publicConfig', 'User'])
		},
		created() {
			this.GetBoxDetail()
			window.scrollTo(0,0)
			localStorage.setItem('delay', 5)
			if(this.$route.query.type == 'double'){
				this.type = this.$route.query.type
				this.GetInfo()
			}
		},
		watch: {
			Animation_checked(val){
				if(val){
					localStorage.setItem('delay', 0)
				}else{
					localStorage.setItem('delay', this.NumIdx>2 ? 8 : 5)
				}
			},
			NumIdx(val){
				if(!this.Animation_checked){
					localStorage.setItem('delay', this.NumIdx>2 ? 8 : 5)
				}
			}
		},
		methods:{
			generateRandomCode(length) {
				var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				var randomCode = '';

				for (var i = 0; i < length; i++) {
						var randomIndex = Math.floor(Math.random() * characters.length);
						randomCode += characters.charAt(randomIndex);
				}

				return randomCode;
			},
			setUserSeed(type){
				setUserSeed({seed: type==1 ? this.seedForm.user_seed : this.generateRandomCode(6)}).then((res) => {
					this.edit_user_seed = false
					this.seedForm.user_seed = res.data.data.user_seed
					this.$notify({
						type: 'success',
						message: '修改成功'
					})
				})
			},
			editUserSeed(){
				this.edit_user_seed = true
				this.$nextTick(()=>{
					this.$refs.user_seed.focus()
				})
			},
			//复制文本
			copy(e) {
				// console.log(e)
				this.$notify({
					type: 'success',
					message: '复制成功'
				})
			},
			onError(e) {
				// console.log(e)
				this.$notify({
					type: 'danger',
					message: '复制失败'
				})
			},
			openHashUrl(type){
				if(type==1){
					window.open('https://emn178.github.io/online-tools/sha256.html')
				}else{
					window.open('https://tool.oschina.net/hexconvert/')
				}
			},
			openSeed(){
				this.openHashSeed = true
				userSeed().then((res) => {
					this.seedForm = res.data.data
				})
			},
			openHistory(){
				this.openHashHistory = true
				let delay = localStorage.getItem('delay') ? Number(localStorage.getItem('delay')) : 0
				boxUserRecords({id:this.$route.query.id, delay}).then((res) => {
					this.hashRecord = res.data.data.records
				})
			},
			//福利箱列表
			GetInfo(){
				activity20240210Info().then((res) => {
					const {data} = res.data;
					this.activityInfo = data
				})
			},
			//宝箱详情
			GetBoxDetail(){
				BoxDetail(this.$route.query.id).then((res) => {
					// console.log(res.data.data)
					this.DetailData = res.data.data
					this.DetailList = res.data.data.box_awards.sort(function(a,b){
						return Number(b.bean) - Number(a.bean)
					})
					// console.log(this.DetailList)
				})
			},

			//开箱
			PostOpen(){
				if(this.type == 'double'){
					let params = {
						type: 3,
						times: this.NumIdx,
						box_id: this.$route.query.id
					}
					activity20240210Draw(params).then((res) => {
						const {data} = res.data
						this.GetPersonalInfo()
						this.Awardlist = res.data.data.awards
						if(this.Animation_checked == true){
							this.WinningShow = true
						}else{
							this.AnimationShow = '1'
							this.EquipmentShow = '1'
							// console.log('false')
							setTimeout(() => {
								this.Dynamic_checked = '1'
								this.Dynamic_num = this.Awardlist.length
							},100)
						}
					})
				}else{
					Open(this.$route.query.id,this.NumIdx).then((res) => {
						// console.log(res.data.data.awardlist)
						this.GetPersonalInfo()
						this.Awardlist = res.data.data.awardlist
						if(this.Animation_checked == true){
							this.WinningShow = true
						}else{
							this.AnimationShow = '1'
							this.EquipmentShow = '1'
							// console.log('false')
							setTimeout(() => {
								this.Dynamic_checked = '1'
								this.Dynamic_num = this.Awardlist.length
							},100)
						}
					})
				}
				
			},

			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.PostUser(res.data.message)
				})
			},

			//接收子级(关闭奖品遮罩层)
			setShow(v){
				// console.log(v)
				this.WinningShow = v
				this.EquipmentShow = '0'
			},

			//接收子级(动画结束)
			setAnimation(v){
				this.WinningShow = v
				this.AnimationShow = '0'
				this.Dynamic_checked = '0'
			},

			//接收子级(下方奖品栏)
			setEquipment(v){
				// console.log(v)
				this.EquipmentItem = this.Awardlist[v - 1]
			},

			...mapMutations([
				'PostUser'
			])
		},

		components: {
			Details,
			Dynamic,
			Equipment,
			Goods,
			Show
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.Title{
		background-size: 100% auto;
		background-position: bottom center;
		background-repeat: no-repeat;
		width: 363px;
		background-image: url(~@/assets/img/home-title-bg.png);
		.icon-return{
			position: absolute;
			top: 50%;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
	}
	.container {
		.box_num{
			display: flex;
			justify-content: center;
			p{
				width: 50px;
				height: 44px;
				background: #232749;
				text-align: center;
				line-height: 44px;
				margin: 0 3px;
				cursor: pointer;
				&.active{
					background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
					text-shadow: 0px 2px 4px #3A6EB5;
				}
			}
		}
		.open_btn {
			text-align: center;
			cursor: pointer;
			.btn{
				background: url(~@/assets/img/box-open-bg.png);
				background-size: 100% 100%;
				width: 274px;
				height: 55px;
				@include HScenter;
				margin: 14px auto 0;
			}
		}
	}
	.switch-con{
		@include Hcenter;
		gap: 12px;
		.skip-con{
			@include HScenter;
			font-size: 12px;
			color: #999;
			margin-top: 16px;
			.van-switch{
				border: none;
				margin-left: 6px;
			}
			.van-switch--on{
				background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
			}
		}
	}
	.hash-btn-con{
		@include HScenter;
		gap: 10px;
		margin: 15px 0;
		img{
			width: 119px;
			cursor: pointer;
		}
	}
	.hash-con{
		@include Scenter;
		flex-direction: column;
		padding-top: 15px;
		background-image: url(~@/assets/img/hash-popup.png);
		background-size: 100% 100%;
		width: 350px;
		height: 537px;
		position: relative;
		.close{
			width: 24px;
			height: 24px;
			background-image: url(~@/assets/img/rule-close.png);
			background-size: 100% 100%;
			position: absolute;
			right: 10px;
			top: 12px;
			cursor: pointer;
		}
		.title-img{
			height: 34px;
			margin-bottom: 12px;
		}
		.title{
			margin-bottom: 12px;
			span{
				position: relative;
				&::before{
					content: '';
					position: absolute;
					top: 50%;
					left: -110%;
					height: 1px;
					width: 53px;
					background: linear-gradient(270deg, #D9D9D9 50%, rgba(217, 217, 217, 0.00) 99.06%);
				}
				&::after{
					content: '';
					position: absolute;
					top: 50%;
					right: -110%;
					height: 1px;
					width: 53px;
					background: linear-gradient(90deg, #D9D9D9 50%, rgba(217, 217, 217, 0.00) 99.06%);
				}
			}
		}
		.label{
			width: 305px;
			margin-bottom: 12px;
		}
		.row{
			margin-bottom: 12px;
			width: 305px;
			@include Scenter;
			gap: 18px;
			.input{
				flex: 1;
				height: 32px;
				border: 1px solid; /* 设置一个基本的边框 */
				border-image: linear-gradient(to bottom, #448EFD, #44FDE7); /* 使用线性渐变作为边框图像 */
				border-image-slice: 1; /* 保持整个边框都是渐变色 */
				padding: 1px; /* 可选，用于确保内容不被边框覆盖 */
				padding: 1px 8px;
				input{
					width: 100%;
					height: 100%;
				}
			}
			.btn{
				background: #FFB800 !important;
				width: 48px;
				height: 32px;
				color: #15161B;
				@include HScenter;
				cursor: pointer;
				border-radius: 0;
			}
		}
		.desc{
			width: 305px;
			height: 220px;
			white-space: pre-line;
			padding: 0 10px;
			overflow-y: auto;
			overflow-x: hidden;
			//滚动条宽度
			&::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}
			/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
			&::-webkit-scrollbar-track {
				background: rgba(255, 255, 255, 0.5);
			}
			//滚动条设置
			&::-webkit-scrollbar-thumb {
				// border-radius: 5px;
				background: rgba(255, 255, 255, 1);
			}
		}
		.list{
			width: 327px;
			height: 423px;
			white-space: pre-line;
			padding: 0 10px;
			overflow-y: auto;
			overflow-x: hidden;
			//滚动条宽度
			&::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}
			/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
			&::-webkit-scrollbar-track {
				background: rgba(255, 255, 255, 0.5);
			}
			//滚动条设置
			&::-webkit-scrollbar-thumb {
				// border-radius: 5px;
				background: rgba(255, 255, 255, 1);
			}
			.item{
				.item-top{
					height: 28px;
					margin-bottom: 12px;
					@include Scenter;
					background: #2A2D3D;
					padding: 0 12px;
					font-size: 12px;
					color: #999;
					.rate{
						margin-left: auto;
						background: var(--LOGO, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 12px;
					}
				}
				.gun-info{
					@include Scenter;
					gap: 12px;
					.img-con{
						width: 72px;
						height: 65px;
						background-size: auto 100%;
						background-position: center center;
						background-repeat: no-repeat;
						&.lv1{
							background-image: url(~@/assets/img/product/roll-lv-bg-1.png);
						}
						&.lv2{
							background-image: url(~@/assets/img/product/roll-lv-bg-2.png);
						}
						&.lv3{
							background-image: url(~@/assets/img/product/roll-lv-bg-3.png);
						}
						&.lv4{
							background-image: url(~@/assets/img/product/roll-lv-bg-4.png);
						}
						&.lv5{
							background-image: url(~@/assets/img/product/roll-lv-bg-5.png);
						}
					}
					.info{
						flex: 1;
						overflow: hidden;
						.name{
							@include wordhide;
							font-size: 12px;
						}
						.alias{
							font-size: 12px;
							color: #999;
						}
					}
					.btn-con{
						.btn{
							width: 88px;
							height: 30px;
							background: #FFB800;
							color: #15161B;
							@include HScenter;
							cursor: pointer;
							&:first-child{
								margin-bottom: 8px;
							}
						}
					}
				}
				.seed-detail{
					font-size: 12px;
					word-break: break-all;
					margin: 12px 0;
					color: #999;
					span{
						color: #09FFC4;
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
	.times{
		opacity: 0;
		color: #ffba2e;
		text-align: center;
		margin-top: -20px;
		font-size: 14px;
		margin-bottom: 15px;
		&.show{
			opacity: 1;
		}
	}
</style>
