<template>
	<div class="fairness-con">
		<img src="~@/assets/img/fairness-title.png" alt="" class="fairness-title">
		<div class="fairness-nav">
			<div class="item" :class="{'active': navIndex==1}" @click="navIndex=1">HASH算法</div>
			<div class="item" :class="{'active': navIndex==2}" @click="navIndex=2">种子设置</div>
			<div class="item" :class="{'active': navIndex==3}" @click="navIndex=3">如何验证</div>
		</div>
		<template v-if="navIndex==1">
			<div class="title"><span>1.HASH算法是一种无法逆向演算的算法</span></div>
			<img class="img-desc img1" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-1.png" alt="">
			<div class="title"><span>2.我们基于Hash算法来保证游戏的绝对公平</span></div>
			<div class="desc-con">
				1.服务器种子会在每一轮游戏开始前随机生成，同时我们会把服务器种子的Hash值提供出来公示公证，以保证我们无法在游戏开始之后更改游戏结果。 <br><br>
				2.您也需要提供一个用户种子，每一轮游戏开始前您可以任意修改您的用户种子，以保证我们无法提前预知游戏结果。 <br><br>
				3.确认开始游戏后，我们将对服务器种子和用户种子进行共同Hash计算，以得到决定最终游戏结果的结果Hash值。
			</div>
			<img class="img-desc" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-2.png" alt="">
			<div class="arrow">
				<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/arrow.png" alt="">
			</div>
			<img class="img-desc" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-3.png" alt="">
			<div class="desc-con">
				由此计算可确保结果Number的值是在0-1范围内完全随机分布的，且为服务器和用户共同决定的结果。
			</div>
			<div class="arrow" style="margin-top: 10px;">
				<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/arrow.png" alt="">
			</div>
			<img class="img-desc" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-4.png" alt="">
			<div class="desc-con">
				在游戏历史中验证该局游戏的服务器种子是否符合游戏展示的服务器种子Hash值，如符合则可确保该局游戏公平无误。
			</div>
			<div class="title"><span>3.公平游戏Hash原理</span></div>
			<img class="img-desc" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-5.png" alt="">
			<div class="arrow">
				<div class="desc-con">
					Hash函数计算
				</div>
				<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/arrow.png" alt="">
				<div class="desc-con">
					正向计算Hash值
				</div>
			</div>
			<img class="img-desc" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-6.png" alt="">
			<div class="arrow">
				<div class="desc-con">
					16进制(结果Hash值前6位)<br>/16进制(ffffff)
				</div>
				<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/arrow.png" alt="">
				<div class="desc-con">
					确保结果Hash值能够运算<br>成0-1之间的小数
				</div>
			</div>
			<img class="img-desc" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-7.png" alt="">
			<div class="arrow">
				<img src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/arrow.png" alt="">
			</div>
			<img class="img-desc" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-8.png" alt="">
		</template>
		<template v-if="navIndex==2">
			<div class="desc">
				1、wanskins 开创了一种公平的Hash玩法，游戏的最终结果将由用户提供的种子和服务器种子共同决定。<br>
				2、注册登录后，我们会为您随机生成一组用户种子。在游戏开始前，您也可以在游戏页面的【修改种子】中修改你的种子
			</div>
			<img class="img-desc" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-9.png" alt="">
		</template>
		<template v-if="navIndex==3">
			<div class="title"><span>1.验证游戏公平性的方法</span></div>
			<div class="desc-con">
				1、验证服务器种子经过Hash函数计算后，是否与游戏开始前网站所有给出的服务器种子Hash值一致。<br>
				2、提取用户种子和服务器种子按照游戏Hash原理步骤依次计算后。查看结果Number是否与网站给出的结果一致。<br>
				验证Hash的参考网页链接：<a href="https://emn178.github.io/online-tools/sha256.html">https://emn178.github.io/online-tools/sha256.html</a><br>
				验证16进制的参考网页链接：<a href="https://tool.oschina.net/hexconvert/">https://tool.oschina.net/hexconvert/</a>
			</div>
			<div class="title"><span>2、验证方法举例</span></div>
			<div class="desc-con">
				1.游戏开始前，复制当前游戏网站提供的服务器种子Hash值 例子中值为：<br>
				<a>db698a74550b60001fa023a4b7f9ed7273b313fc213bdad8ae94501530623211</a>
			</div>
			<img class="img-desc" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-9.png" alt="">
			<div class="desc-con">
				2.游戏结束后，提取游戏结果中的服务器种子 <br>
				通过计算Hash的参考网站，查看服务器种子的Hash计算结果是否与游戏开始前一致<br>
				例子中服务器种子为<a>CR0F4MJCZVQMX0QM</a>，经过计算结果与网站服务器种子HASH一致<br>
			</div>
			<img class="img-desc img10" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-10.png" alt="">
			<img class="img-desc img10" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-11.png" alt="">
			<div class="desc-con">
				3. 提取用户种子和服务器种子，按照游戏Hash原理步骤计算出结果种子Hash值 <br>
				例子中用户种子为 8fxffH ，服务器种子为 <br>
				<a>CR0F4MJCZVQMX0QM </a><br>
				经过计算结果为 <br>
				<a>66111fe0aaf80316f3c71827c18b7026608d6d610aa172aef8de438f5a5273cb</a>，与网站结果种子HASH一致
			</div>
			<img class="img-desc img10" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-12.png" alt="">
			<div class="desc-con">
				4. 提取结果Hash值按照游戏Hash原理的步骤，通过16进制转换为10进制计算出结果Number <br>
				例子中结果Hash值为<br>
				<a>66111fe0aaf80316f3c71827c18b7026608d6d610aa172aef8de438f5a5273cb </a><br>
				取其前6位字符串为 66111f ，通过16进制转换为10进制为 6689055，除以16进制（ffffff）的固定值 16777215 <br>
				经过计算结果<br>
				Number≈<a>0.42221697701316934902485305219013</a>,与网站一致 16进制 (结果Hash值前6位)
			</div>
			<img class="img-desc img10" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-13.png" alt="">
			<div class="desc-con">
				5.计算结果Number
			</div>
			<img class="img-desc img10" src="https://wanskins.oss-cn-beijing.aliyuncs.com/images/app/fairness/img-14.png" alt="">
		</template>
	</div>
</template>

<script>
export default {
	name: 'Fairness',
	data() {
		return {
			navIndex: 1
		}
	},
	methods: {

	},
}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.fairness-con{
		background: #15161B;
		@include Scenter;
		flex-direction: column;
		padding: 16px 0;
		min-height: 100vh;
		.fairness-title{
			height: 34px;
		}
		.fairness-nav{
			@include Scenter;
			gap: 20px;
			margin: 20px 0 0;
			.item{
				color: #999;
				position: relative;
				cursor: pointer;
				&.active{
					background: linear-gradient(180deg, #FFF 0%, #2FB3D0 100%);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					&::before{
						content: '';
						position: absolute;
						left: 50%;
						bottom: -4px;
						background: linear-gradient(180deg, #FFF 0%, #2FB3D0 100%);
						width: 30px;
						height: 2px;
						transform: translateX(-50%);
					}
				}
			}
		}
		.title{
			border-radius: 44px;
			background: linear-gradient(180deg, #343439 0%, #22222D 100%);
			@include HScenter;
			width: 290px;
			height: 37px;
			margin: 24px 0 0;
			span{
				background: var(--LOGO, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				font-size: 12px;
			}
		}
		.desc{
			margin-top: 24px;
			width: 304px;
			padding: 12px 20px;
			border-radius: 14px;
			background: linear-gradient(180deg, #343439 0%, #22222D 100%);
			color: #44A6ED;
			font-size: 12px;
			font-style: normal;
		}
		.img-desc{
			margin-top: 24px;
			width: 300px;
			display: block;
			&.img1{
				width: 348px;
			}
			&.img10{
				width: 310px;
			}
		}
		.desc-con{
			width: 300px;
			font-size: 12px;
			color: #999;
			margin-top: 24px;
			word-break: break-all;
			a{
				color: #FFB800;
			}
		}
		.arrow{
			@include HScenter;
			width: 300px;
			gap: 8px;
			margin-top: 24px;
			.desc-con{
				width: 18px;
				margin-top: 0;
				flex: 1;
				text-align: center;
				white-space: nowrap;
			}
		}
	}
</style>
