<template>
	<div id="Payment">
		<van-loading type="spinner" size="50px" color="#1989fa"/>
	</div>
</template>

<script>
	import { PostRecharge } from '@/network/api.js'
	export default{
		name:'Payment',
		data(){
			return{
				
			}
		},
		created() {
			// this.$route.query.pay_way
			// 获取充值参数请求后覆盖原页面
			// console.log(this.$route.query.type)
			PostRecharge(this.$route.query.id,this.$route.query.type ).then((res) => {
				console.log(res.data)
					location.replace(res.data.list.h5)
				
				// if(this.$route.query.type == 'alipay'){
				// 	location.replace(res.data.list[0].url)
				// }else{
				// 	this.$notify({
				// 		type: 'danger',
				// 		message: '微信充值暂未开放！'
				// 	})
				// }
				
			})
		}
	}
</script>

<style lang="scss">
	#Payment{
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
</style>
